import { Close } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material';
import AppContainer from './app-container';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type BannerProps = {
  label?: string;
};

const Main = styled('div')(({ theme }) => ({
  ...theme.text.primary,
  padding: '15px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Text = styled('p')(() => ({
  padding: 0,
  margin: 0,
}));

const Button = styled('button')(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  fontSize: '20px',
  margin: 0,
  padding: 0,
}));

export default function Banner({ label }: BannerProps) {
  const [isOpen, setIsOpen] = useState(!!label);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = () => {
    setIsOpen(false);
  };

  return isOpen ? (
    <AppContainer
      style={{
        backgroundColor: theme.ui.header.banner.background,
      }}
    >
      <Main>
        <Text>
          <strong>{t`banner.notification`}:</strong> {label}
        </Text>
        <Button onClick={handleClose}>
          <Close />
        </Button>
      </Main>
    </AppContainer>
  ) : null;
}
