import { styled, SxProps, Theme } from '@mui/material';
import { LegendItem, LegendType } from 'core/types/legend.type';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  margin: '5px 20px 5px 0',
});

const Label = styled('p')({
  margin: '0 0 0 10px',
  fontSize: '0.875rem',
});

const Marker = styled('span')({
  width: 14,
  height: 14,
});

export type LegendProps = {
  item: LegendItem;
  width?: number;
  legendStyle?: SxProps<Theme>;
  markerStyle?: SxProps<Theme>;
  labelStyle?: SxProps<Theme>;
};

export default function Legend({
  item,
  markerStyle,
  legendStyle,
  labelStyle,
  width = 150,
}: LegendProps) {
  const pattern = item.patternColor || item.color;
  return (
    <Wrapper sx={{ width, ...legendStyle }}>
      <Marker
        sx={{
          background: `repeating-linear-gradient(-45deg,${item.color},${item.color} 1px,${pattern} 2px,${pattern} 3px)`,
          height: item.type === LegendType.line ? 4 : 14,
          borderRadius: item.type === LegendType.circle ? '50%' : '0%',
          ...markerStyle,
        }}
      />
      <Label sx={{ ...labelStyle }}>{item.label}</Label>
    </Wrapper>
  );
}
