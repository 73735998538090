import { Box, Container, SxProps, Theme, Breakpoint } from '@mui/material';

import { ReactNode } from 'react';

export type AppContainerProps = {
  style?: SxProps<Theme>;
  children?: ReactNode;
  hideMargin?: boolean;
  maxWidth?: false | Breakpoint | undefined;
};

export default function AppContainer({
  style,
  children,
  hideMargin,
  maxWidth = false,
}: AppContainerProps) {
  return (
    <Box sx={{ ...style }}>
      {hideMargin ? (
        children
      ) : (
        <Container maxWidth={maxWidth}>{children}</Container>
      )}
    </Box>
  );
}
