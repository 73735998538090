import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OperationalPlanningServiceF from 'core/services/operational-planning.service';
import { Scenario } from 'core/types/operational-planning.type';

export const fetchScenarios = createAsyncThunk(
  'opPlanning/fetchScenarios',
  async (clusterId: string, { rejectWithValue }) => {
    try {
      const response = await OperationalPlanningServiceF.getScenarios(
        clusterId
      );
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export type opPlanningState = {
  scenarios: Scenario[];
  error: boolean;
  loading: boolean;
};

const initialState: opPlanningState = {
  scenarios: [],
  error: false,
  loading: false,
};

export const opPlanningSlice = createSlice({
  name: 'Operational Planning',
  initialState,
  reducers: {
    resetOpPlanningError: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScenarios.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(fetchScenarios.fulfilled, (state, action) => {
      state.scenarios = [...(action.payload || [])];
      state.scenarios = [...(action.payload || [])];

      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchScenarios.rejected, (state) => {
      state.error = false;
      state.loading = false;
    });
  },
});

export const { resetOpPlanningError } = opPlanningSlice.actions;

export const opPlanningReducerF = opPlanningSlice.reducer;
