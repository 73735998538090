import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapacityPage } from 'core/types/capacity-page.type';
import { LevelOption } from 'core/types/level.type';

export type CapacityManagementMenuState = {
  selectedPage: CapacityPage | null;
  selectedLevels: LevelOption | null;
  isAddPageMenuClicked: boolean;
  isShareMenuItemClicked: boolean;
  isManageMenuItemClicked: boolean;
};

const initialState: CapacityManagementMenuState = {
  selectedPage: null,
  selectedLevels: null,
  isAddPageMenuClicked: false,
  isShareMenuItemClicked: false,
  isManageMenuItemClicked: false,
};

export const capacityManagementMenuSlice = createSlice({
  name: 'capacityManagementMenu',
  initialState,
  reducers: {
    updateSelectedPage: (state, action: PayloadAction<CapacityPage | null>) => {
      return {
        ...state,
        selectedPage: action.payload,
      };
    },
    updateSelectedLevels: (
      state,
      action: PayloadAction<LevelOption | null>
    ) => {
      return {
        ...state,
        selectedLevels: action.payload,
      };
    },
    updateIsAddPageMenuClicked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAddPageMenuClicked: action.payload,
      };
    },
    updateIsShareMenuItemClicked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShareMenuItemClicked: action.payload,
      };
    },
    updateIsManageMenuItemClicked: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isManageMenuItemClicked: action.payload,
      };
    },
  },
});

export const {
  updateIsAddPageMenuClicked,
  updateIsManageMenuItemClicked,
  updateIsShareMenuItemClicked,
  updateSelectedLevels,
  updateSelectedPage,
} = capacityManagementMenuSlice.actions;

export const capacityManagementMenuReducer =
  capacityManagementMenuSlice.reducer;
