import { Box, styled, useTheme } from '@mui/material';
import { scaleThreshold } from '@visx/scale';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { LevelOption } from 'core/types/level.type';
import { HorizonAccuracy } from 'core/types/output.type';
import { chain } from 'lodash';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import TableLoader from 'components/table-loader';

const ScrollWrapper = styled('div')({
  overflow: 'auto',
  width: '100%',
});

const Table = styled('table')({
  width: '100%',
  borderSpacing: 5,
  columnRuleStyle: 'none',
});

const Header = styled('thead')({});

const Body = styled('tbody')({});

const Row = styled('tr')({
  outline: 'none',
});

const HeaderCell = styled('th')(({ theme }) => ({
  minWidth: '50px',
  padding: '10px 5px',
  position: 'relative',
  backgroundColor: theme.viz.accuracy.heatmap.header.background,
  color: theme.viz.accuracy.heatmap.header.color,
  fontWeight: 'normal',
  fontSize: '0.875rem',
}));

const HeaderRowCell = styled('th')(({ theme }) => ({
  minWidth: '150px',
  padding: '14px 16px',
  position: 'relative',
  backgroundColor: theme.viz.accuracy.heatmap.rowHeader.background,
  color: theme.viz.accuracy.heatmap.rowHeader.color,
  fontWeight: 'normal',
  textAlign: 'left',
  fontSize: '1.125rem',
  height: '20px',
}));

const Cell = styled('td')(({ theme }) => ({
  textAlign: 'center',
  padding: '14px',
  color: theme.viz.accuracy.heatmap.cell.color,
}));

export type HorizonHeatmapProps = {
  columns?: string[];
  rowHeaders?: string[];
  data?: HorizonAccuracy[];
  level: LevelOption;
  loading?: boolean;
  height?: number;
  period: Period;
  formatLabel?: (el: string) => string;
};

export default function HorizonHeatmap({
  level,
  period,
  loading,
  data = [],
  rowHeaders = [],
  columns = [],
  height = 500,
  formatLabel = (val) => val,
}: HorizonHeatmapProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const periodUnit = DateService.getFrequencyTranslation(period);
  const frequencyLabel = `${columns.length} ${t(`time.${periodUnit}s`)}`;

  const thresholdColorData = [
    { value: 80, color: theme.viz.accuracy.thresholdColors[0] },
    { value: 82, color: theme.viz.accuracy.thresholdColors[1] },
    { value: 84, color: theme.viz.accuracy.thresholdColors[2] },
    { value: 86, color: theme.viz.accuracy.thresholdColors[3] },
    { value: 88, color: theme.viz.accuracy.thresholdColors[4] },
    { value: 90, color: theme.viz.accuracy.thresholdColors[5] },
    { value: 92, color: theme.viz.accuracy.thresholdColors[6] },
    { value: 94, color: theme.viz.accuracy.thresholdColors[7] },
    { value: 96, color: theme.viz.accuracy.thresholdColors[8] },
    { value: 98, color: theme.viz.accuracy.thresholdColors[9] },
    { value: 100, color: theme.viz.accuracy.thresholdColors[10] },
  ];

  const colorScale = scaleThreshold<number, string>({
    domain: thresholdColorData.map((el) => el.value / 100),
    range: thresholdColorData.map((el) => el.color),
  });

  const aggregate = chain(data)
    .groupBy('horizon')
    .map((v1, horizon) => ({
      horizon: +horizon,
      ...v1.reduce(
        (obj, d) => {
          obj.actual += d.actual;
          obj.weightedAccuracy += d.weightedAccuracy;
          return obj;
        },
        { actual: 0, weightedAccuracy: 0, level }
      ),
    }))
    .value();

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading) {
    return <TableLoader />;
  }

  return (
    <ScrollWrapper sx={{ height: `${height}px` }}>
      <Table sx={{ position: 'relative' }}>
        <Header
          sx={{
            '& th': {
              position: 'sticky',
              top: 0,
            },
          }}
        >
          <Row>
            <HeaderCell
              sx={{
                zIndex: 2,
                left: 0,
                fontWeight: 'bold',
                textAlign: 'right',
              }}
            >
              {frequencyLabel}
            </HeaderCell>
            {columns.map((el) => (
              <HeaderCell key={`heatmap-x-${el}`}>{formatLabel(el)}</HeaderCell>
            ))}
          </Row>
        </Header>
        <Body
          sx={{
            '& th': {
              position: 'sticky',
              zIndex: 1,
              left: 0,
            },
          }}
        >
          <Row>
            <HeaderRowCell
              sx={{
                backgroundColor:
                  theme.viz.accuracy.heatmap.rowHeader.accentBackground,
              }}
            >
              {level.name || ''}
            </HeaderRowCell>
            {aggregate.map((el, i) => {
              const _accuracy =
                el.actual > 0 ? el.weightedAccuracy / el.actual : 0;
              return (
                <Cell
                  key={`header-data-${i}`}
                  sx={{
                    backgroundColor: colorScale(_accuracy),
                  }}
                >
                  {numeral(_accuracy).format(NumeralFormat.percent)}
                </Cell>
              );
            })}
          </Row>
          {rowHeaders.map((el) => {
            return (
              <Row key={`heatmap-row-${el}`}>
                <HeaderRowCell key={`heatmap-header-${el}`}>
                  {el || ''}
                </HeaderRowCell>
                {data
                  .filter(
                    (d) =>
                      el ===
                      (level.level1
                        ? d.level2Name
                        : level.level0
                        ? d.level1Name
                        : d.level0Name)
                  )
                  .map((d, i) => {
                    const _accuracy = d.accuracy;
                    return (
                      <Cell
                        key={`heatmap-cell-${d.level0Id}-${i}`}
                        sx={{
                          backgroundColor: colorScale(_accuracy),
                        }}
                      >
                        {numeral(_accuracy).format(NumeralFormat.percent)}
                      </Cell>
                    );
                  })}
              </Row>
            );
          })}
        </Body>
      </Table>
    </ScrollWrapper>
  );
}
