import { ChartKey } from 'core/enums/chart-key.enum';
import { DateFormat } from 'core/enums/date-format.enum';
import { Period } from 'core/enums/period.enum';
import ApiService from 'core/services/api.service';
import { Note, NoteByDate, NotePayload } from 'core/types/note.type';
import moment from 'moment';

export default class NoteServiceF {
  public static async saveNote(note: NotePayload): Promise<Note> {
    try {
      const response = await ApiService.post<NotePayload, Note>('notes', note);
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async getNotes(
    categoryId: string,
    frequency: Period,
    modelId: number,
    isUser?: number
  ): Promise<Note[]> {
    let params: {
      categoryId?: string;
      isUser?: number;
      frequency: Period;
      modelId: number;
    } = {
      isUser,
      frequency,
      modelId,
    };

    if (categoryId !== 'all') {
      params = {
        categoryId,
        ...params,
      };
    }

    try {
      const response = await ApiService.get<Note[]>('notes', {
        ...params,
      });
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getChartNotes(
    modelId: number,
    frequency: Period,
    chartKey: ChartKey,
    startDate?: string,
    endDate?: string,
    levelId?: string,
    levelNum?: number
  ): Promise<Note[]> {
    try {
      const response = await ApiService.get<Note[]>('notes', {
        startDate,
        endDate,
        levelId,
        levelNum,
        modelId,
        frequency,
        chartKey,
      });

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getLatestNote(modelId: number): Promise<Note> {
    try {
      const response = await ApiService.get<Note>('notes/latest', {
        modelId,
      });
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static getGroupedNotes(notes: Note[]): NoteByDate {
    const _notes: NoteByDate = {};
    if (notes) {
      notes.forEach((el) => {
        const formattedDate = moment
          .utc(el.targetDate)
          .format(DateFormat.default);
        if (_notes[formattedDate]) {
          _notes[formattedDate].notes.push(el);
          _notes[formattedDate].count++;
        } else {
          _notes[formattedDate] = {
            notes: [el],
            count: 1,
          };
        }
      });
    }

    return _notes;
  }
}
