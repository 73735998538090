import AppContainer from 'components/app-container';
import { Period } from 'core/enums/period.enum';
import ModelOutput from './model-output';

export type ForecastOutputTabProps = {
  period: Period;
};

export default function ForecastOutputTab({ period }: ForecastOutputTabProps) {
  return (
    <AppContainer>
      <ModelOutput period={period} />
    </AppContainer>
  );
}
