import { Box } from '@mui/material';
import { LazyExoticComponent } from 'react';

type JSXComponent = () => JSX.Element;

export type SVGIconProps = {
  color?: string;
  height?: number | string;
  Icon?: LazyExoticComponent<JSXComponent> | JSXComponent | React.ElementType;
};

export default function SVGIcon({ Icon, color, height = 100 }: SVGIconProps) {
  return (
    <Box
      sx={{
        height,
      }}
    >
      {Icon && <Icon fill={color || ''} style={{ height: height }} />}
    </Box>
  );
}
