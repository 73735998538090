import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import { ChartNavigationDirection } from 'core/enums/chart-navigation-direction';
import { ReactNode } from 'react';

const Button = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.viz.navigation.background.inactive,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  boxShadow: `0px 10px 53px 0px ${theme.viz.navigation.shadowColor}`,
  '&:hover': {
    backgroundColor: theme.viz.navigation.background.active,
  },
}));

export type ChartNavigationProps = {
  children: ReactNode;
  onClick?: (direction: ChartNavigationDirection) => void;
};

export default function ChartNavigation({
  children,
  onClick = () => null,
}: ChartNavigationProps) {
  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover': {
          '& .chart-navigation': {
            opacity: 1,
          },
        },
      }}
    >
      {children}
      <Box
        className="chart-navigation"
        sx={{ position: 'absolute', top: '50%', left: -20, opacity: 0.5 }}
      >
        <Button onClick={() => onClick(ChartNavigationDirection.left)}>
          <KeyboardArrowLeft />
        </Button>
      </Box>
      <Box
        className="chart-navigation"
        sx={{ position: 'absolute', top: '50%', right: 0, opacity: 0.5 }}
      >
        <Button onClick={() => onClick(ChartNavigationDirection.right)}>
          <KeyboardArrowRight />
        </Button>
      </Box>
    </Box>
  );
}
