import { Period } from 'core/enums/period.enum';
import { PeriodOption } from 'core/types/period.type';
import { t } from 'i18next';

export const NO_LEVEL_OPTION = {
  id: 'No',
  name: t`options.no`,
  level: 0,
};

export const ALL_LEVEL_OPTION = {
  id: 'all',
  name: t`options.allLevel`,
  level: 0,
};

export const ALL_JOB_OPTION = {
  id: 'all',
  name: t`options.allJob`,
  indent: false,
};

export const ALL_CLUSTER_OPTION = {
  id: 'national',
  name: t`options.allCluster`,
};

export const ALL_DISTRICT_OPTION = {
  id: 'national',
  name: t`options.allCluster`,
};

export const ALL_NOTE_CATEGORY = {
  id: 'all',
  name: t`options.allNote`,
};

export const PERIOD_OPTIONS: PeriodOption[] = [
  {
    period: Period.daily,
    name: `${t`field.frequency.daily.title`}(${t`time.days`})`,
  },
  {
    period: Period.monthly,
    name: `${t`field.frequency.monthly.title`}(${t`time.months`})`,
  },
];
