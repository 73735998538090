import moment from 'moment';
import { Period } from 'core/enums/period.enum';
import { LevelOption } from 'core/types/level.type';
import { Model } from 'core/types/model.type';
import {
  AccuracyDateRangeOutput,
  AccuracyDateRangeRequest,
  AccuracyMapOutput,
  AccuracyMapRequest,
  AccuracyTrendOutput,
  AccuracyTrendRequest,
  AggregateAccuracy,
  AggregateAccuracyRequest,
  AggregateOutput,
  AggregateOutputRequest,
  ChangeOutputRequest,
  ChangeOutputResponse,
  DemandOutput,
  DemandOutputRequest,
  DemandOutputResponse,
  EvolutionOutputRequest,
  EvolutionOutputResponse,
  GraphOutput,
  GraphOutputRequest,
  GraphOutputResponse,
  HorizonAccuracy,
  HorizonAccuracyRequest,
  MapOutput,
  MapOutputRequest,
  MetadataOutput,
  MetadataOutputRequest,
  MetricRange,
  MetricRangeRequest,
  VarianceOutput,
  VarianceRequest,
} from 'core/types/output.type';
import ApiService from './api.service';
import { DateFormat } from 'core/enums/date-format.enum';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { t } from 'i18next';
import { JobOption } from 'core/types/job.type';
import { HorizonRange } from 'core/types/horizon-range.type';
import { MetricOption } from 'core/types/metric.type';

export default class OutputServiceF {
  public static async getAggregateAccuracies(
    period: Period,
    horizonRange: HorizonRange,
    level: LevelOption
  ): Promise<AggregateAccuracy[]> {
    let params: AggregateAccuracyRequest = {
      from: horizonRange.from,
      to: horizonRange.to,
      frequency: period,
    };

    const { level0, level1, level2 } = level;

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    }

    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }

    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    try {
      const response = await ApiService.get<AggregateAccuracy[]>(
        'output/accuracy/aggregates',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getAccuracyMap(
    period: Period,
    startDate: string,
    endDate: string,
    level: LevelOption,
    job: MetricOption,
    model: Model
  ): Promise<AccuracyMapOutput[]> {
    let params: AccuracyMapRequest = {
      frequency: period,
      startDate,
      endDate,
      modelId: model.id,
    };

    const { level0, level1 } = level;
    const { job0Id, job1Id } = job;

    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }

    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<AccuracyMapOutput[]>(
        'output/accuracy/map',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getAccuracyRange(
    period: Period,
    startDate: string,
    endDate: string,
    model: Model
  ): Promise<AccuracyDateRangeOutput[]> {
    const params: AccuracyDateRangeRequest = {
      frequency: period,
      startDate,
      endDate,
      metricId: 'demand',
      modelId: model.id,
    };

    try {
      const response = await ApiService.get<AccuracyDateRangeOutput[]>(
        'output/accuracy/range',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getAccuracyTrend(
    period: Period,
    modelId: number,
    level: LevelOption,
    job: JobOption,
    startDate: string,
    endDate: string
  ): Promise<AccuracyTrendOutput[]> {
    let params: AccuracyTrendRequest = {
      metricId: 'demand',
      modelId,
      startDate,
      endDate,
      frequency: period,
    };

    const { level0, level1, level2 } = level;
    const { job1Id, job0Id } = job;

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    }

    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }

    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<AccuracyTrendOutput[]>(
        'output/accuracy/trends',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getHorizonAccuracies(
    period: Period,
    level: LevelOption,
    job: JobOption
  ): Promise<HorizonAccuracy[]> {
    let params: HorizonAccuracyRequest = {
      metricId: 'demand',
      frequency: period,
    };

    const { level0, level1 } = level;
    const { job0Id, job1Id } = job;

    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }

    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<HorizonAccuracy[]>(
        'output/accuracy/horizons',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getVariance(
    period: Period,
    level: LevelOption,
    job: JobOption,
    startDate: string,
    endDate: string,
    modelId: number,
    metrics: string[]
  ): Promise<VarianceOutput[]> {
    let params: VarianceRequest = {
      metrics,
      startDate,
      endDate,
      modelId,
      frequency: period,
    };

    const { level0, level1 } = level;
    const { job0Id, job1Id } = job;

    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }

    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<VarianceOutput[]>(
        'output/variance',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getDemandOutput(
    period: Period,
    model: Model,
    date: string,
    level0?: string
  ): Promise<DemandOutputResponse[]> {
    const params: DemandOutputRequest = {
      metrics: ['demand', 'capacity'],
      modelId: model.id,
      frequency: period,
      date,
    };

    if (level0) {
      params.level0 = level0;
    }

    try {
      const response = await ApiService.get<DemandOutputResponse[]>(
        'output/demand',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getGraphOutput(
    period: Period,
    model: Model,
    level: LevelOption,
    startDate: string,
    endDate: string,
    metrics: string[]
  ): Promise<GraphOutputResponse[]> {
    const { level0, level1, level2 } = level;

    let params: GraphOutputRequest = {
      metrics: metrics,
      modelId: model.id,
      frequency: period,
      startDate,
      endDate,
    };

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    } else {
      if (level1) {
        params = {
          ...params,
          level1: level1.id,
        };
      } else {
        if (level0) {
          params = {
            ...params,
            level0: level0.id,
          };
        }
      }
    }

    try {
      const response = await ApiService.get<GraphOutputResponse[]>(
        'output/graph',
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getAggregateOutput(
    period: Period,
    model: Model,
    level: LevelOption,
    startDate: string,
    endDate: string,
    metrics: string[]
  ): Promise<AggregateOutput[]> {
    const { level0, level1, level2 } = level;

    let params: AggregateOutputRequest = {
      metrics: metrics,
      modelId: model.id,
      frequency: period,
      startDate,
      endDate,
    };

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    }
    if (level1) {
      params = {
        ...params,
        level1: level1.id,
      };
    }
    if (level0) {
      params = {
        ...params,
        level0: level0.id,
      };
    }

    try {
      const response = await ApiService.get<AggregateOutput[]>(
        'output/aggregate',
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getListOutput(
    period: Period,
    model: Model,
    level: LevelOption,
    startDate: string,
    endDate: string,
    metrics: string[]
  ): Promise<GraphOutputResponse[]> {
    const { level0, level1, level2 } = level;

    let params: GraphOutputRequest = {
      metrics: metrics,
      modelId: model.id,
      frequency: period,
      startDate,
      endDate,
    };

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    } else {
      if (level1) {
        params = {
          ...params,
          level1: level1.id,
        };
      } else {
        if (level0) {
          params = {
            ...params,
            level0: level0.id,
          };
        }
      }
    }

    try {
      const response = await ApiService.get<GraphOutputResponse[]>(
        'output/list',
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getOutputChange(
    period: Period,
    level: LevelOption,
    date: string
  ): Promise<ChangeOutputResponse[]> {
    const { level0, level1, level2 } = level;

    let params: ChangeOutputRequest = {
      frequency: period,
      date,
    };

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    } else {
      if (level1) {
        params = {
          ...params,
          level1: level1.id,
        };
      } else {
        if (level0) {
          params = {
            ...params,
            level0: level0.id,
          };
        }
      }
    }

    try {
      const response = await ApiService.get<ChangeOutputResponse[]>(
        'output/change',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getEvolutionOutput(
    period: Period,
    models: string[],
    level: LevelOption,
    startDate: string,
    endDate: string,
    job: JobOption
  ): Promise<EvolutionOutputResponse[]> {
    const { level0, level1, level2 } = level;
    const { job0Id, job1Id } = job;

    let params: EvolutionOutputRequest = {
      metricId: 'demand',
      models,
      frequency: period,
      startDate,
      endDate,
    };

    if (level2) {
      params = {
        ...params,
        level2: level2.id,
      };
    } else {
      if (level1) {
        params = {
          ...params,
          level1: level1.id,
        };
      } else {
        if (level0) {
          params = {
            ...params,
            level0: level0.id,
          };
        }
      }
    }

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<EvolutionOutputResponse[]>(
        'output/evolution',
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getMetadataOutput(
    period: Period,
    model?: Model
  ): Promise<MetadataOutput> {
    let params: MetadataOutputRequest = {
      metrics: ['demand', 'capacity'],
      frequency: period,
    };

    if (model) {
      params = {
        ...params,
        modelId: model.id,
      };
    }

    try {
      const response = await ApiService.get<MetadataOutput>(
        'output/metadata',
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static getGraphTooltipData(
    date: string,
    dataA: GraphOutput[],
    keysA: string[],
    metricA: string,
    keysB: string[],
    metricB: string,
    dataB: GraphOutput[],
    forecastColorA: string,
    forecastColorB: string,
    patternColorA: string,
    patternColorB: string,
    colorScaleA: (value: string) => string,
    colorScaleB: (value: string) => string,
    setPattern: (value: string) => number,
    job: JobOption
  ) {
    const datumA = dataA.find(
      (el) =>
        moment(el.date).format(DateFormat.default) ===
        moment(date).format(DateFormat.default)
    );

    const datumB = dataB.find(
      (el) =>
        moment(el.date).format(DateFormat.default) ===
        moment(date).format(DateFormat.default)
    );

    const tooltipDataA: { legend: LegendItem; value?: number }[] = [
      {
        legend: {
          label: `${t`field.views.graphView.labels.forecast`} ${metricA}`,
          color: forecastColorA,
          type: LegendType.line,
        },
        value: datumA
          ? job.id === 'all'
            ? datumA.forecast['all']
            : datumA.forecast[job.job0]
          : undefined,
      },
      ...keysA.map((el) => ({
        legend: {
          label: `${t`field.views.graphView.labels.actual`} ${el} ${metricA}`,
          color: colorScaleA(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorA : '',
        },
        value: datumA ? datumA.actual[el] : undefined,
      })),
    ];

    const tooltipDataB: { legend: LegendItem; value?: number }[] = [
      {
        legend: {
          label: `${t`field.views.graphView.labels.forecast`} ${metricB}`,
          color: forecastColorB,
          type: LegendType.line,
        },
        value: datumB
          ? job.id === 'all'
            ? datumB.forecast['all']
            : datumB.forecast[job.name]
          : undefined,
      },
      ...keysB.map((el) => ({
        legend: {
          label: `${t`field.views.graphView.labels.actual`} ${el} ${metricB}`,
          color: colorScaleB(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorB : '',
        },
        value: datumB ? datumB.actual[el] : undefined,
      })),
    ];

    return { tooltipDataA, tooltipDataB };
  }

  public static getOutputMicroTooltipData(
    dataA: DemandOutput[],
    keysA: string[],
    metricA: string,
    keysB: string[],
    metricB: string,
    dataB: DemandOutput[],
    patternColorA: string,
    patternColorB: string,
    colorScaleA: (value: string) => string,
    colorScaleB: (value: string) => string,
    setPattern: (value: string) => number
  ) {
    const datumA = dataA[0];
    const datumB = dataB[0];

    const tooltipDataA: { legend: LegendItem; value?: number }[] = [
      ...keysA.map((el) => ({
        legend: {
          label: `${el} ${metricA}`,
          color: colorScaleA(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorA : '',
        },
        value: datumA ? datumA.forecast[el] : undefined,
      })),
    ];

    const tooltipDataB: { legend: LegendItem; value?: number }[] = [
      ...keysB.map((el) => ({
        legend: {
          label: `${el} ${metricB}`,
          color: colorScaleB(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorB : '',
        },
        value: datumB ? datumB.forecast[el] : undefined,
      })),
    ];

    return { tooltipDataA, tooltipDataB };
  }

  public static getAggregateTooltipData(
    dataA: AggregateOutput,
    keysA: string[],
    metricA: string,
    keysB: string[],
    metricB: string,
    dataB: AggregateOutput,
    patternColorA: string,
    patternColorB: string,
    colorScaleA: (value: string) => string,
    colorScaleB: (value: string) => string,
    setPattern: (value: string) => number
  ) {
    const datumA = dataA;
    const datumB = dataB;

    const tooltipDataA: { legend: LegendItem; value?: number }[] = [
      ...keysA.map((el) => ({
        legend: {
          label: `${el} ${metricA}`,
          color: colorScaleA(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorA : '',
        },
        value: datumA ? datumA.forecast[el] : undefined,
      })),
    ];

    const tooltipDataB: { legend: LegendItem; value?: number }[] = [
      ...keysB.map((el) => ({
        legend: {
          label: `${el} ${metricB}`,
          color: colorScaleB(el),
          type: LegendType.box,
          patternColor: setPattern(el) === 1 ? patternColorB : '',
        },
        value: datumB ? datumB.forecast[el] : undefined,
      })),
    ];

    return { tooltipDataA, tooltipDataB };
  }

  public static async getMetricRange(
    period: Period,
    modelId: string,
    metric: MetricOption
  ): Promise<MetricRange> {
    let params: MetricRangeRequest = {
      metricId: metric.metricId,
      modelId,
      frequency: period,
    };

    const { job1Id, job0Id } = metric;

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<MetricRange>(
        'output/metric-ranges',
        params
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getMapOutput(
    period: Period,
    modelId: string,
    startDate: string,
    endDate: string,
    metric: MetricOption
  ): Promise<MapOutput[]> {
    let params: MapOutputRequest = {
      metricId: metric.metricId,
      modelId,
      startDate,
      endDate,
      frequency: period,
    };

    const { job1Id, job0Id } = metric;

    if (job1Id) {
      params = {
        ...params,
        job1: job1Id,
      };
    }

    if (job0Id) {
      params = {
        ...params,
        job0: job0Id,
      };
    }

    try {
      const response = await ApiService.get<MapOutput[]>('output/map', params);

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  public static getJobOrder(jobId: string): number {
    switch (jobId) {
      case 'Copper':
        return 1;
      case 'Hybrid':
        return 2;
      case 'Fiber':
        return 3;
      default:
        return 1;
    }
  }
}
