import AppContainer from 'components/app-container';
import { Period } from 'core/enums/period.enum';
import ForecastAccuracy from './forecast-accuracy';
import HistoricalForecast from './historical-forecast';

export type ForecastAccuracyTabProps = {
  period: Period;
};

export default function ForecastAccuracyTab({
  period,
}: ForecastAccuracyTabProps) {
  return (
    <AppContainer>
      <ForecastAccuracy period={period} />
      <HistoricalForecast period={period} />
    </AppContainer>
  );
}
