import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  useTheme,
} from '@mui/material';
import ComparisonBarChart from 'components/charts/comparison-bar-chart';
import DatePicker from 'components/date-picker';
import { Period } from 'core/enums/period.enum';
import PageSection from 'components/page-section';
import Dropdown from 'components/dropdown';
import OutputServiceF from 'core/services/output.service';
import {
  ChangeOutputResponse,
  ComparisonData,
  ComparisonLineDatum,
} from 'core/types/output.type';
import { LevelOption } from 'core/types/level.type';
import { useStore } from 'core/hooks/useStore';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import DateService from 'core/services/date.service';
import { ParentSize } from '@visx/responsive';
import ComparisonLineChart from 'modules/field/shared/comparison-line-chart';
import { flatten, uniq } from 'lodash';
import ChartLoader from 'components/chart-loader';
import Snackbar from 'components/snackbar';

export type ForecastComparisonProps = {
  period: Period;
  initialDate: string;
  minDate?: string;
  maxDate?: string;
  initialLevel?: LevelOption;
};

export default function ForecastComparison({
  period,
  initialDate,
  minDate,
  maxDate,
  initialLevel,
}: ForecastComparisonProps) {
  const [open, setOpen] = useState(false);
  const { metadataF } = useStore();
  const [horizonA, setHorizonA] = useState('1');
  const [horizonB, setHorizonB] = useState('2');
  const [horizons, setHorizons] = useState<string[]>(['1', '2']);
  const [date, setDate] = useState(initialDate);
  const [kpi, setKpi] = useState('');
  const [category, setCategory] = useState('');
  const [level, setLevel] = useState<LevelOption>(
    initialLevel ? initialLevel : metadataF.levels[0]
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ChangeOutputResponse[]>([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const momentUnit = DateService.getMomentUnit(period);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getOutputChange(
          period,
          level,
          date
        );
        setData(result);
        setLoading(false);
        const availableHorizons = uniq(
          flatten(result.map((el) => el.value.map((d) => `${d.horizon}`)))
        );
        setHorizons(availableHorizons.length ? availableHorizons : ['1', '2']);
        setHorizonA(availableHorizons[0] || '1');
        setHorizonB(availableHorizons[1] || availableHorizons[0] || '1');
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, date]);

  const comparisonData: ComparisonData[] = data.map((el) => {
    const horizonAItem = el.value.find((d) => d.horizon === +horizonA);
    const horizonBItem = el.value.find((d) => d.horizon === +horizonB);

    const keys = uniq([
      ...Object.keys(horizonAItem?.output || {}),
      ...Object.keys(horizonBItem?.output || {}),
    ]).map((key) => {
      return {
        name: key,
        forecastA: horizonAItem?.output[key].forecast || 0,
        horizonA,
        forecastB: horizonBItem?.output[key].forecast || 0,
        horizonB,
        change: horizonAItem?.output[key].forecast
          ? (horizonBItem?.output[key].forecast || 0) /
              horizonAItem?.output[key].forecast -
            1
          : 0,
      };
    });
    return { data: keys, category: el.key };
  });

  const categoryData = data.find((el) => el.key === category);

  const lineData: ComparisonLineDatum[] = categoryData
    ? categoryData.value.map((el) => ({
        date: el.asOfDate,
        value: el.output[kpi] || 0,
        horizon: `${el.horizon}`,
      }))
    : [];

  const { minDate: safeMinDate, maxDate: safeMaxDate } =
    DateService.safelyExtractDateLimits(minDate, maxDate);

  const handleOpen = (selectedKpi: string, selectedCategory: string) => {
    setOpen(true);
    setKpi(selectedKpi);
    setCategory(selectedCategory);
  };

  const handleClose = () => {
    setOpen(false);
    setKpi('');
    setCategory('');
  };

  return (
    <PageSection
      title={t`field.views.forecastComparison.title`}
      tooltipData={t`field.views.forecastComparison.tooltip`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          padding: '24px 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <DatePicker
            label={t`dropdown.forecastDate`}
            views={[momentUnit]}
            minDate={safeMinDate}
            maxDate={safeMaxDate}
            value={date}
            setValue={(d) => {
              setDate(d);
            }}
          />
          <Dropdown
            selectedItem={horizonA}
            keyName="horizonA"
            options={horizons}
            onChange={(val) => setHorizonA(val)}
            formatLabel={(val) => {
              const tKey = `time.${DateService.getFrequencyTranslation(
                period
              )}${+val > 1 ? 's' : ''}`;
              return t('dropdown.horizon', {
                id: 'A',
                horizon: val,
                frequency: t(tKey),
              });
            }}
            style={{
              backgroundColor: theme.viz.comparison.horizonA,
              color: theme.viz.comparison.color,
              '& fieldset': {
                borderColor: 'transparent',
              },
              margin: {
                xs: '10px 0',
                md: '0 20px',
              },
            }}
          />
          <Dropdown
            selectedItem={horizonB}
            keyName="horizonA"
            options={horizons}
            onChange={(val) => setHorizonB(val)}
            formatLabel={(val) => {
              const tKey = `time.${DateService.getFrequencyTranslation(
                period
              )}${+val > 1 ? 's' : ''}`;
              return t('dropdown.horizon', {
                id: 'B',
                horizon: val,
                frequency: t(tKey),
              });
            }}
            style={{
              backgroundColor: theme.viz.comparison.horizonB,
              color: theme.viz.comparison.color,
              '& fieldset': {
                borderColor: 'transparent',
              },
            }}
          />
        </Box>
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      <div style={{ height: 2 }}>
        {loading && comparisonData.length > 0 && <LinearProgress />}
      </div>
      {loading && data.length < 1 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChartLoader width={500} height={500} />
        </Box>
      ) : (
        <div>
          {!comparisonData.length && !loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2>{t`charts.noData`}</h2>
            </Box>
          ) : (
            comparisonData.map((el, i, a) => (
              <Box
                key={`comparison-section-${el.category}`}
                sx={{ margin: '0 0 20px' }}
              >
                <Grid container paddingBottom={3}>
                  {!el.data.length && !loading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <h2>{t`charts.noData`}</h2>
                    </Box>
                  ) : (
                    el.data.map((d, j) => (
                      <Grid
                        item
                        xs={12}
                        key={`comparison-${el.category}-${j}`}
                        md={4}
                      >
                        <ComparisonBarChart
                          metric={d.name}
                          valueA={d.forecastA}
                          valueB={d.forecastB}
                          change={d.change}
                          footerLabel={t`field.views.forecastComparison.labels.comparisonChart`}
                          onClick={() => handleOpen(d.name, el.category)}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
                {i !== a.length - 1 && <Divider />}
              </Box>
            ))
          )}
        </div>
      )}
      <Dialog fullWidth maxWidth={'xl'} open={open} onClose={handleClose}>
        <DialogContent>
          <Box noValidate component="form">
            <h1>{kpi}</h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                padding: '24px 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  margin: {
                    xs: '0 0 20px',
                    md: '0',
                  },
                }}
              >
                <DatePicker
                  label={t`dropdown.forecastDate`}
                  views={[momentUnit]}
                  minDate={safeMinDate}
                  maxDate={safeMaxDate}
                  value={date}
                  setValue={(d) => {
                    setDate(d);
                  }}
                />
                <Dropdown
                  selectedItem={horizonA}
                  keyName="horizonA"
                  options={horizons}
                  onChange={(val) => setHorizonA(val)}
                  formatLabel={(val) => {
                    const tKey = `time.${DateService.getFrequencyTranslation(
                      period
                    )}${+val > 1 ? 's' : ''}`;
                    return t('dropdown.horizon', {
                      id: 'A',
                      horizon: val,
                      frequency: t(tKey),
                    });
                  }}
                  style={{
                    backgroundColor: theme.viz.comparison.horizonA,
                    color: theme.viz.comparison.color,
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    margin: {
                      xs: '10px 0',
                      md: '0 20px',
                    },
                  }}
                />
                <Dropdown
                  selectedItem={horizonB}
                  keyName="horizonA"
                  options={horizons}
                  onChange={(val) => setHorizonB(val)}
                  formatLabel={(val) => {
                    const tKey = `time.${DateService.getFrequencyTranslation(
                      period
                    )}${+val > 1 ? 's' : ''}`;
                    return t('dropdown.horizon', {
                      id: 'B',
                      horizon: val,
                      frequency: t(tKey),
                    });
                  }}
                  style={{
                    backgroundColor: theme.viz.comparison.horizonB,
                    color: theme.viz.comparison.color,
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                  }}
                />
              </Box>
              <LevelAutocomplete
                style={{
                  width: {
                    md: 200,
                  },
                }}
                value={level}
                onChange={(value) => {
                  if (value) {
                    setLevel(value);
                  }
                }}
              />
            </Box>
            <ParentSize>
              {({ width }) => (
                <ComparisonLineChart
                  width={width || 500}
                  height={500}
                  data={lineData}
                  period={period}
                  horizonA={horizonA}
                  horizonB={horizonB}
                  loading={loading}
                  actualDate={date}
                />
              )}
            </ParentSize>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t`actions.close`}</Button>
        </DialogActions>
      </Dialog>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </PageSection>
  );
}
