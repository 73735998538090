import { styled } from '@mui/material';

const Box = styled('div')({
  '@keyframes table-load': {
    '0%': {
      opacity: 0.3,
      backgroundPosition: '0% 0%',
    },
    '25%': {
      opacity: 0.5,
      backgroundPosition: '50% 0%',
    },
    '75%': {
      opacity: 1,
      backgroundPosition: '100% 0%',
    },
    '100%': {
      opacity: 0.5,
      backgroundPosition: '50% 0%',
    },
  },
  animation: '2s table-load ease-in-out alternate infinite',
  backgroundSize: '1000% 100%',
  width: '99%',
  height: '30px',
  borderRadius: '5px',
  backgroundImage:
    'linear-gradient(-90deg, rgba(150, 150, 150, 0.75), rgba(168, 167, 167, 0.3), rgba(150, 150, 150, 0.75))',
});

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
  overflowX: 'scroll',
});

const HeaderCell = styled('th')({
  padding: '5px',
  fontSize: '0.625rem',
  overflow: 'auto',
  border: '1px solid #FFFFFF',
});

const Cell = styled('td')({
  padding: '5px',
  fontSize: '0.625rem',
  overflow: 'auto',
  border: '1px solid #FFFFFF',
});

export default function TableLoader() {
  const columns = ['1', '2', '3', '4', '5', '6', '7', '8'];
  const rows = ['1', '2', '3', '4', '5'];
  return (
    <Table>
      <thead>
        <tr>
          {columns.map((el) => (
            <HeaderCell key={`loader-header-${el}`} />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((el) => (
          <tr key={`loader-row-${el}`}>
            <Cell>
              <Box />
            </Cell>
            {columns.map((d) => (
              <Cell key={`loader-cell-${d}`}>
                <Box />
              </Cell>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
