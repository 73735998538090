import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomPageService from 'core/services/custom-page.service';
import { CustomPage } from 'core/types/custom-page.type';

export type CustomPageResponse = {
  pages: CustomPage[];
  sharedPages: CustomPage[];
};

export const fetchCustomPages = createAsyncThunk<CustomPageResponse, void>(
  'customPages/fetchCustomPages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CustomPageService.getCustomPages();
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export type CustomPageState = {
  pages: CustomPage[];
  sharedPages: CustomPage[];
  error: boolean;
  loading: boolean;
};

const initialState: CustomPageState = {
  pages: [],
  sharedPages: [],
  error: false,
  loading: false,
};

export const customPageSlice = createSlice({
  name: 'CustomPage',
  initialState,
  reducers: {
    resetCustomPageError: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomPages.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(fetchCustomPages.fulfilled, (state, action) => {
      state.pages = [...(action.payload.pages || [])];
      state.sharedPages = [...(action.payload.sharedPages || [])];

      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchCustomPages.rejected, (state) => {
      state.error = false;
      state.loading = false;
    });
  },
});

export const { resetCustomPageError } = customPageSlice.actions;

export const customPageReducer = customPageSlice.reducer;
