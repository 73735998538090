import { useStore } from 'core/hooks/useStore';
import Autocomplete from 'components/autocomplete';
import { LevelOption } from 'core/types/level.type';
import { SxProps, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type LevelAutocompleteProps = {
  value: LevelOption;
  onChange: (value: LevelOption | null) => void;
  levelFilter?: (value: LevelOption) => boolean;
  style?: SxProps<Theme>;
  disabledOptions?: LevelOption[];
};

export default function LevelAutocomplete({
  value,
  style,
  onChange,
  levelFilter = () => true,
  disabledOptions,
}: LevelAutocompleteProps) {
  const { metadataF } = useStore();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Autocomplete
      label={t`dropdown.level`}
      style={style}
      options={metadataF.levels.filter(levelFilter)}
      onChange={(selectedLevel) => {
        onChange(selectedLevel as LevelOption);
      }}
      selectedValue={value}
      getOptionLabel={(el) => el.name}
      renderOption={(props, option, selected) => (
        <li
          {...props}
          style={{
            backgroundColor: selected
              ? theme.ui.autocomplete.selected.background
              : 'transparent',
            color: selected
              ? theme.ui.autocomplete.selected.color
              : theme.ui.autocomplete.color,
            fontWeight: option.level === 1 ? 'bold' : 'normal',
            paddingLeft:
              option.level === 0
                ? '25px'
                : option.level === 1
                ? '50px'
                : '75px',
          }}
        >
          {option.name}
        </li>
      )}
      disabledOptions={disabledOptions}
    />
  );
}
