import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CapacityPageService from 'core/services/capacity-page.service';
import { CapacityPage } from 'core/types/capacity-page.type';

export type CapacityPageResponse = {
  pages: CapacityPage[];
  sharedPages: CapacityPage[];
};

export const fetchCapacityPages = createAsyncThunk<CapacityPageResponse, void>(
  'capacityPages/fetchCapacityPages',
  async (_, { rejectWithValue }) => {
    try {
      const response = await CapacityPageService.getPages();
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export type CapacityPageState = {
  pages: CapacityPage[];
  sharedPages: CapacityPage[];
  error: boolean;
  loading: boolean;
};

const initialState: CapacityPageState = {
  pages: [],
  sharedPages: [],
  error: false,
  loading: false,
};

export const capacityPagSlice = createSlice({
  name: 'CapacityPage',
  initialState,
  reducers: {
    resetCapacityErrors: (state) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCapacityPages.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(fetchCapacityPages.fulfilled, (state, action) => {
      state.pages = [...(action.payload.pages || [])];
      state.sharedPages = [...(action.payload.sharedPages || [])];

      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchCapacityPages.rejected, (state) => {
      state.error = false;
      state.loading = false;
    });
  },
});

export const { resetCapacityErrors } = capacityPagSlice.actions;

export const capacityPageReducer = capacityPagSlice.reducer;
