import WebSocket from 'isomorphic-ws';
import { WSMessage } from '../types/ws-message.type';
import { filter, find } from 'lodash';
import { WEBSOCKET_STATUS_UPDATE } from '../../store/field';
import AuthService from './auth.service';

const WS_URL = process.env.REACT_APP_WEBSOCKET_URL || '';

type Subscription = { channel: string; callback: (data: WSMessage) => void };

export default class WebsocketService {
  private static instance: WebsocketService;

  public dispatch: any;
  public websocket: WebSocket | null = null;
  public subscriptions: Subscription[] = [];
  isOpen = false;

  public static getInstance(): WebsocketService {
    if (!WebsocketService.instance) {
      WebsocketService.instance = new WebsocketService();
    }
    return WebsocketService.instance;
  }

  public initSocket = async (dispatch: any) => {
    WebsocketService.instance.dispatch = dispatch;
    WebsocketService.instance.dispatch({
      type: WEBSOCKET_STATUS_UPDATE,
      payload: 'pending'
    });

    const tokens = await AuthService.getTokens();

    return new Promise((resolve, reject) => {
      WebsocketService.instance.websocket = new WebSocket(`${WS_URL}?idToken=${tokens.idToken}`);
      WebsocketService.instance.websocket.onopen = () => this.onConnOpen(resolve);
      WebsocketService.instance.websocket.onerror = () => this.onConnError(reject);
      WebsocketService.instance.websocket.onmessage = this.onMessage;
      WebsocketService.instance.websocket.onclose = () => this.onConnClose();
    });
  };

  onConnOpen = (resolve: any) => {
    WebsocketService.instance.isOpen = true;
    WebsocketService.instance.dispatch({
      type: WEBSOCKET_STATUS_UPDATE,
      payload: 'connected'
    });

    this.sendMessage('$connect', '');

    console.log('Websocket connected!');
    resolve();
  };

  onConnClose = (cb = () => {}) => {
    WebsocketService.instance.isOpen = false;
    WebsocketService.instance.dispatch({
      type: WEBSOCKET_STATUS_UPDATE,
      payload: 'disconnected'
    });
    WebsocketService.instance.websocket = null;
    console.log('Websocket closed!');
    cb();
  };

  onConnError = (reject: any) => {
    WebsocketService.instance.isOpen = false;
    WebsocketService.instance.dispatch({
      type: WEBSOCKET_STATUS_UPDATE,
      payload: 'failed'
    });
    WebsocketService.instance.websocket = null;
    console.log('Websocket failed!');
    reject();
  };

  onMessage = (event: WebSocket.MessageEvent) => {
    if (event) {
      const eventData = JSON.parse(event.data as string);

      const channel = eventData.channel || eventData.data.channel;
      const data = eventData.data || eventData;

      const subscription = find(this.subscriptions, { channel });

      if (subscription) {
        subscription.callback(data);
      }
    }
  };

  sendMessage = (action: string, data: any) => {
    if (this.websocket && this.isOpen) {
      this.websocket.send(
        JSON.stringify({
          action,
          ...data,
        })
      );
    } else {
      console.log(`Websocket connection not found!!`);
    }
  };

  subscribe = (channel: string, callback: (event: WSMessage) => void) => {
    if (!find(this.subscriptions, { channel })) {
      WebsocketService.instance.subscriptions.push({
        channel,
        callback,
      });
    }

    return this.subscriptions;
  };

  unsubscribe = (channel: string) => {
    WebsocketService.instance.subscriptions = filter(
      WebsocketService.instance.subscriptions,
      (s) => s.channel !== channel
    );

    return WebsocketService.instance.subscriptions;
  };
}
