import { useEffect, useState } from 'react';
import AuthService from 'core/services/auth.service';
import { User } from 'core/types/user.type';

export const useUser = (): [User | undefined, boolean] => {
  const [user, setUser] = useState<User>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    AuthService.getUserInformation().then((response: User | undefined) => {
      if (response) {
        setUser(response);
      }
      setLoggedIn(true);
    });
  }, []);
  return [user, loggedIn];
};
