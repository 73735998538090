import React from 'react';
import WebsocketService from 'core/services/websocket.service';

export type WebsocketHookContext = {
  websocket: WebsocketService | null,
  status: string,
  connect: (resubscribe?: boolean) => Promise<WebsocketService>,
  disconnect: () => void,
  subscribe: (channel: string, callback: (data: any) => void) => Promise<string[]>,
  unsubscribe: (channel: string) => void,
  broadcast: (channel: string, message: { [key: string]: number | string }) => void
}

const WebsocketContext = React.createContext<WebsocketHookContext>({
  status: 'disconnected',
  websocket: null,
  connect: async (): Promise<WebsocketService> => new Promise<WebsocketService>(() => {}),
  disconnect: () => {},
  subscribe: () => new Promise<string[]>(() => () => {}),
  unsubscribe: () => {},
  broadcast: () => {}
});

export default WebsocketContext;
