import { useStore } from 'core/hooks/useStore';
import MultiAutocomplete from 'components/multi-autocomplete';
import { LevelOption } from 'core/types/level.type';
import { Checkbox, SxProps, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type LevelMultiAutocompleteProps = {
  value: LevelOption[];
  onChange: (value: LevelOption[] | null) => void;
  levelFilter?: (value: LevelOption) => boolean;
  limitTags?: number;
  style?: SxProps<Theme>;
};

export default function LevelMultiAutocomplete({
  value,
  style,
  onChange,
  limitTags = 2,
  levelFilter = () => true,
}: LevelMultiAutocompleteProps) {
  const { metadataF } = useStore();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <MultiAutocomplete
      label={t`dropdown.level`}
      style={style}
      limitTags={limitTags}
      options={metadataF.levels.filter(levelFilter)}
      onChange={(selectedLevel) => {
        onChange(selectedLevel);
      }}
      selectedValue={value}
      getOptionLabel={(el) => el.name}
      renderOption={(props, option, selected) => (
        <li
          {...props}
          style={{
            backgroundColor: 'transparent',
            color: theme.ui.autocomplete.color,
            fontWeight: option.level === 1 ? 'bold' : 'normal',
            paddingLeft:
              option.level === 0
                ? '25px'
                : option.level === 1
                ? '50px'
                : '75px',
          }}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
    />
  );
}
