import { ClusterOption } from './../../core/types/cluster.type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ALL_CLUSTER_OPTION,
  ALL_DISTRICT_OPTION,
  ALL_JOB_OPTION,
  ALL_LEVEL_OPTION,
} from 'core/constants/options.constants';
import { DateFormat } from 'core/enums/date-format.enum';
import ApiService from 'core/services/api.service';
import { Job, JobOption } from 'core/types/job.type';
import {
  Level,
  LevelOption,
  District,
  CapacityLevelOption,
} from 'core/types/level.type';
import { Metric, MetricOption } from 'core/types/metric.type';
import { Model } from 'core/types/model.type';
import { NoteCategory } from 'core/types/note.type';
import { User } from 'core/types/user.type';
import { t } from 'i18next';
import moment from 'moment';
import { RootState } from 'store';
import { BucketCategory } from 'core/types/bucket.type.ts';
import { Scenario } from 'core/types/operational-planning.type';

export type MetadataResponse_F = {
  levels: LevelOption[];
  capacityLevels: CapacityLevelOption[];
  models: Model[];
  jobs0: Job[];
  jobs1: Job[];
  noteCategories: NoteCategory[];
  metrics: Metric[];
  users: User[];
  level1s: Level[];
  clusters: ClusterOption[];
  bucketCategories: BucketCategory[];
  runTimes: string[];
  scenarios: Scenario[];
  districts: District[];
};

export const fetchMetadataF = createAsyncThunk<MetadataResponse_F, void>(
  'metadataF/fetchMetadataF',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const { ...rest } = state.metadataF;
    try {
      const response = state.metadataF.init
        ? { ...rest }
        : await ApiService.get<MetadataResponse_F>('metadata');

      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export type MetadataState_F = {
  levels: LevelOption[];
  capacityLevels: CapacityLevelOption[];
  clusters: ClusterOption[];
  models: Model[];
  jobs: JobOption[];
  metricOptions: MetricOption[];
  metrics: Metric[];
  jobs0: Job[];
  jobs1: Job[];
  users: User[];
  noteCategories: NoteCategory[];
  bucketCategories: BucketCategory[];
  init: boolean;
  error: boolean;
  level1s: Level[];
  runTimes: string[];
  scenarios: Scenario[];
  districts: District[];
};

const initialState: MetadataState_F = {
  levels: [ALL_LEVEL_OPTION],
  capacityLevels: [],
  clusters: [ALL_CLUSTER_OPTION],
  bucketCategories: [],
  jobs: [],
  models: [],
  jobs0: [],
  jobs1: [],
  noteCategories: [],
  metricOptions: [],
  users: [],
  metrics: [],
  init: false,
  error: false,
  level1s: [],
  runTimes: [],
  scenarios: [],
  districts: [ALL_DISTRICT_OPTION],
};

export const metadataSliceF = createSlice({
  name: 'MetadataF',
  initialState,
  reducers: {
    resetError: (state) => {
      state.init = true;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMetadataF.pending, (state) => {
      state.error = false;
    });
    builder.addCase(fetchMetadataF.fulfilled, (state, action) => {
      state.models = [
        ...(action.payload.models.map((el) => ({
          ...el,
          runDatetime: moment.utc(el?.runDatetime).format(DateFormat.default),
          asOfDate: moment.utc(el?.asOfDate).format(DateFormat.default),
        })) || []),
      ];
      state.clusters =
        state.clusters.length > 1
          ? state.clusters
          : [...state.clusters, ...(action.payload.clusters || [])];
      state.districts =
        state.districts.length > 1
          ? state.districts
          : [...state.districts, ...(action.payload.districts || [])];
      // TODO: remove revers runTimes
      state.runTimes = [...(action.payload.runTimes || [])].reverse();
      state.jobs0 = [...(action.payload.jobs0 || [])];
      state.jobs1 = [...(action.payload.jobs1 || [])];
      state.metrics = [...(action.payload.metrics || [])];
      state.users = [...(action.payload.users || [])];
      state.scenarios = [...(action.payload.scenarios || [])];
      state.bucketCategories = [...(action.payload.bucketCategories || [])];
      state.noteCategories = [...(action.payload.noteCategories || [])];
      state.init = true;
      state.error = false;
      state.levels =
        state.levels.length > 1
          ? state.levels
          : [...state.levels, ...action.payload.levels];

      state.capacityLevels =
        state.capacityLevels.length > 1
          ? state.capacityLevels
          : [...state.capacityLevels, ...action.payload.capacityLevels];

      state.level1s = [...(action.payload.level1s || [])];

      state.jobs = action.payload.jobs0.reduce(
        (arr, el) => {
          arr.push({
            id: el.id,
            indent: false,
            name: el.name,
            job0: el.name,
            job0Id: el.id,
            job1: '',
            job1Id: '',
          });

          action.payload.jobs1.forEach((j) => {
            arr.push({
              id: `${el.id}${j.id}`,
              indent: true,
              name: `${el.name} ${j.name}`,
              job0: el.name,
              job0Id: el.id,
              job1: j.name,
              job1Id: j.id,
            });
          });

          return arr;
        },
        [ALL_JOB_OPTION] as JobOption[]
      );

      state.metricOptions = action.payload.metrics.reduce((arr, el) => {
        if (el.categoryId === 'demand' || el.categoryId === 'capacity') {
          const allOption = {
            id: el.id,
            indent: false,
            name: `${t`options.overall`} ${el.name}`,
            metricId: el.id,
            metricName: el.name,
            categoryId: el.categoryId,
          };

          const jobs = action.payload.jobs0.reduce((_arr, job0) => {
            if (el.id === 'demand' && job0.id === 'Hybrid') {
              return _arr;
            }
            _arr.push({
              id: `${job0.id}${el.name}`,
              indent: el.id === 'capacity',
              name: `${job0.name} ${el.name}`,
              job0: job0.name,
              job0Id: job0.id,
              job1: '',
              job1Id: '',
              metricId: el.id,
              metricName: el.name,
              categoryId: el.categoryId,
            });

            if (el.id === 'demand' && job0.id !== 'Hybrid') {
              action.payload.jobs1.forEach((j) => {
                _arr.push({
                  id: `${job0.id}${j.id}${el.name}`,
                  indent: true,
                  name: `${job0.name} ${j.name} ${el.name}`,
                  job0: job0.name,
                  job0Id: job0.id,
                  job1: j.name,
                  job1Id: j.id,
                  metricId: el.id,
                  metricName: el.name,
                  categoryId: el.categoryId,
                });
              });
            }

            return _arr;
          }, [] as MetricOption[]);
          return [...arr, ...[allOption, ...jobs]];
        }

        const metric = {
          id: el.id,
          indent: false,
          name: `${el.name}`,
          metricId: el.id,
          metricName: el.name,
          categoryId: el.categoryId,
        };

        return [...arr, metric];
      }, [] as MetricOption[]);
    });
    builder.addCase(fetchMetadataF.rejected, (state) => {
      state.error = true;
      state.init = true;
    });
  },
});

export const { resetError } = metadataSliceF.actions;

export const metadataReducerF = metadataSliceF.reducer;
