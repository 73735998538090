import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import moment from 'moment';
import numeral from 'numeral';
import {
  AccuracyTrendColors,
  AccuracyTrendSeries,
} from 'core/types/output.type';
import { DateFormat } from 'core/enums/date-format.enum';
import { Bar, LinePath } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useState } from 'react';
import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import { flatten, uniq } from 'lodash';
import { curveMonotoneX } from '@visx/curve';
import { ChartNavigationDirection } from 'core/enums/chart-navigation-direction';
import ChartNavigation from 'components/chart-navigation';
import { rangeDisplayed } from 'core/constants/forecast.constants';

const LegendWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '20px 20px 0',
});

const LegendRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const TooltipTitle = styled('p')({
  margin: '0 10px 10px',
  fontWeight: 'bold',
});

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  date: string;
  data: { legend: LegendItem; value?: number }[];
};

export type AccuracyTrendChartProps = {
  width: number;
  height: number;
  data: AccuracyTrendSeries;
  period: Period;
  loading?: boolean;
  colors?: AccuracyTrendColors;
  onNavigation?: (direction: ChartNavigationDirection) => void;
};

export default function AccuracyTrendChart({
  width,
  height,
  data,
  period,
  colors = {},
  loading = false,
  onNavigation = () => null,
}: AccuracyTrendChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [hoverValue, setHoverValue] = useState('');
  const margin = { top: 40, right: 0, bottom: 40, left: 75 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipDataOutput>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: { date: '', data: [] },
  });

  if (!loading && !Object.keys(data).length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && Object.keys(data).length < 1) {
    return <ChartLoader width={width} height={height} />;
  }

  const dateFormat = DateService.getDateFormat(period, 'MM/DD', 'MM/YY');

  const secondaryAxisDateFormat = DateService.getDateFormat(
    period,
    'ddd',
    'mmm'
  );

  const tooltipDateFormat = DateService.getDateFormat(
    period,
    'MMM D, YYYY',
    'MMM YYYY'
  );

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/

  const xDomain = uniq(
    flatten(
      Object.keys(data).map((el) =>
        data[el].map((d) => moment.utc(d.date).format(DateFormat.default))
      )
    )
  );
  xDomain.sort((a, b) => (moment(a).isBefore(moment(b)) ? -1 : 1));

  const xScale = scaleBand<string>({
    domain: xDomain,
    range: [margin.left, innerWidth],
  });

  const yValues = flatten(
    Object.keys(data).map((el) => data[el].map((d) => d.accuracy))
  );
  const yMax = Math.max(...yValues);
  const yMin = Math.min(...yValues);

  const yScale = scaleLinear<number>({
    domain: [yMin, yMax],
    range: [innerHeight, margin.top],
    nice: true,
  });

  const today = moment().format(DateFormat.default);
  const todayExists = xScale.domain().includes(today);

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|
  const handleTooltip = (_date: string, top: number) => {
    const _tooltipData: TooltipDataOutput = {
      date: moment.utc(_date).format(tooltipDateFormat),
      data: Object.keys(data).map((el) => {
        const _data = (data[el] || []).find(
          (d) =>
            moment.utc(d.date).format(DateFormat.default) ===
            moment.utc(_date).format(DateFormat.default)
        );

        return {
          legend: {
            label: el,
            type: LegendType.line,
            color: colors[el],
          },
          value: _data?.accuracy || 0,
        };
      }),
    };

    showTooltip({
      tooltipLeft: (xScale(_date) || 0) + 50,
      tooltipTop: top,
      tooltipData: _tooltipData,
    });
  };

  const handleCloseTooltip = () => {
    setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  //   _                           _
  //  | | ___  __ _  ___ _ __   __| |___
  //  | |/ _ \/ _` |/ _ \ '_ \ / _` / __|
  //  | |  __/ (_| |  __/ | | | (_| \__ \
  //  |_|\___|\__, |\___|_| |_|\__,_|___/
  //          |___/

  const legends: LegendItem[] = Object.keys(data).map((key) => ({
    label: key,
    color: colors[key],
    type: LegendType.line,
  }));

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ height: 2 }}>{loading && <LinearProgress />}</div>
        <LegendWrapper
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: {
              xs: 'start',
            },
          }}
        >
          <LegendRow
            sx={{
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              alignItems: {
                xs: 'start',
              },
            }}
          >
            {legends.map((el, i) => (
              <Legend
                key={`legend-trend-${i}`}
                item={el}
                legendStyle={{ width: 'auto' }}
              />
            ))}
          </LegendRow>
        </LegendWrapper>
        <ChartNavigation onClick={onNavigation}>
          <svg width={innerWidth} height={height}>
            <Group>
              <GridRows
                left={margin.left}
                scale={yScale}
                width={innerWidth}
                height={innerHeight}
                stroke={theme.viz.grid.stroke}
                strokeDasharray={theme.viz.grid.strokeDasharray}
              />
            </Group>
            <Group>
              {Object.keys(data).map((el, i) => (
                <Group key={`${el}-${i}`}>
                  <LinePath
                    stroke={colors[el] || theme.viz.default}
                    curve={curveMonotoneX}
                    strokeWidth={2}
                    data={data[el]}
                    x={(d) =>
                      xScale.bandwidth() / 2 +
                      (xScale(moment.utc(d.date).format(DateFormat.default)) ??
                        0)
                    }
                    y={(d) => yScale(d.accuracy) ?? 0}
                  />
                </Group>
              ))}
            </Group>
            <Group>
              {xScale.domain().map((el, i) => (
                <Group
                  key={`tooltip-g-${i}`}
                  onMouseLeave={() => {
                    setHoverValue('');
                    handleCloseTooltip();
                  }}
                  onMouseMove={(e) => {
                    if (el !== hoverValue) {
                      setHoverValue(el);
                    }
                    handleTooltip(el, e.screenY * 0.3);
                  }}
                  opacity={el === hoverValue ? 1 : 0}
                >
                  <LinePath
                    stroke={theme.viz.default}
                    strokeWidth={1}
                    strokeDasharray={5}
                    data={[
                      {
                        date: el,
                        value: yScale.domain()[0] || 0,
                      },
                      {
                        date: el,
                        value: yScale.domain()[1] || 1,
                      },
                    ]}
                    x={(d) =>
                      xScale.bandwidth() / 2 +
                      (xScale(moment.utc(d.date).format(DateFormat.default)) ??
                        0)
                    }
                    y={(d) => yScale(d.value) ?? 0}
                  />
                  {Object.keys(data).map((d) => {
                    const _color = colors[d];
                    const radius = 5;
                    const _value = data[d].find(
                      (f) =>
                        moment.utc(f.date).format(DateFormat.default) ===
                        moment.utc(el).format(DateFormat.default)
                    );
                    return _value ? (
                      <circle
                        key={`tooltip-marker-${d}-${i}`}
                        fill={_color || theme.viz.default}
                        cx={(xScale(el) || 0) + xScale.bandwidth() / 2}
                        cy={yScale(_value.accuracy)}
                        r={radius}
                      />
                    ) : null;
                  })}
                  <Bar
                    x={xScale(el)}
                    y={innerHeight - yScale(yMin)}
                    fill="transparent"
                    width={xScale.bandwidth()}
                    height={innerHeight - (innerHeight - yScale(yMin))}
                  />
                </Group>
              ))}
            </Group>
            <Group>
              {todayExists && (
                <rect
                  x={xScale(today) || 0}
                  y={innerHeight}
                  height={40}
                  width={xScale.bandwidth()}
                  fill={theme.viz.xAxis.background.highlight}
                />
              )}
            </Group>
            <Group>
              <AxisLeft
                left={margin.left}
                scale={yScale}
                hideTicks
                numTicks={5}
                tickFormat={(d) => numeral(d).format(NumeralFormat.percent)}
                stroke={theme.viz.yAxis.stroke}
                tickLabelProps={() => ({
                  fontSize: theme.viz.yAxis.fontSize,
                  fill: theme.viz.yAxis.color,
                  textAnchor: 'end',
                })}
              />
            </Group>
            <Group>
              <AxisBottom
                top={innerHeight}
                scale={xScale}
                numTicks={rangeDisplayed}
                hideTicks
                stroke={theme.viz.xAxis.stroke}
                tickFormat={(d) => moment.utc(d).format(dateFormat)}
                tickLabelProps={(d) => ({
                  fontSize: theme.viz.xAxis.fontSize,
                  fill:
                    moment.utc(d).format('d') === '0' && period === Period.daily
                      ? theme.viz.xAxis.color.accent
                      : theme.viz.xAxis.color.primary,
                  fontWeight: 'bold',
                  textAnchor: 'middle',
                })}
              />
            </Group>
            <Group>
              <AxisBottom
                top={innerHeight + 16}
                scale={xScale}
                stroke={theme.viz.xAxis.stroke}
                tickLabelProps={(d) => ({
                  fontSize: theme.viz.xAxis.fontSize,
                  fill:
                    moment.utc(d).format('d') === '0' && period === Period.daily
                      ? theme.viz.xAxis.color.accent
                      : theme.viz.xAxis.color.secondary,
                  textAnchor: 'middle',
                  fontWeight:
                    today === moment.utc(d).format(DateFormat.default)
                      ? 'bold'
                      : 'normal',
                })}
                numTicks={rangeDisplayed}
                hideAxisLine
                hideTicks
                tickFormat={(d) => {
                  const _tKey = `time.now.${period}`;
                  return today === moment.utc(d).format(DateFormat.default)
                    ? t(_tKey)
                    : moment.utc(d).format(secondaryAxisDateFormat);
                }}
              />
            </Group>
          </svg>
        </ChartNavigation>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 50,
              padding: 10,
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
            }}
          >
            <TooltipWrapper>
              <TooltipTitle>{tooltipData.date}</TooltipTitle>
              <TooltipTable>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 10px',
                  }}
                >
                  {tooltipData.data.map((el, i) => (
                    <Box
                      key={`tooltip-trend-${i}`}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Legend item={el.legend} />
                      {el.value
                        ? numeral(el.value).format(NumeralFormat.percent)
                        : ''}
                    </Box>
                  ))}
                </Box>
              </TooltipTable>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
