import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import moment from 'moment';
import numeral from 'numeral';
import {
  GraphOutputChartDatum,
  GraphOutputResponse,
} from 'core/types/output.type';
import { DateFormat } from 'core/enums/date-format.enum';
import { Bar, BarStack, LinePath } from '@visx/shape';
import { GridRows } from '@visx/grid';
import {
  Box,
  IconButton,
  LinearProgress,
  styled,
  useTheme,
} from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { JobOption } from 'core/types/job.type';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useState } from 'react';
import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import OutputServiceF from 'core/services/output.service';
import { Note } from 'core/types/note.type';
import NoteStack from 'components/note-stack';
import NoteServiceF from 'core/services/notes.service';
import NoteBox from './note-box';
import CloseIcon from '@mui/icons-material/Close';
import { uniq } from 'lodash';

const LegendWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: '20px 0',
});

const LegendRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipTitle = styled('p')({
  margin: '0 10px 10px',
  fontWeight: 'bold',
});

const TooltipHeader = styled('p')({
  margin: '10px 0',
  borderBottom: '1px solid gray',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  date: string;
  dataA: { legend: LegendItem; value?: number }[];
  dataB: { legend: LegendItem; value?: number }[];
};

export type OutputChartProps = {
  width: number;
  height: number;
  data: GraphOutputResponse[];
  job: JobOption;
  period: Period;
  colorRangeA?: string[];
  forecastColorA?: string;
  patternColorA?: string;
  colorRangeB?: string[];
  forecastColorB?: string;
  patternColorB?: string;
  setPattern?: (key: string) => number;
  loading?: boolean;
  notes: Note[];
};

export default function OutputChart({
  width,
  height,
  data,
  job,
  period,
  colorRangeA = [],
  forecastColorA = '',
  patternColorA = '',
  colorRangeB = [],
  forecastColorB = '',
  patternColorB = '',
  setPattern = () => 0,
  loading = false,
  notes,
}: OutputChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [hoverValue, setHoverValue] = useState('');
  const margin = { top: 40, right: 0, bottom: 40, left: 75 };
  const _innerWidth = width - margin.left - margin.right;
  const innerWidth = _innerWidth > 0 ? _innerWidth : 0;
  const innerHeight = height - margin.top - margin.bottom;
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const groupedNotes = NoteServiceF.getGroupedNotes(notes);
  const NOTE_TOP_MARGIN = 30;
  const NOTES_AREA_HEIGHT = 10;
  const NOTES_AREA_HEIGHT_AND_ARROW = 25;
  const [selectedNoteDate, setSelectedNoteDate] = useState<string>('');
  const [displayNote, setDisplayNote] = useState(false);

  const maxCount = Math.max(
    ...Object.keys(groupedNotes).map((key) => groupedNotes[key].count),
    0
  );

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipDataOutput>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: { date: '', dataA: [], dataB: [] },
  });

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && data.length < 2) {
    return <ChartLoader width={width} height={height} />;
  }

  const BAR_SPACING = 3;
  const TOOLTIP_TOP = 150;

  const primaryAxisDateFormat = DateService.getDateFormat(
    period,
    'MM/DD',
    'MM/YY'
  );
  const secondaryAxisDateFormat = DateService.getDateFormat(
    period,
    'ddd',
    'mmm'
  );
  const tooltipDateFormat = DateService.getDateFormat(
    period,
    'MMM D, YYYY',
    'MMM YYYY'
  );

  let tooltipTimeout: number;

  const dataA = data.find((el) => el.key === 'Capacity') || {
    key: '',
    value: [],
  };
  const dataB = data.find((el) => el.key === 'Demand') || {
    key: '',
    value: [],
  };

  //         _       _
  //    __| | __ _| |_ __ _
  //   / _` |/ _` | __/ _` |
  //  | (_| | (_| | || (_| |
  //   \__,_|\__,_|\__\__,_|

  const keysA = [...(dataA.value[0] ? dataA.value[0].keys : [])]
    .filter((el) => (job?.id === 'all' ? true : el.includes(job?.job0 || '')))
    .sort(
      (a, b) => OutputServiceF.getJobOrder(a) - OutputServiceF.getJobOrder(b)
    );

  const keysB = [...(dataB.value[0] ? dataB.value[0].keys : [])].filter((el) =>
    job?.id === 'all' ? true : el.includes(job?.name || '')
  );

  const forecastA = (dataA ? dataA.value : []).map((el) => ({
    date: moment(el.date).format(DateFormat.default),
    value: job.id === 'all' ? el.forecast['all'] : el.forecast[job.job0],
  }));

  const forecastB = (dataB ? dataB.value : []).map((el) => ({
    date: moment(el.date).format(DateFormat.default),
    value: job.id === 'all' ? el.forecast['all'] : el.forecast[job.name],
  }));

  const actualA = (dataA ? dataA.value : []).map((el) => ({
    date: moment(el.date).format(DateFormat.default),
    value: job.id === 'all' ? el.actual['all'] : el.actual[job.job0],
  }));

  const actualB = (dataB ? dataB.value : []).map((el) => ({
    date: moment(el.date).format(DateFormat.default),
    value: job.id === 'all' ? el.actual['all'] : el.actual[job.name],
  }));

  const xDomain = uniq([
    ...dataA.value.map((d) => moment.utc(d.date).format(DateFormat.default)),
    ...dataB.value.map((d) => moment.utc(d.date).format(DateFormat.default)),
  ]).sort((a: string, b: string) =>
    moment.utc(a).isBefore(moment.utc(b)) ? -1 : 1
  );

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/
  const xScale = scaleBand<string>({
    domain: xDomain,
    range: [margin.left, innerWidth],
  });

  const yMax = Math.max(
    ...forecastA.map((el) => el.value),
    ...forecastB.map((el) => el.value),
    ...actualA.map((el) => el.value),
    ...actualB.map((el) => el.value)
  );

  const yScale = scaleLinear<number>({
    domain: [0, yMax || 1],
    range: [innerHeight, margin.top],
    nice: true,
  });

  const colorScaleA = scaleOrdinal<string, string>({
    domain: (dataA.value[0] ? dataA.value[0].keys : []).sort((a, b) =>
      a.localeCompare(b)
    ),
    range: colorRangeA,
  });

  const colorScaleB = scaleOrdinal<string, string>({
    domain: (dataB.value[0] ? dataB.value[0].keys : []).sort((a, b) =>
      a.localeCompare(b)
    ),
    range: colorRangeB,
  });

  const colorScaleForecast = scaleOrdinal<string, string>({
    domain: [dataA.key, dataB.key],
    range: [forecastColorA, forecastColorB],
  });

  const today = moment().format(DateFormat.default);
  const todayExists = xScale.domain().includes(today);

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|

  const handleBarTooltip = (
    bar: { x: number; y: number; width: number },
    datum: GraphOutputChartDatum
  ) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const left = bar.x + bar.width - margin.left;

    const { tooltipDataA, tooltipDataB } = OutputServiceF.getGraphTooltipData(
      datum.date as string,
      dataA.value,
      keysA,
      dataA.key,
      keysB,
      dataB.key,
      dataB.value,
      forecastColorA,
      forecastColorB,
      patternColorA,
      patternColorB,
      colorScaleA,
      colorScaleB,
      setPattern,
      job
    );

    showTooltip({
      tooltipLeft: left,
      tooltipTop: TOOLTIP_TOP,
      tooltipData: {
        date: moment(datum.date).format(DateFormat.default),
        dataA: tooltipDataA,
        dataB: tooltipDataB,
      },
    });
  };

  const handleTooltip = (_date: string, top: number) => {
    const { tooltipDataA, tooltipDataB } = OutputServiceF.getGraphTooltipData(
      _date,
      dataA.value,
      keysA,
      dataA.key,
      keysB,
      dataB.key,
      dataB.value,
      forecastColorA,
      forecastColorB,
      patternColorA,
      patternColorB,
      colorScaleA,
      colorScaleB,
      setPattern,
      job
    );
    showTooltip({
      tooltipLeft: (xScale(_date) || 0) + 50,
      tooltipTop: top,
      tooltipData: {
        date: _date,
        dataA: tooltipDataA,
        dataB: tooltipDataB,
      },
    });
  };

  const handleCloseTooltip = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  //   _                           _
  //  | | ___  __ _  ___ _ __   __| |___
  //  | |/ _ \/ _` |/ _ \ '_ \ / _` / __|
  //  | |  __/ (_| |  __/ | | | (_| \__ \
  //  |_|\___|\__, |\___|_| |_|\__,_|___/
  //          |___/

  const legendsA: LegendItem[] = [];

  if (dataA.key !== '') {
    legendsA.push(
      {
        label: `${t`field.views.graphView.labels.forecast`} ${dataA.key}`,
        color: forecastColorA,
        type: LegendType.line,
      },
      ...(dataA.value[0] ? dataA.value[0].keys : []).map((el) => ({
        label: `${t`field.views.graphView.labels.actual`} ${el} ${dataA.key}`,
        color: colorScaleA(el),
        type: LegendType.box,
        patternColor: setPattern(el) === 1 ? patternColorA : '',
      }))
    );
  }

  const legendsB: LegendItem[] = [];
  if (dataB.key !== '') {
    legendsB.push(
      {
        label: `${t`field.views.graphView.labels.forecast`} ${dataB.key}`,
        color: forecastColorB,
        type: LegendType.line,
      },
      ...(dataB.value[0] ? dataB.value[0].keys : []).map((el) => ({
        label: `${t`field.views.graphView.labels.actual`} ${el} ${dataB.key}`,
        color: colorScaleB(el),
        type: LegendType.box,
        patternColor: setPattern(el) === 1 ? patternColorB : '',
      }))
    );
  }

  const onNoteClick = (selectedDate: string) => {
    if (selectedNoteDate === selectedDate) setDisplayNote(!displayNote);
    else {
      setDisplayNote(true);
      setSelectedNoteDate(selectedDate);
    }
  };

  const resetSelectedNote = () => {
    setSelectedNoteDate('');
    setDisplayNote(false);
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ height: 2 }}>{loading && <LinearProgress />}</div>
        <LegendWrapper>
          <LegendRow>
            {legendsA.map((el, i) => (
              <Legend key={`legend-A-${i}`} item={el} width={220} />
            ))}
          </LegendRow>
          <LegendRow>
            {legendsB.map((el, i) => (
              <Legend key={`legend-B-${i}`} item={el} width={220} />
            ))}
          </LegendRow>
        </LegendWrapper>
        <svg
          width={innerWidth}
          height={
            height +
            (displayNote ? NOTES_AREA_HEIGHT_AND_ARROW : NOTES_AREA_HEIGHT)
          }
        >
          <Group>
            <GridRows
              left={margin.left}
              scale={yScale}
              width={innerWidth}
              height={innerHeight}
              stroke={theme.viz.grid.stroke}
              strokeDasharray={theme.viz.grid.strokeDasharray}
            />
          </Group>
          <Group top={margin.top}>
            <BarStack<GraphOutputChartDatum, string>
              data={dataA.value.map((d) => ({
                date: moment(d.date).format(DateFormat.default),
                ...d.actual,
              }))}
              keys={keysA}
              x={(d) => d.date}
              xScale={xScale}
              yScale={yScale}
              color={colorScaleA}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <Group key={`bar-stack-A-${barStack.index}-${bar.index}`}>
                      <PatternLines
                        id={`bar-stack-A-${barStack.index}-${bar.index}`}
                        height={5}
                        width={5}
                        stroke={patternColorA}
                        background={bar.color || theme.viz.default}
                        strokeWidth={setPattern(bar.key)}
                        orientation={['diagonal']}
                      />
                      <rect
                        x={bar.x + bar.width / 4 - BAR_SPACING}
                        y={bar.y - margin.top}
                        height={bar.height}
                        width={bar.width / 4}
                        fill={`url('#${`bar-stack-A-${barStack.index}-${bar.index}`}')`}
                        onMouseLeave={handleCloseTooltip}
                        onMouseMove={() =>
                          handleBarTooltip(bar, bar.bar['data'])
                        }
                      />
                    </Group>
                  ))
                )
              }
            </BarStack>
            <BarStack<GraphOutputChartDatum, string>
              data={dataB.value.map((d) => ({
                date: moment(d.date).format(DateFormat.default),
                ...d.actual,
              }))}
              keys={keysB}
              x={(d) => d.date}
              xScale={xScale}
              yScale={yScale}
              color={colorScaleB}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => {
                    return (
                      <Group key={`bar-stack-B-${barStack.index}-${bar.index}`}>
                        <PatternLines
                          id={`bar-stack-B-${barStack.index}-${bar.index}`}
                          height={5}
                          width={5}
                          stroke={patternColorB}
                          background={bar.color || theme.viz.default}
                          strokeWidth={setPattern(bar.key)}
                          orientation={['diagonal']}
                        />
                        <rect
                          x={bar.x + bar.width / 2 + BAR_SPACING}
                          y={bar.y - margin.top}
                          height={bar.height}
                          width={bar.width / 4}
                          fill={`url('#${`bar-stack-B-${barStack.index}-${bar.index}`}')`}
                          onMouseLeave={handleCloseTooltip}
                          onMouseMove={() =>
                            handleBarTooltip(bar, bar.bar['data'])
                          }
                        />
                      </Group>
                    );
                  })
                )
              }
            </BarStack>
          </Group>
          <Group>
            <LinePath
              stroke={forecastColorA || theme.viz.default}
              strokeWidth={2}
              data={forecastA}
              x={(d) => xScale.bandwidth() / 2 + (xScale(d.date) ?? 0)}
              y={(d) => yScale(d.value) ?? 0}
            />
            <LinePath
              stroke={forecastColorB || theme.viz.default}
              strokeWidth={2}
              data={forecastB}
              x={(d) => xScale.bandwidth() / 2 + (xScale(d.date) ?? 0)}
              y={(d) => yScale(d.value) ?? 0}
            />
          </Group>
          <Group>
            {xScale.domain().map((el, i) => (
              <Group
                key={`tooltip-g-output-${i}`}
                onMouseLeave={() => {
                  setHoverValue('');
                  handleCloseTooltip();
                }}
                onMouseMove={(e) => {
                  if (el !== hoverValue) {
                    setHoverValue(el);
                  }
                  handleTooltip(el, e.screenY * 0.3);
                }}
                opacity={el === hoverValue ? 1 : 0}
              >
                <LinePath
                  stroke={theme.viz.default}
                  strokeWidth={1}
                  strokeDasharray={5}
                  data={[
                    {
                      date: el,
                      value: yScale.domain()[0] || 0,
                    },
                    {
                      date: el,
                      value: yScale.domain()[1] || 1,
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment.utc(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value) ?? 0}
                />
                {data.map((d) => {
                  const _color = colorScaleForecast(d.key);
                  const radius = 5;
                  const _value = d.value.find(
                    (f) =>
                      moment.utc(f.date).format(DateFormat.default) ===
                      moment.utc(el).format(DateFormat.default)
                  );
                  return _value ? (
                    <circle
                      key={`tooltip-marker-output-${d.key}-${el}-${i}`}
                      fill={_color || theme.viz.default}
                      cx={(xScale(el) || 0) + xScale.bandwidth() / 2}
                      cy={yScale(_value.forecast['all'])}
                      r={radius}
                    />
                  ) : null;
                })}
                <Bar
                  x={xScale(el)}
                  y={innerHeight - yScale(0)}
                  fill="transparent"
                  width={xScale.bandwidth()}
                  height={innerHeight - (innerHeight - yScale(0))}
                />
              </Group>
            ))}
          </Group>
          <Group>
            <AxisLeft
              left={margin.left}
              scale={yScale}
              hideTicks
              numTicks={5}
              stroke={theme.viz.yAxis.stroke}
              tickLabelProps={() => ({
                fontSize: theme.viz.yAxis.fontSize,
                fill: theme.viz.yAxis.color,
                textAnchor: 'end',
              })}
            />
          </Group>
          <Group>
            {todayExists && (
              <rect
                x={xScale(today) || 0}
                y={innerHeight}
                height={40}
                width={xScale.bandwidth()}
                fill={theme.viz.xAxis.background.highlight}
              />
            )}
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              numTicks={unitsDisplayed}
              hideTicks
              stroke={theme.viz.xAxis.stroke}
              tickFormat={(d) => moment(d).format(primaryAxisDateFormat)}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  moment(d).format('d') === '0' && period === Period.daily
                    ? theme.viz.xAxis.color.accent
                    : theme.viz.xAxis.color.primary,
                fontWeight: 'bold',
                textAnchor: 'middle',
              })}
            />
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight + 16}
              scale={xScale}
              stroke={theme.viz.xAxis.stroke}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  moment(d).format('d') === '0' && period === Period.daily
                    ? theme.viz.xAxis.color.accent
                    : theme.viz.xAxis.color.secondary,
                textAnchor: 'middle',
                fontWeight:
                  today === moment(d).format(DateFormat.default)
                    ? 'bold'
                    : 'normal',
              })}
              numTicks={unitsDisplayed}
              hideAxisLine
              hideTicks
              tickFormat={(d) => {
                const tKey = `time.now.${period}`;
                return today === moment(d).format(DateFormat.default)
                  ? t(tKey)
                  : moment(d).format(secondaryAxisDateFormat);
              }}
            />
          </Group>
          {notes && notes.length > 0 && (
            <NoteStack
              xScale={xScale}
              groupedNotes={groupedNotes}
              startHeight={innerHeight + NOTE_TOP_MARGIN}
              endHeight={
                innerHeight +
                (displayNote ? NOTES_AREA_HEIGHT_AND_ARROW : NOTES_AREA_HEIGHT)
              }
              maxCount={maxCount}
              onClick={onNoteClick}
              displayNote={displayNote}
              selectedNoteDate={selectedNoteDate}
            />
          )}
          {selectedNoteDate && displayNote && <foreignObject></foreignObject>}
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 60,
              padding: 20,
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
            }}
          >
            <TooltipWrapper>
              <TooltipTitle>
                {moment(tooltipData.date).format(tooltipDateFormat)}
              </TooltipTitle>
              <TooltipTable>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 10px',
                  }}
                >
                  {dataA.key && (
                    <>
                      <TooltipHeader>{dataA.key}</TooltipHeader>
                      {tooltipData.dataA.map((el, i) => (
                        <Box
                          key={`tooltip-A-${i}`}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Legend item={el.legend} />
                          {numeral(el.value).format(NumeralFormat.default)}
                        </Box>
                      ))}
                    </>
                  )}
                  {dataB.key && (
                    <>
                      <TooltipHeader>{dataB.key}</TooltipHeader>
                      {tooltipData.dataB.map((el, i) => (
                        <Box
                          key={`tooltip-B-${i}`}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Legend item={el.legend} />
                          {numeral(el.value).format(NumeralFormat.default)}
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </TooltipTable>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
        <Box
          style={{
            marginLeft: margin.left,
            marginTop: -8,
            width: innerWidth - margin.left,
            backgroundColor: theme.ui.noteBox.background,
            height: displayNote ? 255 : 0,
            transition: 'height 0.2s',
            overflow: 'hidden',
            borderTop: `${
              displayNote ? `1px solid ${theme.ui.noteBox.topBorder}` : 'none'
            }`,
            padding: `0 0 ${displayNote ? 20 : 0}px`,
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'end'}
            alignItems={'end'}
            position={'relative'}
          >
            <IconButton
              onClick={() => resetSelectedNote()}
              style={{
                position: 'absolute',
                right: 0,
                top: 5,
                cursor: 'pointer',
              }}
            >
              <CloseIcon
                style={{
                  fill: theme.ui.noteBox.closeIconColor,
                }}
              />
            </IconButton>
          </Box>
          <Box
            style={{
              height: displayNote ? 255 : 0,
              overflowY: 'auto',
              padding: '10px 50px 0 20px',
            }}
          >
            {groupedNotes[selectedNoteDate]?.notes.map((note) => {
              return <NoteBox note={note} key={`note-item-${note.id}`} />;
            })}
          </Box>
        </Box>
      </div>
    </div>
  );
}
