import { LinearProgress } from '@mui/material';
import Snackbar from 'components/snackbar';
import AppContainer from 'components/app-container';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import OutputServiceF from 'core/services/output.service';
import { MetadataOutput } from 'core/types/output.type';
import { useEffect, useState } from 'react';
import ForecastComparison from './forecast-comparison';
import ForecastEvolution from './forecast-evolution';
import { LevelOption } from 'core/types/level.type';

export type ForecastChangeTabProps = {
  period: Period;
  initialDate?: string;
  initialLevel?: LevelOption;
};
export default function ForecastChangeTab({
  period,
  initialDate,
  initialLevel,
}: ForecastChangeTabProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MetadataOutput>();
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getMetadataOutput(period);

        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period]);

  const { startDate } = DateService.generateDateRange(
    DateService.getMomentUnit(period),
    0,
    0,
    initialDate ? initialDate : data?.maxActualDate
  );

  return (
    <AppContainer>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <ForecastEvolution
            initialDate={startDate}
            minDate={data?.minDate}
            maxDate={data?.maxDate}
            period={period}
            initialLevel={initialLevel}
          />
          <ForecastComparison
            initialDate={startDate}
            minDate={data?.minDate}
            maxDate={data?.maxDate}
            period={period}
            initialLevel={initialLevel}
          />
        </>
      )}
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </AppContainer>
  );
}
