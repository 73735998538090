import { createTheme } from '@mui/material/styles';

const colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  blue: '#0750F9',
  differentBlue: '#2351FF',
  brightBlue: '#00aaef',
  vividBlue: '#00a9f4',
  softBlue: '#3F67FF',
  babyBlue: '#A3BDFC',
  darkBlue: '#141928',
  gold: '#ffd048',
  lightWhite: '#F6F6F6',
  darkWhite: '#E6E6E6',
  neutral: '#F0F0F0',
  black: '#000000',
  darkGray: '#333333',
  gray: '#757575',
  highGray: '#979797',
  midGray: '#B3B3B3',
  lightGray: '#D0D0D0',
  teal: '#3C96B4',
  pink: '#FF96BC',
  coral: '#FE9B90',
  purple: '#8C5AC8',
  cyan: '#AAE6F0',
  activeRed: '#FF0037',
  deepCarrotOrange: '#E86238',
  electricGreen: '#61D9C3',
  lightBlue: '#EBF4F7',
  low: '#EB5757',
  mid: '#F2C94C',
  high: '#27AE60',
  shadow: 'rgba(0, 0, 0, 0.2)',
  legendGray: '#919191',
};

const brand = {
  primary: '#FF0037',
  secondary: '#141928',
  tertiary: '#1F2432',
  accent: '#96FFF6',
};

const navigation = {
  background: brand.primary,
  logo: {
    width: 200,
  },
  avatar: {
    color: brand.primary,
    background: colors.white,
  },
  menu: {
    icon: {
      color: colors.white,
    },
    background: brand.secondary,
  },
  link: {
    fontSize: '0.875rem',
    fontWeight: {
      inactive: 'bold',
      active: 'bold',
    },
    color: {
      inactive: colors.white,
      active: brand.accent,
    },
    border: {
      width: 9,
      color: {
        inactive: colors.transparent,
        active: brand.accent,
      },
    },
  },
};

const header = {
  background: brand.secondary,
  banner: {
    background: brand.accent,
    color: colors.black,
  },
  home: {
    box: {
      background: brand.tertiary,
      title: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        color: brand.primary,
      },
      subtitle: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: colors.lightGray,
      },
    },
    details: {
      fontWeight: 'bold',
      color: colors.white,
      hover: {
        color: brand.accent,
      },
    },
  },
};

const tabs = {
  route: {
    title: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    subtitle: {
      fontWeight: 'normal',
      fontSize: '1.25rem',
    },
    color: {
      inactive: colors.gray,
      active: brand.accent,
    },
  },
  header: {
    background: brand.secondary,
    divider: colors.transparent,
    color: {
      inactive: colors.white,
      active: brand.accent,
    },
    border: {
      width: 9,
      color: brand.accent,
    },
    text: {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
  },
  notification: brand.primary,
  background: colors.white,
  divider: colors.neutral,
  color: {
    inactive: colors.black,
    active: colors.black,
  },
  border: {
    width: 9,
    color: brand.primary,
  },
  text: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  buttons: {
    addPage: {
      background: colors.white,
      color: colors.black,
    },
  },
};

const text = {
  header: {
    title: {
      fontFamily: 'BandwidthDisplay',
      fontSize: '2.25rem',
      fontWeight: 'bold',
      color: brand.primary,
      letterSpacing: '1.5px',
    },
    subtitle: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: brand.accent,
    },
    primary: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: colors.white,
    },
    secondary: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: colors.midGray,
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: colors.black,
  },
  tooltip: {
    background: colors.white,
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: colors.gray,
  },
  primary: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: colors.black,
  },
  secondary: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    color: colors.gray,
  },
};

const button = {
  primary: {
    backgroundColor: brand.primary,
    color: colors.white,
    hover: {
      color: colors.white,
      backgroundColor: brand.primary,
    },
  },
  secondary: {
    backgroundColor: brand.secondary,
    color: colors.white,
    hover: {
      color: colors.white,
      backgroundColor: brand.secondary,
    },
  },
  accent: {
    backgroundColor: brand.accent,
    color: colors.black,
    hover: {
      color: colors.black,
      backgroundColor: brand.accent,
    },
  },
};

const modifiedIndicator = {
  color: colors.gold,
  secondaryColor: colors.differentBlue,
};

const dropdown = {
  strong: {
    background: colors.black,
    color: colors.white,
  },
};

const dateSlider = {
  thumb: colors.black,
  rail: colors.neutral,
  label: {
    color: colors.black,
    fontSize: '1rem',
  },
};

const pillToggle = {
  background: colors.darkWhite,
  label: {
    active: colors.white,
    normal: brand.secondary,
  },
  active: brand.secondary,
};

const autocomplete = {
  color: colors.black,
  selected: {
    color: colors.white,
    background: brand.primary,
  },
};

const carousel = {
  color: colors.lightGray,
  active: brand.primary,
};

const form = {
  background: colors.white,
  title: {
    color: colors.black,
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  label: {
    color: colors.black,
    fontWeight: 'bold',
  },
  buttons: {
    close: {
      background: colors.transparent,
      fontSize: '1.875rem',
      color: colors.black,
    },
    background: brand.accent,
    border: brand.accent,
    color: brand.accent,
    secondaryColor: colors.white,
    disabled: {
      background: colors.neutral,
      border: colors.neutral,
      color: colors.midGray,
    },
    cancel: {
      background: colors.lightGray,
      color: colors.darkGray,
      hover: {
        background: colors.lightWhite,
      },
    },
    back: {
      color: colors.darkGray,
    },
  },
};

const noteCategories = {
  data_issue: colors.darkBlue,
  internal_events: colors.pink,
  external_events: colors.purple,
  other: colors.cyan,
};

const tooltip = {
  color: colors.black,
  background: colors.white,
  fontSize: '0.75rem',
  body: {
    color: colors.black,
    fontSize: '2.25rem',
  },
};

const table = {
  background: colors.white,
  border: colors.lightGray,
  borderStrong: colors.black,
  borderTransparent: colors.transparent,
  highlight: {
    green: '#86b907',
    red: '#E89BA1',
    blue: colors.vividBlue,
    gray: colors.lightGray,
    state: [
      '#8C5AC8',
      '#ffd048',
      '#E5546C',
      '#86b907',
      '#75C6E5',
      '#E77887',
      '#E9BFBB',
      '#E89BA1',
    ],
  },
  th: {
    color: {
      primary: colors.black,
      secondary: colors.midGray,
    },
  },
  legend: {
    color: colors.gray,
    fontSize: '0.875rem',
  },
};

const websocketStatus: { [key: string]: string } = {
  failed: colors.low,
  disconnected: colors.low,
  pending: colors.mid,
  connected: colors.high,
};

const switchToggle = {
  label: {
    fontSize: '0.875rem',
    color: colors.black,
  },
  thumb: colors.white,
  checked: brand.primary,
  unchecked: colors.lightGray,
};

const checkbox = {
  backgroundColor: {
    active: brand.secondary,
    inactive: colors.transparent,
    disabled: colors.darkWhite,
  },
};

const slider = {
  color: brand.secondary,
  rail: {
    active: brand.secondary,
    inactive: colors.darkWhite,
  },
};

const datePicker = {
  boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  backgroundColor: colors.white,
  monthButton: {
    borderRadius: '0',
    hover: {
      backgroundColor: colors.white,
      hoverBorderColor: colors.darkGray,
    },
    textColor: colors.darkGray,
    backgroundColor: colors.white,
    selectedColor: colors.differentBlue,
  },
  mainButton: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: colors.differentBlue,
  },
  yearTitle: {
    fontSize: '25px',
    color: colors.darkGray,
  },
  bottomBorderColor: colors.lightGray,
};

const input = {
  color: {
    active: colors.black,
    inactive: colors.darkWhite,
    accent: colors.white,
  },
  backgroundColor: {
    active: colors.white,
    inactive: colors.darkWhite,
    accent: brand.secondary,
  },
  borderColor: colors.midGray,
};

const viz = {
  default: colors.midGray,
  label: {
    color: colors.black,
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  navigation: {
    background: {
      inactive: colors.white,
      active: colors.neutral,
    },
    shadowColor: colors.shadow,
  },
  markers: {
    fontSize: '0.875rem',
    color: colors.black,
  },
  grid: {
    stroke: colors.darkWhite,
    strokeDasharray: '4',
  },
  rect: {
    fill: colors.white,
  },
  yAxis: {
    fontSize: '0.875rem',
    fontWeight: 'normal',
    stroke: colors.gray,
    color: colors.gray,
  },
  xAxis: {
    fontSize: '0.75rem',
    stroke: colors.gray,
    color: {
      primary: colors.black,
      secondary: colors.gray,
      accent: colors.activeRed,
      selected: colors.blue,
    },
    background: {
      highlight: colors.neutral,
    },
  },
  aggregateHome: {
    background: colors.neutral,
    title: {
      color: colors.black,
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    button: {
      color: colors.white,
      background: colors.neutral,
    },
    loader: {
      color: colors.lightGray,
    },
  },
  bar: {
    controls: {
      background: colors.white,
      shadow: colors.shadow,
    },
    colors: [colors.high, colors.low],
    value: {
      fontSize: '0.75rem',
      color: colors.black,
    },
  },
  line: {
    lineColor: colors.brightBlue,
    legendColors: [colors.gold, colors.brightBlue],
    legendFont: colors.legendGray,
    multiLineColors: [
      colors.softBlue,
      colors.babyBlue,
      colors.coral,
      colors.pink,
    ],
  },
  variance: {
    colors: {
      positive: ['#AAE6F0', '#E9B6D1'],
      negative: ['#3C96B4', '#933EA0'],
    },
  },
  headcount: {
    colors: [brand.primary, brand.secondary],
    line: colors.brightBlue,
  },
  evolution: {
    series: {
      a: {
        color: colors.white,
        backgroundColor: '#A0B134',
      },
      b: {
        color: colors.white,
        backgroundColor: '#5FDDEE',
      },
      c: {
        color: colors.white,
        backgroundColor: '#287BF6',
      },
    },
  },
  geoProjection: {
    default: colors.neutral,
    border: colors.white,
  },
  comparison: {
    ticker: {
      color: colors.white,
      fontSize: '1rem',
    },
    horizonA: brand.primary,
    horizonB: colors.black,

    background: colors.lightWhite,
    color: colors.white,
    title: {
      color: colors.gray,
    },
    footer: {
      color: brand.primary,
    },
    line: colors.purple,
    actual: brand.accent,
  },
  output: {
    colorScaleA: ['#3C96B4', '#AAE6F0', '#AAE6F0'],
    forecastColorA: '#3C96B4',
    patternColorA: '#3C96B4',
    colorScaleB: [
      '#FF96BC',
      '#E9B6D1',
      '#8C5AC8',
      '#C580D0',
      '#FAA082',
      '#E77887',
    ],
    patternColorB: '#8C5AC8',
    forecastColorB: '#C580D0',
    thresholdColors: [
      '#D04C62',
      '#E5546C',
      '#E77887',
      '#E89BA1',
      '#E9BFBB',
      '#EAE2D5',
      '#B0D4DD',
      '#75C6E5',
      '#00A9F4',
      '#0B84E0',
      '#134FA9',
    ],
  },
  gauge: {
    header: {
      needle: {
        color: colors.neutral,
        fill: brand.secondary,
        fillOpacity: 0,
      },
      arc: {
        overlay: brand.secondary,
        low: colors.low,
        mid: colors.mid,
        high: colors.high,
      },
      title: {
        color: colors.neutral,
        fontSize: '2rem',
        symbol: {
          fontSize: '1.25rem',
        },
      },
      ticker: {
        color: colors.highGray,
        fontSize: '0.875rem',
        fontWeight: 'bold',
      },
      label: {
        color: colors.lightGray,
        fontSize: '0.875rem',
        fontWeight: 'normal',
      },
      legend: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: colors.lightGray,
      },
    },
    base: {
      needle: {
        color: colors.black,
        fill: colors.transparent,
        fillOpacity: 0,
      },
      arc: {
        overlay: colors.darkWhite,
        low: colors.low,
        mid: colors.mid,
        high: colors.high,
      },
      title: {
        color: colors.black,
        fontSize: '2rem',
        symbol: {
          fontSize: '1.25rem',
        },
      },
      ticker: {
        color: colors.highGray,
        fontSize: '0.875rem',
        fontWeight: 'bold',
      },
      label: {
        color: colors.highGray,
        fontSize: '0.875rem',
        fontWeight: 'normal',
      },
      legend: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: colors.highGray,
      },
    },
  },
  accuracy: {
    thresholdColors: [
      '#B10D0D',
      '#D70F0F',
      '#EC1111',
      '#F6670C',
      '#FB9209',
      '#FFBD06',
      '#C7B107',
      '#a0cf05',
      '#86b907',
      '#739F08',
      '#1F8C09',
    ],
    grid: {
      colors: {
        legend: colors.midGray,
        label: colors.gray,
        category: colors.black,
      },
      border: colors.darkWhite,
      shadow: colors.shadow,
      background: colors.white,
    },
    heatmap: {
      header: {
        background: colors.white,
        color: colors.gray,
      },
      rowHeader: {
        background: colors.neutral,
        color: colors.darkGray,
        accentBackground: colors.lightGray,
      },
      cell: {
        color: colors.white,
      },
    },
  },
};

const tabView = {
  header: {
    backgroundColor: colors.neutral,
    dividerColor: colors.neutral,
    dividerBorderWidth: '1px',
    tab: {
      color: colors.black,
      activeColor: colors.blue,
      padding: '0 0 20px',
      borderWidth: 4,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    wrapper: {
      padding: '20px 0',
    },
  },
  content: {
    backgroundColor: colors.white,
    dividerColor: colors.neutral,
    dividerBorderWidth: '1px',
    tab: {
      color: colors.black,
      activeColor: colors.blue,
      padding: '0 0 20px',
      borderWidth: 4,
      fontWeight: 'normal',
      fontSize: '1rem',
    },
    wrapper: {
      padding: '20px 0',
    },
  },
};

const routeTabView = {
  backgroundColor: colors.neutral,
  tab: {
    color: colors.black,
    activeColor: colors.blue,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '1.25rem',
  },
};

const collapseTable = {
  header: {
    borderColor: colors.black,
  },
  sectionRow: {
    color: colors.neutral,
  },
};

const whatIf = {
  navigation: {
    tabView: {
      backgroundColor: colors.white,
      dividerColor: colors.neutral,
      dividerBorderWidth: '0',
      tab: {
        color: colors.darkGray,
        activeColor: colors.blue,
        padding: '32px 0 20px 0',
        borderWidth: 4,
        fontWeight: 'normal',
        fontSize: '1rem',
      },
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: colors.black,
  },
  subtitle: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: colors.black,
  },
  executiveSummary: {
    outputs: {
      fontSize: '12px',
      bigFontSize: '15px',
      boldFontWeight: 'bold',
      lightFontWeight: '300',
      circleSize: '12px',
    },
    loadModal: {
      modalBoxColor: colors.black,
      backgroundColor: colors.white,
      lineBreak: '1.5px solid #E6E6E6',
      title: {
        fontColor: colors.black,
        fontSize: '30px',
        fontWeight: 'bold',
      },
      orTypography: {
        color: colors.lightGray,
        fontSize: '15px',
      },
      searchTypography: {
        searchResultColor: colors.gray,
        searchNumberColor: colors.darkGray,
        searchFontSize: '13px',
      },
      loadScenarioButton: {
        color: colors.lightGray,
        fontSize: '12px',
      },
      modalButton: {
        color: colors.differentBlue,
        borderRadius: '0',
      },
      labelColor: colors.gray,
      textColor: colors.darkGray,
    },
  },
  optimizedHeadcount: {
    backgroundColor: colors.white,
    tabView: {
      backgroundColor: colors.white,
      dividerColor: colors.darkBlue,
      dividerBorderWidth: '1px',
      tab: {
        color: colors.darkGray,
        activeColor: colors.blue,
        padding: '25px 0',
        borderWidth: 4,
        fontWeight: 'normal',
        fontSize: '1rem',
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        height: 'min-content',
        padding: '20px 0',
      },
    },
    legend: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: colors.black,
      divider: {
        borderWidth: '1px',
        borderColor: colors.midGray,
      },
      legendItem: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: colors.darkGray,
        borderRadius: '4px',
        backgroundColor: colors.lightGray,
      },
    },
    output: {
      backgroundColor: brand.secondary,
      color: brand.accent,
      title: {
        fontSize: '2rem',
      },
      results: {
        fontSize: '1rem',
      },
      details: {
        fontSize: '1.5rem',
      },
    },
  },
  sliderSection: {
    slider: {
      color: brand.primary,
      childColor: colors.vividBlue,
      railColor: colors.darkWhite,
      textField: {
        fontSize: '0.9rem',
        borderRadius: '0px',
        lineHeight: '20px',
        color: colors.darkGray,
        borderColor: colors.midGray,
        backgroundColor: colors.white,
      },
      label: {
        color: colors.darkGray,
        fontSize: '1rem',
      },
      units: {
        fontSize: '0.8rem',
        width: '1.2rem',
      },
    },
    title: {
      color: colors.black,
      fontWeight: 'bold',
      fontSize: '1.5rem',
      backgroundColor: colors.lightGray,
    },
    subSection: {
      borderWidth: '1px',
      borderColor: colors.lightGray,
    },
    expander: {
      borderWidth: '1px',
      borderColor: colors.darkWhite,
    },
    expanderHeader: {
      color: brand.primary,
      fontSize: '0.875rem',
    },
    childSliderWrapper: {
      backgroundColor: colors.neutral,
    },
    subTitle: {
      color: colors.darkGray,
      fontSize: '0.8rem',
      fontWeight: 'normal',
    },
  },
};

const opPlanning = {
  scenario: {
    button: {
      backgroundColor: {
        normal: colors.darkWhite,
        active: brand.secondary,
      },
      colorActive: colors.darkWhite,
      colorsActiveId: colors.purple,
      fontSize: '0.875rem',
      fontSizeId: '1.3rem',
    },
  },
  scenarioComparisonModal: {
    close: {
      background: colors.transparent,
      fontSize: '2rem',
      color: colors.darkGray,
    },
    colorScale: ['#8C5AC8', '#3C96B4', '#E6A0C8'],
    intervalClockTarget: {
      xAxisBorderColor: '#4d4d4d',
      divider: colors.darkWhite,
      lineMarker: colors.darkWhite,
      clockMarker: '#2251FF4D',
      xAxis: {
        fontSize: '0.875rem',
        fontColor: '#4d4d4d',
        fontWeight: '400',
      },
      y0Axis: {
        fontSize: '0.875rem',
        fontWeight: '500',
        fontColor: colors.black,
      },
      y1Axis: {
        fontSize: '0.875rem',
        fontWeight: '400',
        fontColor: colors.darkGray,
      },
    },
    salesTargetMarker: '#007F26',
    titleFontSize: '1.25rem',
    titleFontWeight: '500',
    subtitleFontSize: '1rem',
    subtitleFontWeight: 'normal',
    subtitleClusterFontWeight: '500',
    subtitleBorderColor: colors.midGray,
    sidebar: {
      color: colors.gray,
      fontWeight: '500',
      activeColor: colors.black,
      hoverColor: colors.darkGray,
      fontSize: '0.875rem',
      border: colors.lightGray,
    },
  },
  levelBubbleChart: {
    clock: {
      borderColor: colors.lightGray,
      background: '#fbfbfb',
      color: colors.high,
      copperInstallStroke: '#8C5AC8',
      copperRepairStroke: '#C580D0',
      fiberInstallStroke: '#FF96BC',
      fiberRepairStroke: '#E9B6D1',
      loaderBackground: colors.darkGray,
      bucketColor: colors.darkGray,
      workColor: colors.midGray,
      workFontWeight: 'normal',
      bucketFontWeight: '500',
      unitFontWeight: 'normal',
      unitColor: colors.darkGray,
    },
    fte: {
      fontSize: '0.875rem',
      fontWeight: '500',
      strokeWidth: '1px',
      button: {
        fontSize: '12px',
        color: colors.darkGray,
        fontWeight: '500',
      },
      loanBackground: colors.neutral,
      fteBased: {
        backgroundColor: colors.vividBlue,
        strokeColor: colors.vividBlue,
        color: colors.neutral,
      },
      fteTotal: {
        backgroundColor: colors.teal,
        strokeColor: colors.teal,
        color: colors.neutral,
      },
      loanIn: {
        backgroundColor: colors.white,
        strokeColor: colors.teal,
        color: colors.teal,
      },
      loanOut: {
        backgroundColor: colors.lightGray,
        strokeColor: colors.white,
        color: colors.white,
      },
      legend: {
        fteBased: colors.vividBlue,
        fteLoanIn: {
          background: colors.transparent,
          border: colors.teal,
        },
        fteLoanOut: colors.lightGray,
        fteTotal: colors.teal,
      },
    },
  },
  dailyTotals: {
    dividerColor: colors.lightGray,
    dividerWidth: '1px',
    titleColor: colors.midGray,
    titleFontSize: '14px',
    titleFontWeight: '500',
    bubbleFontSize: '14px',
    bubbleFontWeight: '500',
    labelFontSize: '14px',
    labelFontWeight: '500',
    labelColor: colors.black,
    unitFontSize: '14px',
    unitFontWeight: '400',
    unitColor: colors.lightGray,
    fiber: {
      background: colors.teal,
      color: colors.neutral,
    },
    fiberOvertime: {
      background: colors.deepCarrotOrange,
      color: colors.neutral,
    },
    copper: {
      background: colors.cyan,
      color: colors.darkGray,
    },
    copperOvertime: {
      background: colors.deepCarrotOrange,
      color: colors.neutral,
    },
  },
  daySelection: {
    modal: {
      border: colors.darkWhite,
    },
    legend: {
      submitted: colors.black,
      modified: brand.accent,
    },
    slider: {
      submitted: colors.black,
      modified: brand.accent,
      default: dateSlider.rail,
    },
  },

  linkButton: {
    color: colors.black,
  },
  box: {
    border: colors.gray,
    boxBorder: colors.gray,
  },
  budgetChart: {
    colorScaleA: ['#3C96B4', '#AAE6F0'],
    colorScaleB: ['#C9633B', '#EDA287'],
    colorScaleC: ['#FF96BC', '#3C96B4'],
    colorScaleD: ['#E9B6D1', '#AAE6F0'],
  },
  chart: {
    colorScale: ['#8C5AC8', '#C580D0', '#FF96BC', '#E9B6D1'],
    colorScaleB: ['#8C5AC8', '#FF96BC'],
    target: '#007F26',
  },
  clocks: {
    arcColor: '#E6A0C8',
    arcStroke: colors.black,
    positiveColor: '#007F26',
    negativeColor: '#E5546C',
    subtitle: colors.midGray,
  },
  performance: {
    pie: {
      arc1: colors.purple,
      arc2: colors.pink,
      fill: '#061C2C',
      text: colors.white,
    },
    line: {
      line1: '#223A8C',
      line2: '#6DC6DB',
    },
    bubble: {
      fill: colors.white,
      color1: '#3D96B5',
      color2: colors.deepCarrotOrange,
      color3: colors.purple,
      color4: colors.pink,
      text1: colors.white,
      text2: colors.black,
    },
  },
};

const badge = {
  color: colors.white,
  background: brand.primary,
};

const noteBox = {
  closeIconColor: colors.gray,
  arrowColor: colors.gray,
  background: colors.lightBlue,
  topBorder: colors.gray,
  highlight: brand.accent,
  boxShadow: '0px 10px 25px -10px rgba(0,0,0,0.2)',
};

const capacityManagementModal = {
  cell: {
    borderColor: '#e0e0e0',
  },
  arrowCell: {
    backgroundColor: colors.white,
    borderTopColor: colors.white,
    borderBottomColor: colors.white,
  },
  contentCellTitle: {
    color: colors.gray,
  },
  emptyCell: {
    backgroundColor: colors.white,
    borderTopColor: colors.white,
    borderBottomColor: colors.white,
  },
  dateNumber: {
    color: colors.black,
    secondColor: '#f44336',
  },
  dateName: {
    color: colors.gray,
    secondColor: '#f44336',
  },
  textFieldCell: {
    backgroundColor: colors.white,
    borderColor: colors.blue,
    muiOutlinedClass: {
      borderColor: colors.blue,
      fieldset: {
        borderColor: '#90caf9',
      },
    },
  },
  textFieldInput: {
    borderColor: colors.blue,
  },
  row: {
    borderBottomColor: colors.black,
    backgroundColor: '#e0e0e0',
    color: colors.black,
    borderTopColor: '#e0e0e0',
    secondary: {
      backgroundColor: '#e1f5fe',
      borderBottomColor: '#e0e0e0',
    },
    third: {
      color: '#0097a7',
      backgroundColor: '#e3f2fd',
    },
    totals: {
      skillType: colors.lightWhite,
      all: colors.darkWhite,
    },
    scheduledTotals: {
      text: 'inherit',
      all: colors.lightGray,
    },
  },
  highlight: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  loanSelectedCell: {
    borderBottomColor: '#1565c0',
  },
  validate: {
    color: colors.activeRed,
  },
};

const capacityApiModal = {
  cell: {
    overbooked: colors.activeRed,
    changed: colors.gold,
    clear: colors.transparent,
  },
};

export const customTheme = {
  global: {
    fontFamily: 'PPObjectSans',
    backgroundColor: colors.white,
    divider: colors.neutral,
  },
  brand,
  text,
  ui: {
    navigation,
    header,
    tabs,
    text,
    button,
    dropdown,
    dateSlider,
    pillToggle,
    autocomplete,
    carousel,
    form,
    modifiedIndicator,
    noteCategories,
    tooltip,
    table,
    switchToggle,
    websocketStatus,
    tabView,
    routeTabView,
    slider,
    datePicker,
    badge,
    noteBox,
    capacityManagementModal,
    capacityApiModal,
    collapseTable,
    input,
    checkbox,
  },
  viz,
  whatIf,
  opPlanning,
};

export const theme = createTheme({
  ...customTheme,
  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: 'gray',
      },
    },
  },
});
