import { KeyboardArrowUp } from '@mui/icons-material';
import { Box, styled, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { Arc } from '@visx/shape';
import ChartLoader from 'components/chart-loader';
import { ThresholdColorDatum } from 'core/types/charts.type';
import numeral from 'numeral';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RotateWrapperProps = {
  top: number;
  left: number;
  angle: number;
  children?: ReactNode;
};

function RotateWrapper({ top, left, angle, children }: RotateWrapperProps) {
  return (
    <div
      style={{
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        transformOrigin: 'center bottom',
        transition: 'transform 0.5s ease',
        transform: `rotate(${angle}deg)`,
      }}
    >
      {children}
    </div>
  );
}

type TickerProps = {
  header?: boolean;
  children?: ReactNode;
  top: number;
  left: number;
};

function Ticker({ header, children, top, left }: TickerProps) {
  const Component = styled('p')(({ theme }) => ({
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    margin: 0,
    color: header
      ? theme.viz.gauge.header.ticker.color
      : theme.viz.gauge.base.ticker.color,
    fontSize: header
      ? theme.viz.gauge.header.ticker.fontSize
      : theme.viz.gauge.base.ticker.fontSize,
    fontWeight: header
      ? theme.viz.gauge.header.ticker.fontWeight
      : theme.viz.gauge.base.ticker.fontWeight,
  }));
  return <Component>{children}</Component>;
}

type TitleProps = {
  symbol: string;
  header?: boolean;
  children?: ReactNode;
};

function Title({ symbol, header, children }: TitleProps) {
  const Component = styled('p')(({ theme }) => ({
    margin: 0,
    color: header
      ? theme.viz.gauge.header.title.color
      : theme.viz.gauge.base.title.color,
    fontSize: header
      ? theme.viz.gauge.header.title.fontSize
      : theme.viz.gauge.base.title.fontSize,
    fontWeight: 'bold',
  }));
  const Symbol = styled('span')(({ theme }) => ({
    margin: 0,
    color: header
      ? theme.viz.gauge.header.title.color
      : theme.viz.gauge.base.title.color,
    fontSize: header
      ? theme.viz.gauge.header.title.symbol.fontSize
      : theme.viz.gauge.base.title.symbol.fontSize,
    fontWeight: 'bold',
  }));
  return (
    <Component>
      {children}
      <Symbol>{symbol}</Symbol>
    </Component>
  );
}

type LabelProps = {
  header?: boolean;
  children?: ReactNode;
};

function Label({ header, children }: LabelProps) {
  const Component = styled('p')(({ theme }) => ({
    margin: 0,
    wordWrap: 'break-word',
    color: header
      ? theme.viz.gauge.header.label.color
      : theme.viz.gauge.base.label.color,
    fontSize: header
      ? theme.viz.gauge.header.label.fontSize
      : theme.viz.gauge.base.label.fontSize,
    fontWeight: header
      ? theme.viz.gauge.header.label.fontWeight
      : theme.viz.gauge.base.label.fontWeight,
  }));

  return <Component>{children}</Component>;
}

export type GaugeChartProps = {
  width: number;
  value: number;
  min: number;
  max: number;
  label?: string;
  header?: boolean;
  loading?: boolean;
  margin?: { top: number; bottom: number; left: number; right: number };
  scale?: ThresholdColorDatum[];
};

export default function GaugeChart({
  width,
  value,
  min,
  max,
  label,
  header,
  loading,
  scale = [],
  margin = { top: 30, right: 10, bottom: 20, left: 10 },
}: GaugeChartProps) {
  const themeConfig = useTheme();
  const [displayValue, setDisplayValue] = useState(min);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setDisplayValue(value);
    }, 200);
  });

  if (width < 10) return null;

  const theme = header
    ? themeConfig.viz.gauge.header
    : themeConfig.viz.gauge.base;

  const height = width * 0.5;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2 + margin.right;

  const calculateAngle = (number: number) =>
    180 * ((number - min) / (max - min)) - 90;

  const INNER_MULTIPLIER = 0.2;
  const OUTER_MULTIPLIER = 0.27;
  const INNER_RADIUS = innerWidth * INNER_MULTIPLIER;
  const OUTER_RADIUS = innerWidth * OUTER_MULTIPLIER;
  const INNER_STROKE_WIDTH = INNER_RADIUS + 5;

  const NEEDLE_RADIUS = innerWidth * 0.02;
  const ARROW_SIZE = innerWidth * 0.1;
  const NEEDLE_STROKE = NEEDLE_RADIUS / 2;

  const BOTTOM_CHART = centerY + OUTER_RADIUS - INNER_RADIUS + margin.top / 2;

  if (!loading && !value) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && !value) {
    return <ChartLoader width={width} height={height} />;
  }

  const tickers = [90, 95].map((el) => (
    <RotateWrapper
      key={`ticker-${el}`}
      top={BOTTOM_CHART}
      left={centerX}
      angle={calculateAngle(el)}
    >
      <Ticker top={-OUTER_RADIUS - 16} left={-5} header={header}>
        {el}%
      </Ticker>
    </RotateWrapper>
  ));

  const angles =
    scale.length > 0
      ? scale
      : [
          { value: 90, color: theme.arc.low },
          { value: 95, color: theme.arc.mid },
          { value: 100, color: theme.arc.high },
        ];

  return (
    <div
      style={{
        position: 'relative',
        width: width - margin.right,
        height,
      }}
    >
      <svg width={innerWidth} height={innerHeight}>
        <Group top={BOTTOM_CHART} left={centerX}>
          {angles.map((el, i) => {
            return (
              <Group key={`arc-${el.value}`}>
                <Arc
                  startAngle={
                    (i === 0
                      ? -90
                      : calculateAngle(
                          i - 1 <= 0 ? angles[0].value : angles[i - 1].value
                        )) *
                    (Math.PI / 180)
                  }
                  endAngle={calculateAngle(el.value) * (Math.PI / 180)}
                  outerRadius={INNER_RADIUS}
                  innerRadius={INNER_STROKE_WIDTH}
                  padAngle={0}
                  fill={el.color}
                  opacity={1}
                />
                <Arc
                  startAngle={
                    (i === 0
                      ? -90
                      : calculateAngle(
                          i - 1 <= 0 ? angles[0].value : angles[i - 1].value
                        )) *
                    (Math.PI / 180)
                  }
                  endAngle={
                    calculateAngle(
                      el.value - (i === angles.length - 1 ? 0 : 0.1)
                    ) *
                    (Math.PI / 180)
                  }
                  outerRadius={INNER_STROKE_WIDTH}
                  innerRadius={OUTER_RADIUS}
                  padAngle={0}
                  fill={theme.arc.overlay}
                  opacity={1}
                />
              </Group>
            );
          })}
          <text
            textAnchor="start"
            x={OUTER_RADIUS + 2}
            y={0}
            fill={theme.ticker.color}
            fontSize={theme.ticker.fontSize}
            fontWeight={theme.ticker.fontWeight}
          >
            100%
          </text>
        </Group>
        <Group>
          <circle
            r={NEEDLE_RADIUS}
            strokeWidth={NEEDLE_STROKE}
            cy={BOTTOM_CHART}
            cx={centerX}
            stroke={theme.needle.color}
            fill={theme.needle.fill}
          />
        </Group>
      </svg>
      <RotateWrapper
        top={BOTTOM_CHART}
        left={centerX}
        angle={
          calculateAngle(displayValue) < -90
            ? -95
            : calculateAngle(displayValue)
        }
      >
        <div
          style={{
            position: 'absolute',
            width: `${ARROW_SIZE}px`,
            height: `${ARROW_SIZE}px`,
            border: '1px solid transparent',
            left: `${-ARROW_SIZE / 2 - NEEDLE_STROKE / 4}px`,
            top: `${-INNER_RADIUS - ARROW_SIZE * 0.4}px`,
          }}
        >
          <KeyboardArrowUp
            style={{ fontSize: `${ARROW_SIZE}px`, color: theme.needle.color }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            width: `${NEEDLE_STROKE}px`,
            height: `${INNER_RADIUS - NEEDLE_RADIUS}px`,
            backgroundColor: theme.needle.color,
            left: `${-NEEDLE_STROKE / 2}px`,
            borderRadius: 2,
            top: `${-INNER_RADIUS}px`,
          }}
        />
      </RotateWrapper>

      {tickers}
      <div
        style={{
          position: 'absolute',
          top: BOTTOM_CHART + NEEDLE_RADIUS,
          left: centerX / 2,
          textAlign: 'center',
          width: `${centerX}px`,
        }}
      >
        <Title header={header} symbol="%">
          {numeral(value).format('0,0.0')}
        </Title>
        <Label header={header}>{label}</Label>
      </div>
    </div>
  );
}
