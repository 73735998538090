import {
  CustomPageCreateResponse,
  CustomPagePayload,
  CustomPageResponse,
  customPageUpdatePayload,
  CustomPageUpdateResponse,
  PageSection,
  PageSectionPayload,
  SharedPagesPayload,
} from 'core/types/custom-page.type';
import { LevelOption } from 'core/types/level.type';
import ApiService from './api.service';

export default class CustomPageService {
  public static async getCustomPages(): Promise<CustomPageResponse> {
    try {
      const response = await ApiService.get<CustomPageResponse>('custom-pages');

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async createPage(
    page: CustomPagePayload
  ): Promise<CustomPageCreateResponse> {
    try {
      const response: CustomPageCreateResponse = await ApiService.post<
        CustomPagePayload,
        CustomPageCreateResponse
      >('custom-pages', page);
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }
  public static async addPageSection(
    pageId: number,
    level: LevelOption
  ): Promise<PageSection> {
    try {
      const response: PageSection = await ApiService.post<
        PageSectionPayload,
        PageSection
      >(`custom-pages/${pageId}/sections`, { level });
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async updatePages(
    page: customPageUpdatePayload
  ): Promise<CustomPageUpdateResponse> {
    try {
      const response: CustomPageUpdateResponse = await ApiService.put<
        customPageUpdatePayload,
        CustomPageUpdateResponse
      >('custom-pages', page);
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async updateSharedPages(
    pageId: number,
    addUsers: string[],
    removeUsers: string[]
  ): Promise<object> {
    try {
      const response: object = await ApiService.post<
        SharedPagesPayload,
        object
      >(`custom-pages/${pageId}/shared`, { addUsers, removeUsers });
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }
}
