import { scaleLinear } from '@visx/scale';
import { ScaleBand } from 'd3-scale';
import { NoteByDate } from 'core/types/note.type';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import { Box, styled, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import Badge from '@mui/material/Badge';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

export type NoteStackProps = {
  xScale: ScaleBand<string>;
  groupedNotes: NoteByDate;
  startHeight: number;
  endHeight: number;
  maxCount: number;
  onClick?: (selectedNoteDate: string) => void;
  displayNote?: boolean;
  selectedNoteDate?: string;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginTop: 10,
  padding: 0,
  '& .MuiBadge-badge': {
    color: theme.ui.badge.color,
    backgroundColor: theme.ui.badge.background,
  },
  cursor: 'pointer',
}));

export default function NoteStack({
  groupedNotes,
  startHeight,
  endHeight,
  xScale,
  maxCount,
  onClick,
  displayNote,
  selectedNoteDate,
}: NoteStackProps) {
  const yScale = scaleLinear({
    domain: [1, maxCount || 1.5],
    range: [startHeight, endHeight],
  });

  const theme = useTheme();

  return (
    <Group top={20}>
      {Object.keys(groupedNotes).map((key) => {
        const xValue = xScale(moment.utc(key).format(DateFormat.default));
        const formattedDate = moment(key).format(DateFormat.default);
        return xValue ? (
          <foreignObject
            key={`note-${key}`}
            x={xValue + xScale.bandwidth() / 2 - 25}
            y={yScale(1)}
            width={50}
            height={55}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <StyledBadge
                badgeContent={groupedNotes[formattedDate].count}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={() => (onClick ? onClick(formattedDate) : null)}
              >
                <TextSnippetOutlinedIcon color="action" />
              </StyledBadge>
              {displayNote && selectedNoteDate === formattedDate && (
                <Box
                  sx={{
                    width: '100%',
                    height: '7px',
                    backgroundColor: theme.ui.noteBox.highlight,
                    margin: '10px 0 0',
                  }}
                />
              )}
            </Box>
          </foreignObject>
        ) : null;
      })}
    </Group>
  );
}
