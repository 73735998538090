import { MouseEvent, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { RouteItem } from 'core/types/route-item.type';
import logo from 'theme/assets/logo-white.png';
import { styled, useTheme } from '@mui/material';
import AppContainer from './app-container';
import { useUser } from 'core/hooks/useUser';

export const NavLink = styled(Link)(({ theme }) => ({
  color: theme.ui.navigation.link.color.inactive,
  textDecoration: 'none',
  fontSize: theme.ui.navigation.link.fontSize,
  height: '50px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  padding: '0 4px 8px',
  fontWeight: theme.ui.navigation.link.fontWeight.inactive,
  borderBottom: `${theme.ui.navigation.link.border.width}px solid`,
}));

const Image = styled('img')({
  width: '95%',
  height: '95%',
});

export type NavBarProps = {
  routes: RouteItem[];
};

export default function NavBar({ routes }: NavBarProps) {
  const [user] = useUser();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const theme = useTheme();

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

  return (
    <AppContainer
      style={{
        backgroundColor: theme.ui.navigation.background,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}
    >
      <Toolbar disableGutters>
        {/* Mobile menu */}
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{
              padding: 0,
              margin: 0,
            }}
          >
            <MenuIcon
              sx={{
                color: theme.ui.navigation.menu.icon.color,
              }}
            />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiPaper-root': {
                backgroundColor: theme.ui.navigation.menu.background,
              },
            }}
          >
            {routes.map(({ to, end, title }) => (
              <MenuItem key={to} onClick={handleCloseNavMenu}>
                <NavLink
                  key={to}
                  to={to}
                  end={end}
                  style={({ isActive }) => ({
                    color: isActive
                      ? theme.ui.navigation.link.color.active
                      : theme.ui.navigation.link.color.inactive,
                    border: 'none',
                    borderLeft: `${
                      theme.ui.navigation.link.border.width
                    }px solid ${
                      isActive
                        ? theme.ui.navigation.link.border.color.active
                        : theme.ui.navigation.link.border.color.inactive
                    }`,
                    fontWeight: isActive
                      ? theme.ui.navigation.link.fontWeight.active
                      : theme.ui.navigation.link.fontWeight.inactive,
                  })}
                >
                  {title}
                </NavLink>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* Logo */}
        <Box
          sx={{
            display: 'flex',
            flexGrow: {
              xs: 1,
              md: 0,
            },
            transform: {
              xs: 'scale(0.85)',
              md: 'scale(1)',
            },
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          >
            <Link to="/">
              <Image src={logo} alt="logo" />
            </Link>
          </Box>
          <Box
            sx={{
              display: {
                xs: 'block',
                md: 'none',
              },
            }}
          >
            <Link to="/">
              <Image
                src={logo}
                alt="logo"
                sx={{
                  height: '50px',
                }}
              />
            </Link>
          </Box>
        </Box>
        {/* Main routes */}
        <Box
          sx={{
            flexGrow: 1,
            alignItems: 'end',
            justifyContent: 'space-evenly',
            display: { xs: 'none', md: 'flex' },
            height: '80px',
            padding: '0 25px 0 80px',
          }}
        >
          {routes.map(({ to, title, end }, i) => {
            return (
              <NavLink
                end={end}
                key={`nav-link-${i}`}
                to={to}
                style={({ isActive }) => ({
                  color: isActive
                    ? theme.ui.navigation.link.color.active
                    : theme.ui.navigation.link.color.inactive,
                  borderColor: isActive
                    ? theme.ui.navigation.link.border.color.active
                    : theme.ui.navigation.link.border.color.inactive,
                  fontWeight: isActive
                    ? theme.ui.navigation.link.fontWeight.active
                    : theme.ui.navigation.link.fontWeight.inactive,
                })}
              >
                {title}
              </NavLink>
            );
          })}
        </Box>
        {/* Profile map */}
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  width: '40px',
                  height: '40px',
                  bgcolor: theme.ui.navigation.avatar.background,
                  color: theme.ui.navigation.avatar.color,
                }}
              >
                {user?.firstName && user?.lastName
                  ? `${user.firstName[0]}${user.lastName[0]}`
                  : ''}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppContainer>
  );
}
