import NoteServiceF from 'core/services/notes.service';
import { Note } from 'core/types/note.type';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  FormControlLabel,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { useStore } from 'core/hooks/useStore';
import Dropdown from 'components/dropdown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LevelOption } from 'core/types/level.type';
import { useTranslation } from 'react-i18next';
import TableLoader from 'components/table-loader';
import {
  ALL_LEVEL_OPTION,
  ALL_NOTE_CATEGORY,
} from 'core/constants/options.constants';
import { Period } from 'core/enums/period.enum';
import { Model } from 'core/types/model.type';
import Snackbar from 'components/snackbar';
import { StyledSwitch } from 'components/switch';

const TableCellHeader = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: theme.ui.table.background,
  borderBottom: `1px solid ${theme.ui.table.border}`,
  align: 'center',
  fontWeight: 'bold',
  borderRight: `1px solid ${theme.ui.table.border}`,
  fontSize: '0.875rem',
}));

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  backgroundColor: theme.ui.table.background,
  align: 'center',
  borderBottom: `1px dashed ${theme.ui.table.border}`,
  borderRight: `1px solid ${theme.ui.table.border}`,
}));

const CircleColor = styled('span')({
  height: '15px',
  width: '15px',
  borderRadius: '50%',
  display: 'inline-block',
});

export type NoteIndexProps = {
  period: Period;
  onClick: (
    tab: number,
    date: string,
    level: LevelOption,
    trigger: number
  ) => void;
};

export default function NoteIndex({ period, onClick }: NoteIndexProps) {
  const { metadataF } = useStore();
  const { noteCategories, levels, models } = metadataF;
  const [model, _] = useState<Model>(models[0]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [noteCategory, setNoteCategory] = useState(ALL_NOTE_CATEGORY);
  const [isUser, setIsUser] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const format = DateFormat.s_MMDDYYYY;
  const [error, setError] = useState('');

  useEffect(() => {
    const getNotes = async () => {
      setLoading(true);
      try {
        const response = await NoteServiceF.getNotes(
          noteCategory.id,
          period,
          +model.id,
          isUser ? 1 : 0
        );
        setNotes(response);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    getNotes();
  }, [isUser, noteCategory]);

  const navigateToChartView = (note: Note) => {
    const selectedLevel = note.level2
      ? note.level2.id
      : note.level1
      ? note.level1.id
      : note.level0?.id || ALL_LEVEL_OPTION.id;

    const _level = [ALL_LEVEL_OPTION, ...levels].find(
      (level) => level.id != undefined && level.id == selectedLevel
    );

    if (_level) {
      onClick(1, note.targetDate, _level, Date.now());
    }
  };

  const renderedNotes = notes.filter((el) =>
    searchText.trim() === ''
      ? true
      : el.level0?.name.toLowerCase().includes(searchText) ||
        el.level1?.name.toLowerCase().includes(searchText) ||
        el.level2?.name.toLowerCase().includes(searchText) ||
        el.body.toLowerCase().includes(searchText)
  );

  const borderLeftStyle = {
    borderLeft: `1px solid ${theme.ui.table.border}`,
    borderLeftColor: theme.ui.table.borderStrong,
    borderRight: `1px solid ${theme.ui.table.border}`,
  } as React.CSSProperties;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'flex-start',
        }}
      >
        <Dropdown
          options={[ALL_NOTE_CATEGORY, ...noteCategories]}
          selectedItem={noteCategory}
          onChange={setNoteCategory}
          selector={(val) => val.id}
          keyName="noteCategory"
          style={{
            width: {
              xs: '100%',
              md: '200px',
            },
            margin: {
              xs: '20px 0 20px',
              md: '0 10px',
            },
            backgroundColor: theme.ui.dropdown.strong.background,
            color: theme.ui.dropdown.strong.color,
          }}
          formatLabel={(val) => val.name}
        />
        <TextField
          id="search-note"
          label={`${t('actions.search')} ${t('entities.note')}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value.toLowerCase())}
          autoComplete="off"
          variant="outlined"
          size="small"
          sx={{
            width: {
              xs: '100%',
              md: '300px',
            },
            margin: {
              xs: '0 0 20px ',
              md: '0 20px 0 20px',
            },
          }}
        />
        <FormControlLabel
          sx={{
            paddingLeft: {
              xs: 2,
              md: 2,
            },
          }}
          control={<StyledSwitch />}
          label={
            <Typography
              fontSize={theme.ui.switchToggle.label.fontSize}
              color={theme.ui.switchToggle.label.color}
            >
              {t`field.views.noteIndex.myMentions`}
            </Typography>
          }
          onChange={() => setIsUser(!isUser)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row' },
          justifyContent: 'flex-end',
          margin: '20px 0',
        }}
      >
        {noteCategories.map((category) => (
          <Box
            key={`noteCat-${category.id}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              margin: '0 10px',
            }}
          >
            <CircleColor
              style={{
                backgroundColor: (
                  theme.ui.noteCategories as {
                    [key: string]: string;
                  }
                )[category.id] as string,
              }}
            />
            <p
              style={{
                paddingLeft: 5,
                fontSize: theme.ui.table.legend.fontSize,
                color: theme.ui.table.legend.color,
              }}
            >
              {category.name}
            </p>
          </Box>
        ))}
      </Box>
      {loading ? (
        <TableLoader />
      ) : (
        <TableContainer>
          <Table
            aria-label={t`field.views.noteIndex.noteIndexTableLabel`}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCellHeader width={50}></TableCellHeader>
                <TableCellHeader
                  width={120}
                >{t`field.views.noteIndex.tableHeader.date`}</TableCellHeader>
                <TableCellHeader
                  style={borderLeftStyle}
                >{t`field.views.noteIndex.tableHeader.level0`}</TableCellHeader>
                <TableCellHeader>{t`field.views.noteIndex.tableHeader.level1`}</TableCellHeader>
                <TableCellHeader
                  width={95}
                >{t`field.views.noteIndex.tableHeader.level2`}</TableCellHeader>
                <TableCellHeader width={140} style={borderLeftStyle}>
                  {t`field.views.noteIndex.tableHeader.createdAt`}
                </TableCellHeader>
                <TableCellHeader
                  style={{
                    borderLeft: `1px solid ${theme.ui.table.border}`,
                    borderLeftColor: theme.ui.table.borderStrong,
                    borderRight: 0,
                  }}
                >{t`field.views.noteIndex.tableHeader.note`}</TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderedNotes.map((note, idx) => (
                <TableRow key={`${note.id}-${idx}`}>
                  <TableCell component="th" scope="row">
                    <CircleColor
                      style={{
                        backgroundColor: (
                          theme.ui.noteCategories as {
                            [key: string]: string;
                          }
                        )[note.categoryId] as string,
                      }}
                    ></CircleColor>
                  </TableCell>
                  <TableCell align="left">
                    {moment
                      .utc(note.targetDate, DateFormat.default)
                      .format(format)}
                  </TableCell>
                  <TableCell align="left" style={borderLeftStyle}>
                    <span
                      style={{
                        opacity: note.level0?.name ? 1 : 0.3,
                      }}
                    >
                      {note.level0?.name || t`field.views.noteIndex.value.all`}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span
                      style={{
                        opacity: note.level1?.name ? 1 : 0.3,
                      }}
                    >
                      {note.level1?.name || t`field.views.noteIndex.value.all`}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <span
                      style={{
                        opacity: note.level2?.name ? 1 : 0.3,
                      }}
                    >
                      {note.level2?.name || t`field.views.noteIndex.value.all`}
                    </span>
                  </TableCell>
                  <TableCell align="left" style={borderLeftStyle}>
                    {moment
                      .utc(note.createdAt, DateFormat.default)
                      .format(format)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      borderLeft: `1px solid ${theme.ui.table.border}`,
                      borderLeftColor: theme.ui.table.borderStrong,
                      borderRight: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: 450,
                      }}
                    >
                      <p style={{ margin: '0 20px 0 0' }}>{note.body}</p>
                      <IconButton onClick={() => navigateToChartView(note)}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!loading && !notes.length && (
            <Box
              sx={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2>{t`charts.noData`}</h2>
            </Box>
          )}
        </TableContainer>
      )}
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
