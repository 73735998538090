import { useTheme, Switch, styled } from '@mui/material';
export const StyledSwitch = styled(Switch)(() => {
  const theme = useTheme();

  return {
    width: 38,
    height: 19,
    padding: 0,
    marginRight: 5,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 25,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(19px)',
        color: theme.ui.switchToggle.thumb,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.ui.switchToggle.checked,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 14,
      height: 14,
      borderRadius: 8,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 19 / 2,
      opacity: 1,
      backgroundColor: theme.ui.switchToggle.unchecked,
      boxSizing: 'border-box',
    },
  };
});
