import { styled } from '@mui/material';

const Bar = styled('div')({
  '@keyframes chart-load': {
    '0%': {
      height: '50px',
    },
    '50%': {
      height: '400px',
    },
    '100%': {
      height: '50px',
    },
  },
  margin: '0 5px',
  width: '50px',
  height: '50px',
  animation: 'chart-load 0.75s infinite ease-in-out',
});

export type ChartLoaderProps = {
  width?: number;
  height?: number;
  backgroundColor?: string;
  scale?: number;
  gap?: number;
};

export default function ChartLoader({
  backgroundColor = '#EFEFEF',
  width = 100,
  height = 100,
  scale = 0.1,
  gap = -19,
}: ChartLoaderProps) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'rotate(90deg)',
        width,
        height,
      }}
    >
      <Bar
        style={{
          backgroundColor,
          transform: `scale(${scale})`,
          margin: `0 ${gap}px`,
        }}
      />
      <Bar
        style={{
          backgroundColor,
          animationDelay: '0.16s',
          transform: `scale(${scale})`,
          margin: `0 ${gap}px`,
        }}
      />
      <Bar
        style={{
          backgroundColor,
          animationDelay: '0.32s',
          transform: `scale(${scale})`,
          margin: `0 ${gap}px`,
        }}
      />
    </div>
  );
}
