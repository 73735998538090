import { Period } from 'core/enums/period.enum';
import { DateFormat } from 'core/enums/date-format.enum';
import moment from 'moment';
import { DateLimits, DateRange } from 'core/types/date.type';

export default class DateService {
  public static increaseDate(
    unit: 'day' | 'month' | 'year',
    number: number,
    date?: string
  ): string {
    const base = moment(this.safelyExtractDate(date));
    base.add(number, unit);

    return base.format(DateFormat.default);
  }

  public static decreaseDate(
    unit: 'day' | 'month' | 'year',
    number: number,
    date?: string
  ): string {
    const base = moment(this.safelyExtractDate(date));
    base.subtract(number, unit);

    return base.format(DateFormat.default);
  }

  public static generateDateRange(
    unit: 'day' | 'month' | 'year',
    subtract: number,
    add: number,
    date?: string
  ): DateRange {
    const base = moment(this.safelyExtractDate(date));
    base.subtract(subtract, unit);

    const startDate = base.format(DateFormat.default);
    base.add(add - 1, unit);

    const endDate = base.format(DateFormat.default);
    return { startDate, endDate };
  }

  public static generateDateSet(
    initialDate: string,
    unit: 'day' | 'month' | 'year',
    quantity: number
  ): string[] {
    const dateSet = [initialDate];

    for (let i = 1; i <= quantity; i++) {
      const date = moment(initialDate);
      date.add(i, unit);
      dateSet.push(date.format(DateFormat.default));
    }

    return dateSet;
  }

  public static safelyExtractDateLimits(
    minDate?: string,
    maxDate?: string
  ): DateLimits {
    if (!minDate && !maxDate) {
      return { minDate: '1900-01-01', maxDate: '3000-01-01' };
    }
    return {
      minDate: this.safelyExtractDate(minDate || '1900-01-01'),
      maxDate: this.safelyExtractDate(maxDate || '3000-01-01'),
    };
  }

  public static safelyExtractDate(date?: string, safeValue?: string): string {
    return !date || date === 'Invalid date' ? safeValue || '1900-01-01' : date;
  }

  public static getMomentUnit(period: Period) {
    switch (period) {
      case Period.monthly:
        return 'month';
      case Period.daily:
      default:
        return 'day';
    }
  }

  public static getFrequencyTranslation(period: Period) {
    switch (period) {
      case Period.monthly:
        return 'month';
      case Period.daily:
      default:
        return 'day';
    }
  }

  public static getDateFormat(
    period: Period,
    dailyFormat: string,
    monthlyFormat: string
  ) {
    switch (period) {
      case Period.daily:
        return dailyFormat;
      default:
        return monthlyFormat;
    }
  }

  public static getUnitsDisplayedCount(period: Period) {
    switch (period) {
      case Period.monthly:
        return 14;
      case Period.daily:
      default:
        return 21;
    }
  }
}
