import { FormControl, InputLabel, SxProps, Theme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactNode } from 'react';

export type DropdownProps<T> = {
  options: T[];
  selectedItem?: T;
  onChange: (value: T) => void;
  selector?: (value: T) => string | undefined;
  formatLabel?: (value: T) => string | undefined;
  formatStyle?: (value: T) => SxProps<Theme> | undefined;
  keyName?: string;
  backgroundColor?: string;
  color?: string;
  style?: SxProps<Theme> | undefined;
  labelId?: string;
  label?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  renderValue?: ((value: T) => ReactNode) | undefined;
};

export default function Dropdown<T>({
  selectedItem,
  options,
  onChange,
  selector = (value: unknown) => value as string | undefined,
  formatLabel = (value: unknown) => value as string | undefined,
  formatStyle = (_: unknown) => ({}),
  keyName = '',
  style = {},
  label = '',
  disabled = false,
  displayEmpty = false,
}: DropdownProps<T>) {
  const handleOnChange = (event: SelectChangeEvent<string>) => {
    const selected = options.find((el) => selector(el) === event.target.value);
    if (selected) {
      onChange(selected);
    }
  };

  const select = (
    <Select
      labelId={keyName}
      label={label}
      disabled={disabled}
      value={selectedItem ? selector(selectedItem) : ''}
      onChange={handleOnChange}
      displayEmpty={displayEmpty}
      size="small"
      notched={displayEmpty}
      sx={{
        padding: '0 20px 0 0',
        ...(label ? {} : style),
      }}
    >
      {options.map((el) => (
        <MenuItem
          sx={formatStyle(el)}
          key={`${keyName ? `${keyName}-` : ''}${selector(el)}`}
          value={selector(el)}
          disabled={(el as any)?.disabled}
        >
          {formatLabel(el)}
        </MenuItem>
      ))}
    </Select>
  );
  return (
    <>
      {label ? (
        <FormControl
          sx={{
            ...style,
          }}
        >
          <InputLabel id={keyName} shrink={displayEmpty}>
            {label}
          </InputLabel>
          {select}
        </FormControl>
      ) : (
        select
      )}
    </>
  );
}
