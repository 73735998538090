import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  Box,
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import DatePicker from './date-picker';
import { DateFormat } from 'core/enums/date-format.enum';
import { LoadingButton } from '@mui/lab';
import { NoteCategory, NotePayload } from 'core/types/note.type';
import { useStore } from 'core/hooks/useStore';
import Dropdown from './dropdown';
import { Model } from 'core/types/model.type';
import { useTranslation } from 'react-i18next';
import { ChartKey } from 'core/enums/chart-key.enum';
import { LevelOption } from 'core/types/level.type';
import NoteServiceF from 'core/services/notes.service';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { Close } from '@mui/icons-material';

export type NoteFormProps = {
  period: Period;
  isOpen: boolean;
  onClose: () => void;
  level: LevelOption;
  initialModel?: Model;
  initialDate: string;
  snackSuccess?: (isOpen: boolean) => void;
  snackFail?: (isOpen: boolean) => void;
};

export default function NoteForm({
  isOpen,
  onClose,
  period,
  initialDate,
  level,
  initialModel,
  snackSuccess = () => null,
  snackFail = () => null,
}: NoteFormProps) {
  const { metadataF } = useStore();
  const { models, noteCategories } = metadataF;

  const [targetDate, setTargetDate] = useState(initialDate);
  const [model, setModel] = useState<Model>(initialModel || models[0]);

  const [noteCategory, setNoteCategory] = useState<NoteCategory>(
    noteCategories[0] || { id: '', name: '' }
  );

  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const momentUnit = DateService.getMomentUnit(period);

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    15,
    targetDate
  );

  const handleClose = () => {
    setBody('');
    setNoteCategory(noteCategories[0]);
    setModel(models[0]);
    setTargetDate(initialDate);
    onClose();
  };

  const handleClick = async () => {
    const note: NotePayload = {
      body: body,
      frequency: period,
      chartKey: ChartKey.output,
      categoryId: noteCategory.id,
      level0Id:
        level?.level0 && level.level0?.id ? level.level0?.id : undefined,
      level1Id: level?.level1 && level.level1.id ? level.level1.id : undefined,
      level2Id: level?.level2 && level.level2.id ? level.level2.id : undefined,
      modelId: +model.id,
      targetDate,
    };

    setLoading(true);

    try {
      await NoteServiceF.saveNote(note);
      handleClose();
      snackSuccess(true);
    } catch (error) {
      snackFail(true);
    }

    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              onClick={onClose}
              style={{
                border: 'none',
                backgroundColor: theme.ui.form.buttons.close.background,
                display: 'block',
                margin: 0,
                padding: 0,
              }}
            >
              <Close
                sx={{
                  fontSize: theme.ui.form.buttons.close.fontSize,
                  color: theme.ui.form.buttons.close.color,
                }}
              />
            </button>
          </Box>
          <Box
            sx={{
              padding: {
                xs: '0',
                md: '5px 40px',
              },
            }}
          >
            <h2
              style={{
                fontSize: theme.ui.form.title.fontSize,
                fontWeight: theme.ui.form.title.fontWeight,
              }}
            >{t`noteForm.title`}</h2>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                justifyContent: 'space-between',
                margin: '30px 0',
              }}
            >
              <Dropdown
                label={t`noteForm.form.level`}
                options={[level]}
                disabled
                selectedItem={level}
                onChange={() => ''}
                selector={(val) => val.id}
                keyName="level"
                formatLabel={(val) => val.name}
                style={{
                  width: {
                    xs: '100%',
                    md: '30%',
                  },
                  margin: {
                    xs: '20px 0',
                    md: '0',
                  },
                }}
              />
              <DatePicker
                label={t`noteForm.form.date`}
                views={[momentUnit]}
                value={targetDate}
                setValue={setTargetDate}
                minDate={startDate}
                maxDate={endDate}
              />
              <Dropdown
                label={t`noteForm.form.model`}
                options={models}
                selectedItem={model}
                onChange={setModel}
                selector={(val) => val.id}
                keyName="model"
                style={{
                  width: {
                    xs: '100%',
                    md: '30%',
                  },
                  margin: {
                    xs: '20px 0',
                    md: '0',
                  },
                }}
                formatLabel={(val) =>
                  moment.utc(val.asOfDate).format(DateFormat.default)
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '40%',
                  },
                  marginBottom: {
                    xs: '20px',
                    md: '0',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontWeight: theme.ui.form.label.fontWeight,
                    color: theme.ui.form.label.color,
                  }}
                >
                  {t`noteForm.form.category`}*
                </InputLabel>
                <RadioGroup
                  aria-labelledby="Notes-Categories"
                  name="notes-categories-radio"
                  value={noteCategory.id}
                  onChange={(e) => {
                    const _noteCategory = noteCategories.find(
                      (el) => el.id === e.target.value
                    );
                    if (_noteCategory) {
                      setNoteCategory(_noteCategory);
                    }
                  }}
                >
                  {noteCategories.map((category) => {
                    return (
                      <FormControlLabel
                        key={`note-category-${category.id}`}
                        value={category.id}
                        control={
                          <Radio
                            style={{
                              color: (
                                theme.ui.noteCategories as {
                                  [key: string]: string;
                                }
                              )[category.id] as string,
                            }}
                          />
                        }
                        label={
                          <Typography
                            color={theme.ui.form.label.color}
                            fontSize={'0.875rem'}
                          >
                            {category.name}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    md: '60%',
                  },
                }}
              >
                <div>
                  <InputLabel htmlFor="body">
                    {t`noteForm.form.body`}
                  </InputLabel>
                  <TextField
                    id="body"
                    multiline
                    rows={5}
                    placeholder={t`noteForm.form.placeholder`}
                    style={{ width: '100%' }}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
        <DialogActions
          sx={{
            padding: {
              xs: '20px 0 40px',
              md: '5px 40px 20px',
            },
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              textTransform: 'none',
              width: {
                xs: '50%',
                md: '140px',
              },
              height: '42px',
              borderRadius: 0,
              color: theme.ui.form.buttons.color,
              borderColor: theme.ui.form.buttons.border,
            }}
          >
            {t`noteForm.button.cancel`}
          </Button>
          <LoadingButton
            variant="outlined"
            onClick={handleClick}
            loading={loading}
            disabled={!noteCategory || body.trim() === '' || !level || !model}
            type="submit"
            sx={{
              width: {
                xs: '50%',
                md: '140px',
              },
              height: '42px',
              textTransform: 'none',
              color: theme.ui.form.buttons.color,
              borderColor: theme.ui.form.buttons.border,
              '&.Mui-disabled': {
                backgroundColor: theme.ui.form.buttons.disabled.background,
                color: theme.ui.form.buttons.disabled.color,
                borderColor: theme.ui.form.buttons.disabled.border,
                border: 'none',
              },
              borderRadius: 0,
            }}
          >
            {!loading && t`noteForm.button.add`}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
