import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Grid, SxProps, Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface ThumbProps extends React.HTMLAttributes<unknown> {}

function Thumb(props: ThumbProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <NavigateBefore
          sx={{
            position: 'absolute',
            fontSize: '20px',
            color: 'white',
            top: 10,
            left: 5,
          }}
        />
        <NavigateNext
          sx={{
            position: 'absolute',
            fontSize: '20px',
            color: 'white',
            top: 10,
            right: 5,
          }}
        />
      </Box>
    </SliderThumb>
  );
}

export type DateSliderProps = {
  startDate: string;
  value: string;
  period: Period;
  quantity: number;
  hideQuantityLabel?: boolean;
  hideEndMargin?: boolean;
  onSelect?: (value: string) => void;
  style?: SxProps<Theme>;
};

export default function DateSlider({
  startDate,
  value,
  quantity,
  hideQuantityLabel,
  hideEndMargin,
  period,
  onSelect = () => '',
  style = {},
}: DateSliderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = DateService.getMomentUnit(period);
  const dates = DateService.generateDateSet(startDate, unit, quantity - 1);

  const tKey = `time.${DateService.getFrequencyTranslation(period)}${
    quantity > 1 ? 's' : ''
  }`;

  const dateFormat = DateService.getDateFormat(period, 'ddd MM/DD', 'MMM YY');

  const index = dates.findIndex((el) => el === value);

  return (
    <Box sx={{ marginBottom: '2rem', ...style }}>
      <Grid container spacing={2} alignItems="center">
        {!hideQuantityLabel ? (
          <Grid item sx={{ marginRight: 2 }}>
            {quantity} {t(tKey)}
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs>
          <Slider
            value={index + 1}
            defaultValue={1}
            onChange={(_, _value) => {
              onSelect(dates[(_value as number) - 1]);
            }}
            valueLabelFormat={(_value) =>
              dates[_value - 1]
                ? moment(dates[_value - 1]).format(dateFormat)
                : ''
            }
            valueLabelDisplay="on"
            step={1}
            marks
            min={1}
            max={quantity}
            components={{ Thumb }}
            sx={{
              '& .MuiSlider-thumb': {
                height: 40,
                width: 40,
                backgroundColor: theme.ui.dateSlider.thumb,
                border: 'none',
              },
              '& .MuiSlider-track': {
                display: 'none',
              },
              '& .MuiSlider-rail': {
                color: theme.ui.dateSlider.rail,
                height: 2,
                backgroundColor: theme.ui.dateSlider.rail,
              },
              '& .MuiSlider-mark': {
                width: 8,
                height: 8,
                color: theme.ui.dateSlider.rail,
                borderRadius: '50%',
              },
              '& .MuiSlider-markActive': {
                color: theme.ui.dateSlider.rail,
                backgroundColor: theme.ui.dateSlider.rail,
                width: 8,
                height: 8,
                borderRadius: '50%',
              },
              '& .MuiSlider-valueLabel': {
                backgroundColor: 'transparent',
                color: theme.ui.dateSlider.label.color,
                fontWeight: 'bold',
                fontSize: theme.ui.dateSlider.label.fontSize,
              },
            }}
          />
        </Grid>
        <Grid item sx={{ marginLeft: hideEndMargin ? 0 : 5 }} />
      </Grid>
    </Box>
  );
}
