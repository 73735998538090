import { styled, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import numeral from 'numeral';

const Wrapper = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 10,
});

const HeaderTitle = styled('p')(({ theme }) => ({
  alignSelf: 'start',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontSize: '1rem',
  color: theme.viz.comparison.title.color,
  margin: '0 0 12px',
}));

const ChartWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.viz.comparison.background,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const BracketWrapper = styled('div')({
  position: 'absolute',
  top: 10,
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 0,
});

const FieldSet = styled('fieldset')({
  width: '125px',
  height: '50px',
  borderBottom: 'none',
});

const Legend = styled('legend')({
  margin: '0 auto',
  fontWeight: 'bold',
});

const Footer = styled('div')(({ theme }) => ({
  color: theme.viz.comparison.footer.color,
  padding: '10px 0',
  fontSize: '0.875rem',
}));

export type ComparisonBarChartProps = {
  metric: string;
  valueA: number;
  valueB: number;
  change: number;
  footerLabel?: string;
  format?: string;
  onClick: () => void;
};

export default function ComparisonBarChart({
  metric,
  valueA,
  valueB,
  change,
  format,
  footerLabel,
  onClick,
}: ComparisonBarChartProps) {
  const theme = useTheme();
  const width = 275;
  const height = 105;
  const yMax = 100;

  const yScale = scaleLinear({
    domain: [Math.min(valueA, valueB), Math.max(valueA, valueB)],
    range: [35, 45],
  });

  const barAHeight = yScale(valueA);
  const barAy = yMax - barAHeight;

  const barBHeight = yScale(valueB);
  const barBy = yMax - barBHeight;

  const barDivider = 5;
  const barWidth = width / 2 - barDivider;
  const labelHeight = 18;

  return (
    <Wrapper onClick={() => onClick()}>
      <HeaderTitle>{metric}</HeaderTitle>
      <ChartWrapper>
        <svg width={width} height={height} style={{ zIndex: 1 }}>
          <Group>
            <Bar
              width={barWidth}
              fill={theme.viz.comparison.horizonA}
              height={barAHeight}
              x={0}
              y={barAy}
            />
            <foreignObject
              x={0}
              y={barAy + barAHeight / 2 - labelHeight / 2}
              width={barWidth}
              height={labelHeight}
            >
              <div
                style={{
                  color: theme.viz.comparison.ticker.color,
                  fontSize: theme.viz.comparison.ticker.fontSize,
                  textAlign: 'center',
                }}
              >
                {numeral(valueA).format(format || '0,0')}
              </div>
            </foreignObject>
          </Group>
          <Group>
            <Bar
              width={barWidth}
              fill={theme.viz.comparison.horizonB}
              height={barBHeight}
              x={barWidth + barDivider * 2}
              y={barBy}
            />
            <foreignObject
              x={barWidth + barDivider * 2}
              y={barBy + barBHeight / 2 - labelHeight / 2}
              width={barWidth}
              height={labelHeight}
            >
              <div
                style={{
                  color: theme.viz.comparison.ticker.color,
                  fontSize: theme.viz.comparison.ticker.fontSize,
                  textAlign: 'center',
                }}
              >
                {numeral(valueB).format(format || NumeralFormat.default)}
              </div>
            </foreignObject>
          </Group>
        </svg>
        <BracketWrapper>
          <FieldSet>
            <Legend
              style={{
                color: theme.viz.default,
              }}
            >{`${change > 0 ? `+` : ``}${numeral(change).format(
              NumeralFormat.percent
            )}`}</Legend>
          </FieldSet>
        </BracketWrapper>
        <Footer>{footerLabel}</Footer>
      </ChartWrapper>
    </Wrapper>
  );
}
