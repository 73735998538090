import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export const WEBSOCKET_STATUS_UPDATE = 'WEBSOCKET_STATUS_UPDATE';

export type WebsocketState = {
  status: 'failed' | 'disconnected' | 'pending' | 'connected';
};

const initialState: WebsocketState = {
  status: 'disconnected'
};

export const websocketStateSlice = createSlice<WebsocketState, SliceCaseReducers<WebsocketState>>({
  name: 'WebsocketState',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(WEBSOCKET_STATUS_UPDATE, (state: WebsocketState, action: any) => {
      if (state.status === 'failed' && action.payload === 'disconnected') { return; }

      state.status = action.payload;
    });
  },
} as any);


export const websocketStateReducer = websocketStateSlice.reducer;
