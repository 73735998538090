import TextField from '@mui/material/TextField';
import * as MuiAutocomplete from '@mui/material/Autocomplete';
import { HTMLAttributes, ReactNode, SyntheticEvent, useState } from 'react';
import { SxProps, Theme } from '@mui/material';

export type MultiAutocompleteProps<T> = {
  selectedValue: T[] | undefined;
  options: T[];
  limitTags?: number;
  label?: string;
  onChange: (value: T[] | null) => void;
  getOptionLabel: (option: T) => string;
  getOptionDisabled?: (option: T) => boolean;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    selected: boolean
  ) => ReactNode;
  style?: SxProps<Theme>;
  isOpened?: boolean;
  onClose?:
    | ((
        event: SyntheticEvent<Element, Event>,
        reason: MuiAutocomplete.AutocompleteCloseReason
      ) => void)
    | undefined;
};

export default function MultiAutocomplete<T>({
  options,
  selectedValue,
  onChange,
  getOptionLabel,
  renderOption,
  style,
  label = '',
  limitTags = 2,
  getOptionDisabled = () => false,
  isOpened = false,
  onClose = () => {},
}: MultiAutocompleteProps<T>) {
  const [value, setValue] = useState(selectedValue);

  return (
    <MuiAutocomplete.default
      placeholder="-"
      value={value}
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      size="small"
      onChange={(_, val) => {
        onChange(val);
        setValue(val);
      }}
      options={options}
      onClose={onClose}
      sx={{ ...style }}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) =>
        renderOption(props, option, selected)
      }
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
