export const mockPerformanceDispatches = [
  {
    state: 'CA',
    scheduled: 11594,
    expected: 4790,
    fte: 487,
    overtime: 890,
    fiber: 2540,
    cooper: 13844,
  },
  {
    state: 'FL',
    scheduled: 8790,
    expected: 21594,
    fte: 287,
    overtime: 190,
    fiber: 20954,
    cooper: 9430,
  },
];
