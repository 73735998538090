import { Box, styled, useTheme } from '@mui/material';
import { Note } from 'core/types/note.type';

export type NoteBoxProps = {
  note: Note;
};

const StyledBox = styled(Box)({
  borderLeft: 'solid 4px',
});

export default function NoteBox({ note }: NoteBoxProps) {
  const theme = useTheme();
  return (
    <StyledBox
      style={{
        borderLeftColor: (
          theme.ui.noteCategories as {
            [key: string]: string;
          }
        )[note.categoryId] as string,
        marginLeft: 10,
        marginBottom: 10,
      }}
    >
      <h5 style={{ paddingLeft: 10, paddingTop: 5, marginBottom: 10 }}>
        {note.userId}
      </h5>
      <p style={{ paddingLeft: 10, marginTop: 1, paddingBottom: 2 }}>
        {note.body}
      </p>
    </StyledBox>
  );
}
