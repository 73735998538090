import { useTranslation } from 'react-i18next';
import { Period } from 'core/enums/period.enum';
import AccuracyTrend from './accuracy-trend';
import ForecastAccuracyMap from './forecast-accuracy-map';
import ForecastHeatmap from './forecast-heatmap';
import ForecastVariance from './forecast-variance';
import PageSection from 'components/page-section';
import TabView from 'components/tab-view';

export type HistoricalForecastProps = {
  period: Period;
};

export default function HistoricalForecast({
  period,
}: HistoricalForecastProps) {
  const { t } = useTranslation();
  return (
    <PageSection title={t`field.views.historicalForecast.tab`}>
      <TabView
        tabItems={[
          {
            title: t`field.views.forecastAccuracyMap.tab`,
            component: <ForecastAccuracyMap period={period} />,
          },
          {
            title: t`field.views.forecastHorizonAccuracyHeatmap.tab`,
            component: <ForecastHeatmap period={period} />,
          },
          {
            title: t`field.views.forecastVariance.tab`,
            component: <ForecastVariance period={period} />,
          },
          {
            title: t`field.views.accuracyTrend.tab`,
            component: <AccuracyTrend period={period} />,
          },
        ]}
      />
    </PageSection>
  );
}
