import { Box, Divider, styled, useTheme } from '@mui/material';
import { stateIcons } from 'assets/icons/states';
import Dropdown from 'components/dropdown';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import { Model } from 'core/types/model.type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import DateSlider from 'components/date-slider';
import { ParentSize } from '@visx/responsive';
import GeoProjection from 'components/charts/geo-projection';
import { LevelOption } from 'core/types/level.type';
import { ALL_LEVEL_OPTION } from 'core/constants/options.constants';
import SVGIcon from 'components/svg-icon';
import GaugeChart from 'components/charts/gauge-chart';
import { MetricOption } from 'core/types/metric.type';
import MetricDropdown from 'modules/field/shared/metric-dropdown';
import DateService from 'core/services/date.service';
import OutputServiceF from 'core/services/output.service';
import { AccuracyMapOutput } from 'core/types/output.type';
import { unionBy } from 'lodash';
import AccuracyHorizontalBarChart from 'modules/field/shared/accuracy-horizontal-bar-chart';
import ChartLoader from 'components/chart-loader';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { scaleThreshold } from '@visx/scale';
import Snackbar from 'components/snackbar';

const ChartLabel = styled('p')({
  fontSize: '0.875rem',
  margin: '0',
});

const Circle = styled('div')({
  borderRadius: '50%',
  border: '2px solid',
  width: '55px',
  height: '55px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: 'bold',
});

const CircleLabel = styled('p')({
  fontSize: '0.875rem',
  margin: '0',
  fontWeight: 'bold',
  textAlign: 'center',
});

const CircleDescription = styled('p')({
  fontSize: '0.875rem',
  margin: '0',
  textAlign: 'center',
});

export type ForecastAccuracyMapProps = {
  period: Period;
};

export default function ForecastAccuracyMap({
  period,
}: ForecastAccuracyMapProps) {
  const { metadataF } = useStore();
  const [model, setModel] = useState<Model>(metadataF.models[0]);
  const [metric, setMetric] = useState<MetricOption>(
    metadataF.metricOptions.filter(
      (el) => el.categoryId === 'demand' || el.categoryId === 'capacity'
    )[0]
  );
  const [loading, setLoading] = useState(true);
  const [level, setLevel] = useState<LevelOption>(metadataF.levels[0]);
  const momentUnit = DateService.getMomentUnit(period);
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);
  const initialDate = DateService.increaseDate(
    momentUnit,
    1,
    model?.asOfDate || ''
  );
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [data, setData] = useState<AccuracyMapOutput[]>([]);
  const [error, setError] = useState('');

  const { t } = useTranslation();
  const theme = useTheme();

  const stateIcon = stateIcons.find((el) => {
    return el.name === level.name;
  });

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    unitsDisplayed,
    initialDate
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getAccuracyMap(
          period,
          startDate,
          endDate,
          level,
          metric,
          model
        );
        console.log(result);
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, model, metric]);

  const thresholdColorData = [
    { value: 0.8, color: theme.viz.accuracy.thresholdColors[0] },
    { value: 0.82, color: theme.viz.accuracy.thresholdColors[1] },
    { value: 0.84, color: theme.viz.accuracy.thresholdColors[2] },
    { value: 0.86, color: theme.viz.accuracy.thresholdColors[3] },
    { value: 0.88, color: theme.viz.accuracy.thresholdColors[4] },
    { value: 0.9, color: theme.viz.accuracy.thresholdColors[5] },
    { value: 0.92, color: theme.viz.accuracy.thresholdColors[6] },
    { value: 0.94, color: theme.viz.accuracy.thresholdColors[7] },
    { value: 0.96, color: theme.viz.accuracy.thresholdColors[8] },
    { value: 0.98, color: theme.viz.accuracy.thresholdColors[9] },
    { value: 1, color: theme.viz.accuracy.thresholdColors[10] },
  ];

  const colorScale = scaleThreshold<number, string>({
    domain: thresholdColorData.map((el) => el.value),
    range: thresholdColorData.map((el) => el.color),
  });

  const filteredData = data.filter(
    (el) =>
      moment.utc(el.date).format(DateFormat.default) ===
      moment.utc(selectedDate).format(DateFormat.default)
  );

  const aggregateDatum = filteredData
    .filter((el) => el.metricId === metric.metricId)
    .reduce(
      (obj, el) => {
        obj.actual += el.actual;
        obj.weightedAccuracy += el.weightedAccuracy;
        return obj;
      },
      {
        actual: 0,
        weightedAccuracy: 0,
        levelId: level.id,
        date: selectedDate,
        levelName: level.name,
        metricId: metric.metricId,
      }
    );

  const aggregateCapacity = filteredData
    .filter((el) => el.metricId === 'capacity')
    .reduce(
      (obj, el) => {
        obj.actual += el.actual;
        obj.weightedAccuracy += el.weightedAccuracy;
        return obj;
      },
      { actual: 0, weightedAccuracy: 0 }
    );

  const aggregateCapacityAccuracy = aggregateCapacity.actual
    ? aggregateCapacity.weightedAccuracy / aggregateCapacity.actual
    : 0;

  const aggregateDemand = filteredData
    .filter((el) => el.metricId === 'demand')
    .reduce(
      (obj, el) => {
        obj.actual += el.actual;
        obj.weightedAccuracy += el.weightedAccuracy;
        return obj;
      },
      { actual: 0, weightedAccuracy: 0 }
    );

  const aggregateDemandAccuracy = aggregateDemand.actual
    ? aggregateDemand.weightedAccuracy / aggregateDemand.actual
    : 0;

  const levelNames = unionBy<{ name: string; id: string }>(
    data.map((el) => ({ name: el.levelName, id: el.levelId })),
    (el) => el.id
  ).sort((a, b) => a.name.localeCompare(b.name));

  const mapData = filteredData.filter((el) => el.metricId === metric.metricId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '65%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              margin: {
                xs: '0 0 20px',
                md: '0',
              },
            }}
          >
            <MetricDropdown
              value={metric}
              filterJobs
              onChange={(value) => setMetric(value)}
              style={{
                margin: {
                  xs: '0 0 20px 0',
                  md: '0 20px 20px 0',
                },
                width: {
                  xs: '100%',
                  md: '250px',
                },
              }}
            />
            <Dropdown
              options={metadataF.models}
              selectedItem={model}
              onChange={(el) => {
                setModel(el);
                const _initialDate = DateService.increaseDate(
                  momentUnit,
                  1,
                  el.asOfDate
                );

                setSelectedDate(_initialDate);
              }}
              selector={(val) => val.id}
              keyName="model"
              style={{
                margin: {
                  xs: '20px 0',
                  md: '0',
                },
              }}
              formatLabel={(val) =>
                moment.utc(val.asOfDate).format(DateFormat.default)
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: {
              xs: 'auto',
              md: '700px',
            },
          }}
        >
          <ParentSize style={{ paddingTop: 10 }}>
            {({ width }) => (
              <GeoProjection
                width={width}
                loading={loading}
                unit={NumeralFormat.percentSm}
                thresholdColorData={thresholdColorData}
                data={
                  level.id === ALL_LEVEL_OPTION.id
                    ? mapData
                    : [
                        {
                          ...aggregateDatum,
                          accuracy: aggregateDatum.actual
                            ? aggregateDatum.weightedAccuracy /
                              aggregateDatum.actual
                            : 0,
                        },
                      ]
                }
                height={width * 0.75}
                colorScale={colorScale}
                dataFilter={(d, feature) => d.levelId === feature.properties.id}
                selector={(el) => el.accuracy}
                zoomSelector={(_feature) => _feature.properties.id === level.id}
                onClick={(value) => {
                  const _level = metadataF.levels.find((el) => el.id === value);
                  if (_level) {
                    setLevel(_level);
                  } else {
                    setLevel(ALL_LEVEL_OPTION);
                  }
                }}
              />
            )}
          </ParentSize>
          <Box>
            <DateSlider
              period={period}
              quantity={unitsDisplayed}
              value={selectedDate}
              startDate={initialDate}
              onSelect={(value) => {
                setSelectedDate(value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '30%',
          },
          paddingLeft: {
            xs: 0,
            md: '40px',
          },
          marginTop: {
            xs: '20px',
            md: '0',
          },
          borderLeft: {
            xs: '0px',
            md: `1px solid ${theme.global.divider}`,
          },
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          <h3
            style={{
              fontWeight: theme.ui.text.title.fontWeight,
              fontSize: '1.5rem',
              margin: '0 20px 0 0',
            }}
          >
            {stateIcon && stateIcon?.name !== ''
              ? stateIcon.name
              : t`field.views.mapView.states.usa`}
          </h3>
          {
            <SVGIcon
              Icon={stateIcon?.Icon}
              color={colorScale(
                aggregateDatum.actual
                  ? aggregateDatum.weightedAccuracy / aggregateDatum.actual
                  : 0
              )}
              height={40}
            />
          }
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <ParentSize>
            {({ width }) => (
              <GaugeChart
                width={width}
                min={thresholdColorData[0].value * 100}
                max={100}
                loading={loading}
                scale={thresholdColorData.map((el) => ({
                  ...el,
                  value: el.value * 100,
                }))}
                value={
                  aggregateDatum.actual
                    ? (aggregateDatum.weightedAccuracy /
                        aggregateDatum.actual) *
                      100
                    : 0
                }
                label={t('charts.forecastAccuracyLevel', { name: level.name })}
              />
            )}
          </ParentSize>
          <Divider sx={{ margin: '20px 0' }} />
          <Box
            sx={{
              height: {
                xs: '100%',
                md: '450px',
              },
              overflowY: 'scroll',
            }}
          >
            {loading ? (
              <ParentSize>
                {({ width }) => (
                  <ChartLoader
                    width={width}
                    height={200}
                    scale={0.5}
                    gap={-10}
                  />
                )}
              </ParentSize>
            ) : (
              levelNames.map((el) => {
                const _data = filteredData.filter((d) => d.levelId === el.id);

                const subLevelNames = unionBy<{ name?: string; id?: string }>(
                  _data.map((s) => ({
                    name: s.subLevelName,
                    id: s.subLevelId,
                  })),
                  (f) => f.id
                )
                  .filter((f) => f.name)
                  .sort((a, b) =>
                    a.name && b.name ? a.name.localeCompare(b.name) : 0
                  );

                const valueA = _data.find((f) => f.metricId === 'demand');

                return (
                  <Box key={`level-chart-${el.id}`} sx={{ margin: '10px 0' }}>
                    <ChartLabel
                      style={{
                        fontSize: level.id !== 'all' ? '1.25rem' : '0.875rem',
                        fontWeight: 'bold',
                      }}
                    >
                      {el.name || ''}
                    </ChartLabel>
                    <ParentSize>
                      {({ width }) => (
                        <AccuracyHorizontalBarChart
                          width={width}
                          height={50}
                          metricA="Demand"
                          colorScale={colorScale}
                          valueA={valueA?.accuracy || 0}
                          loading={loading}
                        />
                      )}
                    </ParentSize>
                    {subLevelNames.length > 0 && (
                      <Box
                        sx={{
                          margin: '20px 0',
                        }}
                      >
                        {subLevelNames.map((s) => {
                          const subLevelData = _data.filter(
                            (d) => s.id && d.subLevelId === s.id
                          );

                          const _valueA = subLevelData.find(
                            (f) => f.metricId === 'demand'
                          );

                          return (
                            <Box
                              key={`sub-level-chart-${s.id}`}
                              sx={{ margin: '20px 0' }}
                            >
                              <ChartLabel style={{ fontWeight: 'bold' }}>
                                {s.name || ''}
                              </ChartLabel>
                              <ParentSize>
                                {({ width }) => (
                                  <AccuracyHorizontalBarChart
                                    width={width}
                                    height={50}
                                    metricA="Demand"
                                    colorScale={colorScale}
                                    valueA={_valueA?.accuracy || 0}
                                    loading={loading}
                                  />
                                )}
                              </ParentSize>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
