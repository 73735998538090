import { Period } from 'core/enums/period.enum';
import { useEffect, useState } from 'react';
import { JobOption } from 'core/types/job.type';
import { LevelOption } from 'core/types/level.type';
import { useStore } from 'core/hooks/useStore';
import { Box } from '@mui/material';
import JobDropdown from 'modules/field/shared/job-dropdown';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import { HorizonAccuracy } from 'core/types/output.type';
import OutputServiceF from 'core/services/output.service';
import { uniq } from 'lodash';
import HorizonHeatmap from 'modules/field/shared/horizon-heatmap';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import Snackbar from 'components/snackbar';

export type ForecastHeatmapProps = {
  period: Period;
};

export default function ForecastHeatmap({ period }: ForecastHeatmapProps) {
  const { metadataF } = useStore();
  const [job, setJob] = useState<JobOption>(metadataF.jobs[0]);
  const [level, setLevel] = useState<LevelOption>(metadataF.levels[0]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<HorizonAccuracy[]>([]);
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const periodUnit = DateService.getFrequencyTranslation(period);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getHorizonAccuracies(
          period,
          level,
          job
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, job]);

  const columns = uniq(data.map((el) => `${el.horizon}`));
  const rowHeaders = uniq(
    data.map((el) =>
      el
        ? `${
            level.level1
              ? el.level2Name || ''
              : level.level0
              ? el.level1Name || ''
              : el.level0Name || ''
          }`
        : ''
    )
  );

  const tableHeight = (rowHeaders.length + 1) * 90;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <JobDropdown
          value={job}
          onChange={(value) => setJob(value)}
          style={{
            margin: {
              xs: '0 0 20px 0',
              md: '0 20px 20px 0',
            },
          }}
        />
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          levelFilter={(val) => val.level !== 2}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      <HorizonHeatmap
        period={period}
        level={level}
        loading={loading}
        columns={columns}
        rowHeaders={rowHeaders}
        data={data}
        height={tableHeight > 500 ? 500 : tableHeight}
        formatLabel={(el) =>
          `${el} ${t(`time.${periodUnit}${+el === 1 ? '' : 's'}`)}`
        }
      />
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
