import { useEffect, useState } from 'react';
import AuthService, { AuthTokens } from 'core/services/auth.service';

export const useAuth = (): [AuthTokens | undefined, boolean] => {
  const [authTokens, setAuthTokens] = useState<AuthTokens>();
  const [authAttempt, setAuthAttempt] = useState<boolean>(false);

  useEffect(() => {
    const getTokens = async () => {
      const token = await AuthService.getTokens();
      if (token.idToken !== '') {
        setAuthTokens(token);
      }
      setAuthAttempt(token.attempt);
    };
    getTokens();
  }, []);
  return [authTokens, authAttempt];
};
