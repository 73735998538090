import { Box, styled, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { scaleThreshold } from '@visx/scale';
import ChartLoader from 'components/chart-loader';
import Legend from 'components/legend';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { LegendType } from 'core/types/legend.type';
import { AggregateAccuracy } from 'core/types/output.type';
import { chain } from 'lodash';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')({
  margin: '50px 0',
});

const LegendWrapper = styled('div')({
  display: 'none',
  marginTop: '16px',
});

const KpiRow = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '20px',
});

const CategoryLabel = styled('p')(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.viz.accuracy.grid.colors.category,
  margin: '20px 0',
}));

const KpiBox = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.viz.accuracy.grid.border}`,
  backgroundColor: theme.viz.accuracy.grid.background,
  padding: '32px',
  transition: 'transform 0.5',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: `0px 1px 10px ${theme.viz.accuracy.grid.shadow}`,
    '& .kpi-legend': {
      display: 'flex',
    },
  },
}));

const KpiLabel = styled('p')(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.viz.accuracy.grid.colors.label,
  textTransform: 'uppercase',
  margin: 0,
}));

const KpiValue = styled('p')({
  margin: 0,
  fontWeight: 'bold',
  fontSize: '1.75rem',
});

export type AggregateAccuracyGridProps = {
  data?: AggregateAccuracy[];
  loading?: boolean;
};

export default function AggregateAccuracyGrid({
  data = [],
  loading,
}: AggregateAccuracyGridProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading) {
    return (
      <ParentSize>
        {({ width }) => <ChartLoader width={width} height={500} />}
      </ParentSize>
    );
  }

  const min = 0.85;
  const max = 0.9;

  const thresholdColorData = [
    { value: 80, color: theme.viz.accuracy.thresholdColors[0] },
    { value: 82, color: theme.viz.accuracy.thresholdColors[1] },
    { value: 84, color: theme.viz.accuracy.thresholdColors[2] },
    { value: 86, color: theme.viz.accuracy.thresholdColors[3] },
    { value: 88, color: theme.viz.accuracy.thresholdColors[4] },
    { value: 90, color: theme.viz.accuracy.thresholdColors[5] },
    { value: 92, color: theme.viz.accuracy.thresholdColors[6] },
    { value: 94, color: theme.viz.accuracy.thresholdColors[7] },
    { value: 96, color: theme.viz.accuracy.thresholdColors[8] },
    { value: 98, color: theme.viz.accuracy.thresholdColors[9] },
    { value: 100, color: theme.viz.accuracy.thresholdColors[10] },
  ];

  const colorScale = scaleThreshold<number, string>({
    domain: thresholdColorData.map((el) => el.value / 100),
    range: thresholdColorData.map((el) => el.color),
  });

  const values = chain(data)
    .groupBy('category')
    .map((v1, k1) => ({
      label: k1,
      data: v1.map((d) => ({
        id: `${k1}-${d.metricId}${d.job0Name ? `-${d.job0Name}` : ''}`,
        label: `${d.job0Name ? `${d.job0Name} ` : ''}${d.metricName}`,
        accuracy: d.accuracy,
      })),
    }))
    .value();

  return (
    <Wrapper>
      <Box>
        {values.map((el) => (
          <Box key={`category-${el.label}`}>
            <CategoryLabel>{el.label}</CategoryLabel>
            <KpiRow>
              {el.data.map((d, i, a) => (
                <KpiBox
                  key={`${d.id}`}
                  sx={{
                    width: {
                      xs: '100%',
                      md: '275px',
                    },
                    flexGrow: i === a.length - 1 && a.length % 2 !== 0 ? 2 : 1,
                  }}
                >
                  <KpiLabel>{d.label}</KpiLabel>
                  <KpiValue
                    sx={{
                      color: colorScale(d.accuracy),
                    }}
                  >
                    {numeral(d.accuracy).format(NumeralFormat.percent)}
                  </KpiValue>
                  <LegendWrapper className="kpi-legend">
                    <Legend
                      item={{
                        label: `<${numeral(min).format(
                          NumeralFormat.percentSm
                        )}`,
                        type: LegendType.circle,
                        color: colorScale(min),
                      }}
                      width={100}
                      legendStyle={{ margin: 0 }}
                      labelStyle={{
                        color: theme.viz.accuracy.grid.colors.legend,
                      }}
                    />
                    <Legend
                      item={{
                        label: `${numeral(min).format(
                          NumeralFormat.percentSm
                        )} - ${numeral(max).format(NumeralFormat.percentSm)}`,
                        type: LegendType.circle,
                        color: colorScale(0.9),
                      }}
                      width={130}
                      legendStyle={{ margin: 0 }}
                      labelStyle={{
                        color: theme.viz.accuracy.grid.colors.legend,
                      }}
                    />
                    <Legend
                      item={{
                        label: `>${numeral(max).format(
                          NumeralFormat.percentSm
                        )}`,
                        type: LegendType.circle,
                        color: colorScale(0.95),
                      }}
                      width={100}
                      legendStyle={{ margin: 0 }}
                      labelStyle={{
                        color: theme.viz.accuracy.grid.colors.legend,
                      }}
                    />
                  </LegendWrapper>
                </KpiBox>
              ))}
            </KpiRow>
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
}
