import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import numeral from 'numeral';
import {
  DemandOutputResponse,
  OutputMicroChartDatum,
} from 'core/types/output.type';
import { BarStackHorizontal } from '@visx/shape';
import { Box, styled, useTheme } from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { useTranslation } from 'react-i18next';
import OutputServiceF from 'core/services/output.service';
import { localPoint } from '@visx/event';
import { DataSideType, TooltipData } from 'core/types/tooltip-data-type';

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipHeader = styled('p')({
  margin: '10px 0',
  borderBottom: '1px solid gray',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  dataA: { legend: LegendItem; value?: number }[];
  dataB: { legend: LegendItem; value?: number }[];
};

export type OutputHorizontalMicroChartProps = {
  width: number;
  height: number;
  data: DemandOutputResponse[];
  colorRange?: string[];
  patternColor?: string;
  xMaxOverride?: number;
  setPattern?: (key: string) => number;
  loading?: boolean;
  metric: string;
};

export default function OutputHorizontalMicroChart({
  width,
  height,
  data,
  xMaxOverride,
  metric,
  colorRange = [],
  patternColor = '',
  setPattern = () => 0,
  loading = false,
}: OutputHorizontalMicroChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const margin = { top: 10, right: 0, bottom: 10, left: 0 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading) {
    return <ChartLoader width={width} height={height} scale={0.3} gap={-10} />;
  }

  const PADDING = 100;

  let tooltipTimeout: number;

  //       _       _
  //    __| | __ _| |_ __ _
  //   / _` |/ _` | __/ _` |
  //  | (_| | (_| | || (_| |
  //   \__,_|\__,_|\__\__,_|

  const dataA = data.find((el) => el.key === metric) || {
    key: '',
    value: [],
  };
  console.log('data', dataA);

  const keysA = [...(dataA && dataA.value[0] ? dataA.value[0].keys : [])].sort(
    (a, b) => OutputServiceF.getJobOrder(a) - OutputServiceF.getJobOrder(b)
  );

  // replace data here
  const forecastA = ((dataA && dataA.value) || []).map((el) => ({
    key: dataA.key,
    value: el.forecast['all'],
  }));

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/

  const yScale = scaleBand<string>({
    domain: data.map((el) => el.key),
    range: [innerHeight, margin.top],
  });

  // find max of data
  const xMax = Math.max(...forecastA.map((el) => el.value));

  const xScale = scaleLinear<number>({
    domain: [0, xMaxOverride || xMax],
    range: [margin.left, innerWidth - PADDING],
  });

  const colorScaleA = scaleOrdinal<string, string>({
    domain: (dataA && dataA.value[0] ? dataA.value[0].keys : []).sort((a, b) =>
      a.localeCompare(b)
    ),
    range: colorRange,
  });

  const BAR_SPACING = yScale.bandwidth() / 4;
  const BAR_HEIGHT = yScale.bandwidth();
  const LABEL_HEIGHT = yScale.bandwidth() + 2;
  const LABEL_WIDTH = 75;

  //   _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|

  const handleCloseTooltip = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  // TODO: can probably remove
  const getTooltipPattern = (dataSide: DataSideType, key: string) => {
    if (key.includes('Hybrid')) {
      return dataSide == 'A' ? patternColor : undefined;
    }

    return undefined;
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <svg width={innerWidth} height={height}>
          <Group top={margin.top}>
            <BarStackHorizontal<OutputMicroChartDatum, string>
              data={((dataA && dataA.value) || []).map((d) => ({
                key: dataA.key,
                ...d.forecast,
              }))}
              keys={keysA}
              y={(d) => d.key}
              xScale={xScale}
              yScale={yScale}
              color={colorScaleA}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <Group key={`bar-stack-A-${barStack.index}-${bar.index}`}>
                      <PatternLines
                        id={`bar-stack-A-${barStack.index}-${bar.index}`}
                        height={5}
                        width={5}
                        stroke={patternColor}
                        background={bar.color || theme.viz.default}
                        strokeWidth={setPattern(bar.key)}
                        orientation={['diagonal']}
                      />
                      <rect
                        x={bar.x}
                        y={bar.y + BAR_SPACING}
                        height={BAR_HEIGHT}
                        width={bar.width >= 0 ? bar.width : 0}
                        fill={`url('#${`bar-stack-A-${barStack.index}-${bar.index}`}')`}
                        onMouseLeave={handleCloseTooltip}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const eventSvgCoords = localPoint(event);
                          const left = xScale(0);
                          showTooltip({
                            tooltipData: {
                              ...bar,
                              dataSide: 'A',
                            },
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    </Group>
                  ))
                )
              }
            </BarStackHorizontal>
            {forecastA[0] && (
              <foreignObject
                y={(yScale(dataA.key) || 0) + LABEL_HEIGHT / 4}
                x={xScale(forecastA[0].value) + BAR_SPACING}
                width={LABEL_WIDTH}
                height={LABEL_HEIGHT}
              >
                <div
                  style={{
                    color: theme.viz.markers.color,
                    fontSize: theme.viz.markers.fontSize,
                    textAlign: 'left',
                    height: '90%',
                  }}
                >
                  {forecastA[0].value
                    ? numeral(forecastA[0].value).format(NumeralFormat.default)
                    : ''}
                </div>
              </foreignObject>
            )}
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 60,
              padding: 20,
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
              zIndex: 10000,
            }}
          >
            <TooltipWrapper>
              <TooltipTable>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TooltipHeader>{tooltipData.bar.data.key}</TooltipHeader>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Legend
                      item={{
                        label: tooltipData.key,
                        color: tooltipData.color,
                        type: LegendType.box,
                        patternColor: getTooltipPattern(
                          tooltipData.dataSide,
                          tooltipData.key
                        ),
                      }}
                    />
                    {numeral(
                      tooltipData.bar.data[
                        tooltipData.key as keyof typeof tooltipData.bar.data
                      ]
                    ).format(NumeralFormat.default)}
                  </Box>
                </Box>
              </TooltipTable>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
