import { configureStore } from '@reduxjs/toolkit';
import {
  metadataReducerF,
  customPageReducer,
  opPlanningReducerF,
  capacityPageReducer,
  websocketStateReducer,
  capacityManagementMenuReducer,
} from './field';

export const store = configureStore({
  reducer: {
    websocketState: websocketStateReducer,
    metadataF: metadataReducerF,
    opPlanning: opPlanningReducerF,
    customPage: customPageReducer,
    capacityPage: capacityPageReducer,
    capacityManagementMenu: capacityManagementMenuReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
