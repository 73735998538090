import { lazy, Suspense, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme as globalTheme } from 'theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FieldModule from 'modules/field/field.module';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: theme.global.backgroundColor,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  fontFamily: theme.global.fontFamily,
}));

const ForbiddenPage = lazy(() => import('modules/403/forbidden.page'));
function App() {
  useEffect(() => {
    if (window.location.host === process.env.REACT_APP_CLOUDFRONT_DOMAIN) {
      window.location.replace(process.env.REACT_APP_REDIRECT_URL || '');
    }
  }, []);

  if (window.location.host === process.env.REACT_APP_CLOUDFRONT_DOMAIN) {
    window.location.replace(process.env.REACT_APP_REDIRECT_URL || '');
    return null;
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <Suspense fallback={null}>
        <Wrapper>
          <Router>
            <Routes>
              <Route path="/*" element={<FieldModule />} />
              <Route path="403" element={<ForbiddenPage />} />
            </Routes>
          </Router>
        </Wrapper>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
