import { SxProps, Theme, useTheme } from '@mui/material';
import Dropdown from 'components/dropdown';
import { useStore } from 'core/hooks/useStore';
import { JobOption } from 'core/types/job.type';

export type JobDropdownProps = {
  value: JobOption;
  style?: SxProps<Theme>;
  onChange: (value: JobOption) => void;
};

export default function JobDropdown({
  value,
  style,
  onChange,
}: JobDropdownProps) {
  const { metadataF } = useStore();
  const theme = useTheme();

  const { jobs } = metadataF;

  return (
    <Dropdown
      keyName="job"
      selectedItem={value}
      onChange={onChange}
      options={jobs}
      selector={(el) => el.id}
      style={{
        ...style,
        backgroundColor: theme.ui.dropdown.strong.background,
        color: theme.ui.dropdown.strong.color,
        '& fieldset': {
          borderColor: 'transparent',
        },
      }}
      formatLabel={(el) => `${el.name}`}
      formatStyle={(el) => ({
        paddingLeft: el.indent ? 4 : 2,
      })}
    />
  );
}
