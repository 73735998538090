import {
  BudgetAvailabilityDaysOut,
  BudgetGraphOutput,
  LevelFteOutputData,
  ScenarioExpectedOvertimeOutput,
  ScenarioIntervalClockTargetOutput,
  ScenarioIntervalClockTargetResponse,
  ScenarioSalesTargetOutput,
  UpdateBudgetAvailabilitiesForBucketsPayload,
  UpdateBudgetAvailabilitiesForBucketsResponse,
  WorkAreaBucket,
} from './../types/operational-planning.type';
import { DayStatus } from 'core/enums/day-status.enum';
import { mockSalesChart } from 'core/mocks/salesChart';
import { mockPerformanceDispatches } from 'core/mocks/performanceDispatches';
import ApiService from 'core/services/api.service';
import {
  ServicesGraphOutput,
  SalesGraphOutputRow,
  ServicesGraphOutputRequest,
  Scenario,
  DailyTotalOutput,
  DailyTotalOutputRequest,
  LevelFteOutputRequest,
  LevelClockOutput,
  LevelClockOutputRequest,
  ClusterClockRequest,
  ClusterClockOutput,
  LevelClockOutputResponse,
  ScenarioPatch,
  GraphSummary,
  Status,
  StatusRequest,
  PatchStatusRequest,
  PerformanceDispatches,
  BudgetTransaction,
  BookingOrders,
  BudgetAvailability,
  RecommendedBudget,
} from 'core/types/operational-planning.type';
import { sum, union, uniq } from 'lodash';
import { scenarioSalesTargetOutput } from 'core/mocks/scenarioSalesTargetOutput';
import moment from 'moment';
import { BudgetCapacity } from 'core/types/budget.type';
import { Mode, ModeConfig, ModeResponse, ModeUpdate } from 'core/types/model.type';
import { BudgetAvailabilityProdV } from '../types/budget-availability-prodv.type';
import { ScheduledTechnician } from '../types/scheduled-technician.type';
import {
  WindowAvailabilityWithRatio,
  BudgetRatioPayload,
} from 'core/types/window.type';
export default class OperationalPlanningServiceF {
  public static async getServicesGraphOutput(
    clusterId: string,
    startDate: string,
    endDate: string,
    runTime: string,
    scenarioId: number
  ): Promise<ServicesGraphOutput[]> {
    const params: ServicesGraphOutputRequest = {
      startDate,
      endDate,
      runTime,
      scenarioId,
    };
    try {
      const response = await ApiService.get<ServicesGraphOutput[]>(
        `operational-planning/clusters/${clusterId}/bookings`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static getServiceChartSummary(
    data: ServicesGraphOutput[]
  ): GraphSummary {
    const summary: GraphSummary = {
      allKeys: [],
      maxY: 0,
    };

    data.forEach((el) => {
      summary.allKeys = union(summary.allKeys, el.keys);
      const barTotals = Math.max(
        ...el.values.map((bar) => {
          return sum(Object.values(bar.values));
        })
      );
      summary.maxY = barTotals > summary.maxY ? barTotals : summary.maxY;
    });

    return summary;
  }

  public static getBudgetGraphSummary(data: BudgetGraphOutput[]): GraphSummary {
    const summary: GraphSummary = {
      allKeys: [],
      maxY: 0,
    };

    data.forEach((el) => {
      summary.allKeys = union(summary.allKeys, el.keys);
      const barTotals = Math.max(
        ...el.values.map((bar) => {
          return sum(Object.values(bar.values));
        })
      );
      summary.maxY = barTotals > summary.maxY ? barTotals : summary.maxY;
    });

    return summary;
  }

  public static async getSalesGraphOutput(
    clusterId: string,
    startDate: string,
    endDate: string,
    runTime: string,
    scenarioId: number
  ): Promise<SalesGraphOutputRow[]> {
    const params: ServicesGraphOutputRequest = {
      startDate,
      endDate,
      runTime,
      scenarioId,
    };
    try {
      // to-do: add proper service when api is implemented
      // const response = await ApiService.get<SalesGraphOutputRow[]>(
      //   `operational-planning/clusters/${clusterId}/bookings`,
      //   params
      // );
      const response = mockSalesChart;
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getBudgetGraphOutput(
    districtId: string,
    startDate: string,
    endDate: string
  ): Promise<BudgetGraphOutput[]> {
    const params: { startDate: string; endDate: string } = {
      startDate,
      endDate,
    };
    try {
      const response = await ApiService.get<BudgetGraphOutput[]>(
        `operational-planning/districts/${districtId}/budget-availability`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getPerformanceDispatches(): Promise<
    PerformanceDispatches[]
  > {
    try {
      return mockPerformanceDispatches;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static getSalesChartSummary(
    data: SalesGraphOutputRow[]
  ): GraphSummary {
    const summary: GraphSummary = {
      allKeys: uniq(
        data.map((el) => {
          return `${el.job0.name} ${el.bucket.name}`;
        })
      ),
      maxY: data.reduce((prev, current) => {
        return current.projected > prev ? current.projected : prev;
      }, 0),
    };
    return summary;
  }

  public static async getScenarios(clusterId: string): Promise<Scenario[]> {
    const params = {
      clusterId: clusterId,
    };
    try {
      const response = await ApiService.get<Scenario[]>(
        `operational-planning/scenarios`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async patchScenarios(
    scenarioId: number,
    scenario: ScenarioPatch
  ) {
    // to-do - will add return type here when I got api working

    try {
      const response = await ApiService.patch(
        `operational-planning/scenarios/${scenarioId}`,
        scenario
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getDailyTotalOutput(
    clusterId: string,
    startDate: string,
    endDate: string,
    runTime: string,
    scenarioId: number
  ): Promise<DailyTotalOutput[]> {
    const params: DailyTotalOutputRequest = {
      startDate,
      endDate,
      runTime,
      scenarioId,
    };
    try {
      const response = await ApiService.get<DailyTotalOutput[]>(
        `operational-planning/output/clusters/${clusterId}/daily-totals`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getClusterClocksOutput(
    request: ClusterClockRequest
  ): Promise<ClusterClockOutput[]> {
    const { runTime, scenarioId, clusterId } = request;
    const params = { runTime, scenarioId };
    try {
      const response = await ApiService.get<ClusterClockOutput[]>(
        `operational-planning/clusters/${clusterId}/clocks`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getLevelFteOutput(
    clusterId: string,
    startDate: string,
    endDate: string,
    runTime: string,
    scenarioId: number
  ): Promise<LevelFteOutputData> {
    const params: LevelFteOutputRequest = {
      startDate,
      endDate,
      runTime,
      scenarioId,
    };

    try {
      const response = await ApiService.get<LevelFteOutputData>(
        `operational-planning/output/clusters/${clusterId}/aggregate`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getLevelClockOutput(
    clusterId: string,
    runTime: string,
    scenarioId: number
  ): Promise<LevelClockOutput[]> {
    const params: LevelClockOutputRequest = {
      runTime,
      scenarioId,
    };

    try {
      const response = await ApiService.get<LevelClockOutputResponse[]>(
        `operational-planning/clusters/${clusterId}/clocks/levels`,
        params
      );
      return OperationalPlanningServiceF.mapLevelClockToUi(response);
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getStatus(
    districtId: string,
    startDate: string,
    endDate: string
  ): Promise<Status[]> {
    const params: StatusRequest = {
      startDate,
      endDate,
    };

    try {
      const response = await ApiService.get<Status[]>(
        `operational-planning/clusters/${districtId}/output/status`,
        params
      );
      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async patchStatus(
    clusterId: string,
    scenarioId: number,
    runTime: string,
    dates: string[],
    status: DayStatus
  ): Promise<Status[]> {
    try {
      const payload: PatchStatusRequest = {
        dates,
        status,
        scenarioId,
        runTime,
      };

      const response = await ApiService.patch<PatchStatusRequest, Status[]>(
        `operational-planning/clusters/${clusterId}/output/status`,
        payload
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async getScenarioExpectedOvertimeOutput(
    clusterId: string,
    runTime: string,
    scenarioId: number,
    startDate: string
  ): Promise<ScenarioExpectedOvertimeOutput[]> {
    const endDate = moment.utc(startDate).add(21);
    const response = await ApiService.get<ScenarioExpectedOvertimeOutput[]>(
      `operational-planning/clusters/${clusterId}/output/overtime`,
      {
        runTime,
        scenarioId,
        startDate,
        endDate,
      }
    );

    return response;
  }

  public static async getScenarioSalesTargetOutput(): Promise<
    ScenarioSalesTargetOutput[]
  > {
    return scenarioSalesTargetOutput;
  }

  public static async getScenarioIntervalClockTargetOutput(
    clusterId: string,
    runTime: string,
    modelId: number
  ): Promise<ScenarioIntervalClockTargetOutput[]> {
    const response = await ApiService.get<
      ScenarioIntervalClockTargetResponse[]
    >(`operational-planning/clusters/${clusterId}/clocks/scenario-comparison`, {
      runTime,
      modelId,
    });

    return OperationalPlanningServiceF.mapIntervalTargetClockToUi(response);
  }

  public static async getBudgetTransactionsByDates(
    startDate: string,
    endDate: string
  ): Promise<BudgetTransaction[]> {
    const response = await ApiService.get<BudgetTransaction[]>(
      `operational-planning/budget-transactions`,
      { startDate, endDate }
    );

    return response;
  }

  public static async getBudgetTransactionsByFilter(
    orderDate: string,
    workArea: string,
    skillType: string,
    workType: string,
    bucketId: string,
  ): Promise<BudgetTransaction[]> {
    const response = await ApiService.get<BudgetTransaction[]>(
      `operational-planning/budget-transactions-by-filter`,
      {
        orderDate,
        workArea,
        skillType,
        workType,
        bucketId
      }
    );

    return response;
  }

  public static async getWindowAvailabilityWithRatio(
    orderDate: string,
    workArea: string
  ): Promise<WindowAvailabilityWithRatio[]> {
    const response = await ApiService.get<WindowAvailabilityWithRatio[]>(
      `operational-planning/window-ratio`,
      { orderDate, workArea }
    );

    return response;
  }

  public static async getBudgetAvailabilityByDates(
    startDate: string,
    endDate: string
  ): Promise<BudgetAvailability[]> {
    const response = await ApiService.get<BudgetAvailability[]>(
      `operational-planning/budget-availability`,
      { startDate, endDate }
    );

    return response;
  }

  public static async getWindowAvailabilityByDates(
    startDate: string,
    endDate: string
  ): Promise<WindowAvailabilityWithRatio[]> {
    const response = await ApiService.get<WindowAvailabilityWithRatio[]>(
      `operational-planning/window-availability-by-dates-between`,
      { startDate, endDate }
    );

    return response;
  }

  public static async getBudgetAvailabilityProdV(
    workArea: string
  ): Promise<BudgetAvailabilityProdV[]> {
    const response = await ApiService.get<BudgetAvailabilityProdV[]>(
      `operational-planning/work-area/${workArea}/prodv`
    );

    return response;
  }

  public static async getBudgetAvailabilityDaysOut(
    workArea: string
  ): Promise<BudgetAvailabilityDaysOut[]> {
    const response = await ApiService.get<BudgetAvailabilityDaysOut[]>(
      `operational-planning/work-area/${workArea}/days-out`
    );

    return response;
  }

  public static async getBookingOrdersByDates(
    startDate: string,
    endDate: string
  ): Promise<BookingOrders[]> {
    const response = await ApiService.get<BookingOrders[]>(
      `operational-planning/booking-orders`,
      { startDate, endDate }
    );

    return response;
  }

  public static async getBookingOrdersByFilter(
    orderDate: string,
    workArea: string,
    skillType?: string,
    workType?: string,
    bucketId?: string,
    skipCancelled?: boolean,
  ): Promise<BookingOrders[]> {
    const response = await ApiService.get<BookingOrders[]>(
      `operational-planning/booking-orders-by-filter`,
      {
        orderDate,
        workArea,
        skillType,
        workType,
        bucketId,
        skipCancelled,
      }
    );

    return response;
  }

  public static async getBucketsInformationByWorkArea(
    workArea: string
  ): Promise<WorkAreaBucket[]> {
    const response = await ApiService.get<WorkAreaBucket[]>(
      `operational-planning/work-area/${workArea}/buckets-information`,
      {}
    );

    return response;
  }

  public static async getBudgetAvailabilityByWorkArea(
    workArea: string,
    startDate: string,
    endDate: string
  ): Promise<BudgetCapacity[]> {
    const response = await ApiService.get<BudgetCapacity[]>(
      `operational-planning/work-area/${workArea}/budget-availability`,
      { startDate, endDate }
    );

    return response;
  }

  public static async getScheduledTechniciansByWorkArea(
    workArea: string,
    startDate: string,
    endDate: string,
    inLoad: string
  ): Promise<ScheduledTechnician[]> {
    const response = await ApiService.get<ScheduledTechnician[]>(
      `operational-planning/work-area/${workArea}/techs`,
      {
        startDate,
        endDate,
        inLoad,
      }
    );

    return response;
  }

  public static async updateBudgetAvailabilities(
    workArea: string,
    payload: BudgetCapacity[]
  ): Promise<void> {
    await ApiService.post<BudgetCapacity[], void>(
      `capacity-management/budget-availability/work-area/${workArea}/batch`,
      payload
    );
  }

  public static async updateBudgetAvailabilityOverrides(
    workArea: string,
    payload: BudgetCapacity[]
  ): Promise<void> {
    await ApiService.patch<BudgetCapacity[], void>(
      `operational-planning/work-area/${workArea}/budget-availability-override`,
      payload
    );
  }

  public static async updateBudgetAvailabilityForBuckets(
    workArea: string,
    payload: UpdateBudgetAvailabilitiesForBucketsPayload
  ): Promise<void> {
    await ApiService.patch<
      UpdateBudgetAvailabilitiesForBucketsPayload,
      UpdateBudgetAvailabilitiesForBucketsResponse
    >(
      `operational-planning/work-area/${workArea}/budget-availability-date-ranged`,
      payload
    );
  }

  public static async updateBudgetByWindowRatio(
    workArea: string,
    payload: BudgetRatioPayload
  ): Promise<void> {
    await ApiService.patch<BudgetRatioPayload, void>(
      `operational-planning/work-area/${workArea}/budget-window-ratio`,
      payload
    );
  }

  public static async getMode(id: string): Promise<ModeResponse> {
    const response = await ApiService.get<ModeResponse>(
      `operational-planning/model-mode`,
      { id }
    );

    return response;
  }

  public static async updateMode(
    id: string,
    mode: Mode,
    config?: ModeConfig[],
  ): Promise<void> {
    const payload: ModeUpdate = {
      id,
      mode,
      config,
    };
    await ApiService.patch<ModeUpdate, void>(
      `operational-planning/model-mode-update`,
      payload
    );
  }

  public static async getRecommendedByWorkArea(
    workArea: string,
    startDate: string,
    endDate: string
  ): Promise<RecommendedBudget[]> {
    const response = await ApiService.get<RecommendedBudget[]>(
      `operational-planning/work-area/${workArea}/recommended-budget`,
      { startDate, endDate }
    );

    return response;
  }

  private static mapIntervalTargetClockToUi(
    data: ScenarioIntervalClockTargetResponse[]
  ): ScenarioIntervalClockTargetOutput[] {
    const response: ScenarioIntervalClockTargetOutput[] = [];
    data.forEach((item) => {
      const alreadyExist = response.find(
        (job) => job.id === `${item.job0Id}${item.job1Id}`
      );
      if (alreadyExist) {
        return;
      }
      const buckets = data
        .filter(
          (job) => item.job0Id === job.job0Id && item.job1Id === job.job1Id
        )
        .map((bucket) => ({
          id: bucket.bucketId,
          name: bucket.bucket.name,
          scenarios: bucket.scenarios,
          clockFrom: bucket.clockFrom,
          clockTo: bucket.clockTo,
        }));
      response.push({
        id: `${item.job0Id}${item.job1Id}`,
        clusterId: item.clusterId,
        name: `${item.job0.name} ${item.job1.name}`,
        buckets,
      });
    });
    return response;
  }

  private static mapLevelClockToUi(
    data: LevelClockOutputResponse[]
  ): LevelClockOutput[] {
    const response: LevelClockOutput[] = [];

    data.forEach((item) => {
      const workGroupId = `${item.job0Id}-${item.job1Id}`;
      const workJobId = `${item.job0Id}-${item.job1Id}-${item.bucketId}`;
      const workGroupType = response.find(
        (workType) => workType.id === workGroupId
      );
      const workJobType = response.find(
        (workType) => workType.id === workJobId
      );
      if (!workGroupType) {
        response.push(
          {
            id: workGroupId,
            name: `${item.job0.name} ${item.job1.name}`,
            job0Id: item.job0Id,
            job1Id: item.job1Id,
            bucketId: item.bucketId,
            bucketName: item.bucket.name,
            clockFrom: item.clockFrom,
            clockTo: item.clockTo,
            data: [],
          },
          {
            id: workJobId,
            name: `${item.job0.name} ${item.job1.name}`,
            job0Id: item.job0Id,
            job1Id: item.job1Id,
            bucketId: item.bucketId,
            bucketName: item.bucket.name,
            clockFrom: item.clockFrom,
            clockTo: item.clockTo,
            data: [{ ...item }],
          }
        );
      }
      if (workGroupType && !workJobType) {
        response.push({
          id: workJobId,
          name: `${item.job0.name} ${item.job1.name}`,
          job0Id: item.job0Id,
          job1Id: item.job1Id,
          bucketId: item.bucketId,
          bucketName: item.bucket.name,
          clockFrom: item.clockFrom,
          clockTo: item.clockTo,
          data: [{ ...item }],
        });
      }

      if (workGroupType && workJobType) {
        workJobType.data.push({ ...item });
      }
    });
    const sortedResponse = response.sort((a, b) => (a.name >= b.name ? 1 : -1));

    return sortedResponse;
  }
}
