import {
  CapacityPagePayload,
  CapacityPageResponse,
  CapacityPageSection,
  CapacityPageSectionPayload,
  CapacityPageUpdateResponse,
  SharedCapacityPagesPayload,
  UpdateCapacityPagePayload,
} from 'core/types/capacity-page.type';
import { CapacityLevelOption } from 'core/types/level.type';
import ApiService from './api.service';

export default class CapacityPageService {
  public static async getPages(): Promise<CapacityPageResponse> {
    try {
      const response = await ApiService.get<CapacityPageResponse>(
        'capacity-pages'
      );

      return response;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  public static async createPage(
    page: CapacityPagePayload
  ): Promise<CapacityPageResponse> {
    try {
      const response: CapacityPageResponse = await ApiService.post<
        CapacityPagePayload,
        CapacityPageResponse
      >('capacity-pages', page);
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async addCapacityPageSection(
    pageId: number,
    level: CapacityLevelOption
  ): Promise<CapacityPageSection> {
    try {
      const response: CapacityPageSection = await ApiService.post<
        CapacityPageSectionPayload,
        CapacityPageSection
      >(`capacity-pages/${pageId}/sections`, { level });
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }
  public static async deleteCapacityPageSection(
    pageId: number,
    levelId: string
  ): Promise<void> {
    try {
      await ApiService.delete<void>(
        `capacity-pages/${pageId}/sections/${levelId}`
      );
      return;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async updatePages(
    page: UpdateCapacityPagePayload
  ): Promise<CapacityPageUpdateResponse> {
    try {
      const response: CapacityPageUpdateResponse = await ApiService.put<
        UpdateCapacityPagePayload,
        CapacityPageUpdateResponse
      >('capacity-pages', page);
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  public static async updateSharedPages(
    pageId: number,
    addUsers: string[],
    removeUsers: string[]
  ): Promise<object> {
    try {
      const response: object = await ApiService.post<
        SharedCapacityPagesPayload,
        object
      >(`capacity-pages/${pageId}/shared`, { addUsers, removeUsers });
      return response;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }
}
