import { ScenarioSalesTargetOutput } from './../types/operational-planning.type';
export const scenarioSalesTargetOutput: ScenarioSalesTargetOutput[] = [
  {
    jobId: 1,
    data: [
      {
        target: 380,
        period: 'I',
        sales: [
          { scenarioId: 1, orders: 320 },
          { scenarioId: 2, orders: 390 },
          { scenarioId: 3, orders: 290 },
        ],
      },
      {
        target: 430,
        period: 'II',
        sales: [
          { scenarioId: 1, orders: 460 },
          { scenarioId: 2, orders: 395 },
          { scenarioId: 3, orders: 360 },
        ],
      },
      {
        target: 380,
        period: 'III',
        sales: [
          { scenarioId: 1, orders: 375 },
          { scenarioId: 2, orders: 250 },
          { scenarioId: 3, orders: 390 },
        ],
      },
    ],
  },
  {
    jobId: 2,
    data: [
      {
        target: 240,
        period: 'I',
        sales: [
          { scenarioId: 1, orders: 50 },
          { scenarioId: 2, orders: 190 },
          { scenarioId: 3, orders: 290 },
        ],
      },
      {
        target: 220,
        period: 'II',
        sales: [
          { scenarioId: 1, orders: 300 },
          { scenarioId: 2, orders: 400 },
          { scenarioId: 3, orders: 600 },
        ],
      },
      {
        target: 320,
        period: 'III',
        sales: [
          { scenarioId: 1, orders: 250 },
          { scenarioId: 2, orders: 100 },
          { scenarioId: 3, orders: 150 },
        ],
      },
    ],
  },
];
