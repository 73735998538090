import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { ALL_LEVEL_OPTION } from 'core/constants/options.constants';
import { useStore } from 'core/hooks/useStore';
import CustomPageService from 'core/services/custom-page.service';
import { CustomPagePayload } from 'core/types/custom-page.type';
import { LevelOption } from 'core/types/level.type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SaveToPageDialogProps = {
  open: boolean;
  level: LevelOption;
  onClose: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
};

export default function SaveToPageDialog({
  open,
  level,
  onClose,
  onSuccess = () => null,
  onFail = () => null,
}: SaveToPageDialogProps) {
  const { customPage } = useStore();
  const [name, setName] = useState('');
  const [radio, setRadio] = useState<number>(-1);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation();

  const { pages } = customPage;

  const handleClose = () => {
    setName('');
    onClose();
  };

  const handleClick = async () => {
    setLoading(true);

    if (radio === -1) {
      const payload: CustomPagePayload = {
        name,
        levels: [level],
        order: (pages || []).length,
      };

      try {
        await CustomPageService.createPage(payload);
        handleClose();
        onSuccess();
      } catch (error) {
        onFail();
      }
    } else {
      const page = pages.find((el) => el.id === +radio);
      if (page) {
        try {
          await CustomPageService.addPageSection(page.id, level);
          handleClose();
          onSuccess();
        } catch (error) {
          onFail();
        }
      }
    }

    setLoading(false);
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={open} onClose={handleClose}>
      <DialogContent>
        <Box>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              onClick={handleClose}
              style={{
                border: 'none',
                backgroundColor: theme.ui.form.buttons.close.background,
                display: 'block',
                margin: 0,
                padding: 0,
              }}
            >
              <Close
                sx={{
                  fontSize: theme.ui.form.buttons.close.fontSize,
                  color: theme.ui.form.buttons.close.color,
                }}
              />
            </button>
          </Box>
          <Box
            sx={{
              padding: {
                xs: '0',
                md: '5px 40px',
              },
            }}
          >
            <h2
              style={{
                fontSize: theme.ui.form.title.fontSize,
                fontWeight: theme.ui.form.title.fontWeight,
              }}
            >
              {t(`saveToPageForm.title`, { level: level.name })}
            </h2>
            <Divider />
            <Box
              sx={{
                margin: '10px 0 0',
                overflowY: 'auto',
                maxHeight: '400px',
              }}
            >
              <FormControl>
                <FormLabel id="pages">{t`saveToPageForm.form.page`}</FormLabel>
                <RadioGroup
                  aria-labelledby="pages"
                  name="page-radios"
                  value={radio}
                  onChange={(e) => setRadio(+e.target.value)}
                >
                  {pages.map((el) => {
                    const _section = (el.pageSections || []).find(
                      (f) =>
                        level.id ===
                        (f.level2Id
                          ? f.level2Id
                          : f.level1Id
                          ? f.level1Id
                          : f.level0Id
                          ? f.level0Id
                          : ALL_LEVEL_OPTION.id)
                    );
                    return (
                      <FormControlLabel
                        key={`radio-${el.id}`}
                        value={el.id}
                        disabled={_section ? true : false}
                        control={<Radio />}
                        label={el.name}
                      />
                    );
                  })}
                  <FormControlLabel
                    value={-1}
                    control={<Radio />}
                    label={
                      <TextField
                        size="small"
                        value={name}
                        onFocusCapture={() => setRadio(-1)}
                        sx={{
                          width: '100%',
                          marginRight: {
                            xs: '0px',
                            md: '20px',
                          },
                        }}
                        id="custom-page-name"
                        label={t`saveToPageForm.form.name`}
                        onChange={(e) => setName(e.target.value)}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                justifyContent: 'space-between',
                margin: '30px 0',
              }}
            ></Box>
          </Box>
        </Box>
        <DialogActions
          sx={{
            padding: {
              xs: '20px 0 40px',
              md: '5px 40px 20px',
            },
          }}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              textTransform: 'none',
              width: {
                xs: '50%',
                md: '140px',
              },
              height: '42px',
              borderRadius: 0,
              color: theme.ui.form.buttons.color,
              borderColor: theme.ui.form.buttons.border,
            }}
          >
            {t`saveToPageForm.button.cancel`}
          </Button>
          <LoadingButton
            variant="outlined"
            onClick={handleClick}
            loading={loading}
            disabled={name.trim() === '' && radio === -1}
            type="submit"
            sx={{
              width: {
                xs: '50%',
                md: '140px',
              },
              height: '42px',
              textTransform: 'none',
              color: theme.ui.form.buttons.color,
              borderColor: theme.ui.form.buttons.border,
              '&.Mui-disabled': {
                backgroundColor: theme.ui.form.buttons.disabled.background,
                color: theme.ui.form.buttons.disabled.color,
                borderColor: theme.ui.form.buttons.disabled.border,
                border: 'none',
              },
              borderRadius: 0,
            }}
          >
            {!loading && t`saveToPageForm.button.add`}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
