import { lazy } from 'react';
import { Period } from 'core/enums/period.enum';
import { RouteItem } from 'core/types/route-item.type';
import i18n from 'i18n';
import ForecastPeriod from './forecast-output/components/forecast-period';
import SharedLink from 'components/shared-link';
import DateService from 'core/services/date.service';

const ForecastOutput = lazy(
  () => import('modules/field/forecast-output/forecast-output.page')
);
const OperationalPlanning = lazy(
  () => import('modules/field/operational-planning/operational-planning.page')
);

export enum FieldRoutes {
  main = '',
  forecast = 'forecast',
  capacityManagement = 'capacity',
  operationalPlanning = 'planning',
}

export const navRoutes: RouteItem[] = [
  {
    to: ``,
    path: '/',
    Component: OperationalPlanning,
    title: i18n.t`field.navigation.operationalPlanning`,
    end: true,
  },
  {
    to: `/${FieldRoutes.forecast}`,
    path: FieldRoutes.forecast,
    Component: ForecastOutput,
    title: i18n.t`field.navigation.forecastOutput`,
    end: true,
  },
];

export const pageRoutes: RouteItem[] = [
  {
    to: FieldRoutes.operationalPlanning,
    path: '/',
    Component: OperationalPlanning,
    title: i18n.t`field.navigation.operationalPlanning`,
  },
  {
    to: FieldRoutes.forecast,
    path: `${FieldRoutes.forecast}/*`,
    Component: ForecastOutput,
    title: i18n.t`field.navigation.forecastOutput`,
  },
  {
    to: FieldRoutes.operationalPlanning,
    path: `${FieldRoutes.operationalPlanning}/*`,
    Component: OperationalPlanning,
    title: i18n.t`field.navigation.operationalPlanning`,
  },
];

export enum FieldForecastRoutes {
  monthly = 'monthly',
  daily = 'daily',
}

export const forecastRoutes: RouteItem[] = [
  {
    to: FieldForecastRoutes.daily,
    path: FieldForecastRoutes.daily,
    Component: ForecastPeriod,
    props: {
      period: Period.daily,
    },
    title: i18n.t`field.frequency.daily.title`,
    subtitle: ` (${DateService.getUnitsDisplayedCount(
      Period.daily
    )} ${i18n.t`field.frequency.daily.subtitle`})`,
  },
  {
    to: FieldForecastRoutes.monthly,
    path: FieldForecastRoutes.monthly,
    Component: ForecastPeriod,
    props: {
      period: Period.monthly,
    },
    title: i18n.t`field.frequency.monthly.title`,
    subtitle: ` (${DateService.getUnitsDisplayedCount(
      Period.monthly
    )} ${i18n.t`field.frequency.monthly.subtitle`})`,
  },
];

export const sharedLinkRoute: RouteItem = {
  to: 'link',
  path: 'link',
  Component: SharedLink,
  title: 'Shared Link',
  props: {
    id: null,
    date: null,
  },
};
