import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { CognitoUserDetails, User } from 'core/types/user.type';
import jwtDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';

export type CognitoUSerData = {
  Username: string;
  Pool: CognitoUserPool;
};

export type AuthTokens = {
  accessToken: string;
  idToken: string;
  attempt: boolean;
};

export default class AuthService {
  public static async authenticateUser(
    cognitoUser: CognitoUser,
    credentials: AuthenticationDetails
  ): Promise<CognitoUserSession> {
    return new Promise(function (resolve, reject) {
      cognitoUser.authenticateUser(credentials, {
        onSuccess: resolve,
        onFailure: reject,
        newPasswordRequired: resolve,
      });
    });
  }

  public static decodeToken(token: string): User | undefined {
    try {
      const info: CognitoUserDetails = jwtDecode(token);
      if (info && info?.identities[0]) {
        return {
          id: info?.identities[0].userId,
          firstName: info?.['custom:firstName'],
          lastName: info?.['custom:lastName'],
          email: info?.email,
          role: info['cognito:groups'][0],
        };
      }
    } catch (error) {
      return undefined;
    }
  }

  public static async getUserInformation(): Promise<User | undefined> {
    const user = await Auth.currentSession();

    if (user) {
      return this.decodeToken(user.getIdToken().getJwtToken());
    }
    return undefined;
  }

  public static async getTokens(): Promise<AuthTokens> {
    let userAuthenticated = false;
    try {
      const user = await Auth.currentSession();
      userAuthenticated = true;
      return {
        accessToken: user.getAccessToken().getJwtToken(),
        idToken: user.getIdToken().getJwtToken(),
        attempt: true,
      };
    } catch (error) {
      userAuthenticated = false;
    }

    if (!userAuthenticated) {
      try {
        await Auth.federatedSignIn();
        userAuthenticated = true;
        return { accessToken: '', idToken: '', attempt: false };
      } catch (error) {
        return { accessToken: '', idToken: '', attempt: true };
      }
    }

    return { accessToken: '', idToken: '', attempt: true };
  }
}
