export type LegendItem = {
  label: string;
  type: LegendType;
  color: string;
  patternColor?: string;
};

export enum LegendType {
  box,
  line,
  circle,
}
