import { useTranslation } from 'react-i18next';
import ForecastOutputTab from '../output/forecast-output-tab';
import ForecastAccuracyTab from '../accuracy/forecast-accuracy-tab';
import ForecastChangeTab from '../change/forecast-change-tab';
import TabView from 'components/tab-view';
import { Period } from 'core/enums/period.enum';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LevelOption } from 'core/types/level.type';
import { useStore } from 'core/hooks/useStore';
import { ALL_LEVEL_OPTION } from 'core/constants/options.constants';
import { LocationState } from 'core/types/location-state-type';

export type ForecastPeriodProps = {
  period: Period;
};

export default function ForecastPeriod({ period }: ForecastPeriodProps) {
  const { t } = useTranslation();
  const { metadataF } = useStore();
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const [trigger, setTrigger] = useState(Date.now());
  const [initialDate, setInitialDate] = useState<string | undefined>();
  const [initialLevel, setInitialLevel] = useState<LevelOption | undefined>();

  useEffect(() => {
    if (location.state) {
      const locationState = location.state as LocationState;
      if (locationState.sharedLinkData) {
        setTab(+locationState.sharedLinkData.tab);
        setInitialDate(locationState.sharedLinkData.date);

        setInitialLevel(findLevelById(locationState.sharedLinkData.level));
        setTrigger(Date.now());
      }
    }
  }, [location.state]);

  const findLevelById = (levelId: string) => {
    return [ALL_LEVEL_OPTION, ...metadataF.levels].find(
      (_level) => _level.id != undefined && _level.id == levelId
    );
  };

  return (
    <TabView
      key={trigger}
      header
      initialIndex={tab}
      tabItems={[
        {
          title: t`field.views.forecastOutput.tab`,
          component: <ForecastOutputTab key={period} period={period} />,
        },
        {
          title: t`field.views.forecastAccuracy.tab`,
          component: <ForecastAccuracyTab key={period} period={period} />,
        },
        {
          title: t`field.views.forecastChange.tab`,
          component: (
            <ForecastChangeTab
              key={period}
              period={period}
              initialDate={initialDate}
              initialLevel={initialLevel}
            />
          ),
        },
      ]}
    />
  );
}
