import AuthService from './auth.service';

export type LambdaResponse<T> = {
  message: string;
  data: T;
};

export default class ApiService {
  private static getQueryParams(params: object): string {
    const keys = Object.keys(params) as Array<keyof typeof params>;
    return keys.reduce((str, el, i, a) => {
      let value: unknown = params[el];
      if (typeof value === 'string') {
        value = value?.replace(/\s/g, '%20');
      } else if (typeof value === 'boolean') {
        value = String(value)
      }
      return value === undefined
        ? str
        : `${str}${el}=${value}${i === a.length - 1 ? '' : '&'}`;
    }, '');
  }

  public static async get<T>(path: string, params?: object): Promise<T> {
    const tokens = await AuthService.getTokens();

    if (tokens.idToken === '') {
      throw new Error('Not authorized, session expired');
    }

    try {
      const raw = await fetch(
        `${process.env.REACT_APP_API_URL}/${path}${
          params ? `?${this.getQueryParams(params)}` : ''
        }`,
        {
          headers: {
            Authorization: tokens.idToken,
          },
        }
      );

      if (!raw.ok) {
        throw new Error('Request failed');
      }

      const response: LambdaResponse<T> = await raw.json();
      return response.data;
    } catch (err) {
      throw new Error('Request failed');
    }
  }

  public static async delete<T>(path: string): Promise<T> {
    const tokens = await AuthService.getTokens();

    if (tokens.idToken === '') {
      throw new Error('Not authorized, session expired');
    }

    try {
      const raw = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'DELETE',
        headers: {
          Authorization: tokens.idToken,
        },
      });

      if (!raw.ok) {
        throw new Error('Request failed');
      }

      const response: LambdaResponse<T> = await raw.json();
      return response.data;
    } catch (err) {
      throw new Error('Request failed');
    }
  }

  public static async post<T, R>(path: string, body: T): Promise<R> {
    const tokens = await AuthService.getTokens();

    if (!tokens) {
      throw new Error('Not authorized, session expired');
    }

    try {
      const raw = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'POST',
        headers: {
          Authorization: tokens.idToken,
        },
        body: JSON.stringify(body),
      });

      if (!raw.ok) {
        throw new Error('Request failed');
      }

      const response: LambdaResponse<R> = await raw.json();
      return response.data;
    } catch (err) {
      throw new Error('Request failed');
    }
  }

  public static async patch<T, R>(path: string, body: T): Promise<R> {
    const tokens = await AuthService.getTokens();

    if (!tokens) {
      throw new Error('Not authorized, session expired');
    }

    try {
      const raw = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'PATCH',
        headers: {
          Authorization: tokens.idToken,
        },
        body: JSON.stringify(body),
      });

      if (!raw.ok) {
        throw new Error('Request failed');
      }

      const response: LambdaResponse<R> = await raw.json();
      return response.data;
    } catch (err) {
      throw new Error('Request failed');
    }
  }

  public static async put<T, R>(path: string, body: T): Promise<R> {
    const tokens = await AuthService.getTokens();

    if (!tokens) {
      throw new Error('Not authorized, session expired');
    }

    try {
      const raw = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        method: 'PUT',
        headers: {
          Authorization: tokens.idToken,
        },
        body: JSON.stringify(body),
      });

      if (!raw.ok) {
        throw new Error('Request failed');
      }

      const response: LambdaResponse<R> = await raw.json();
      return response.data;
    } catch (err) {
      throw new Error('Request failed');
    }
  }
}
