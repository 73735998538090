import * as topojson from 'topojson-client';
import { geoCentroid } from 'd3-geo';
import { AlbersUsa } from '@visx/geo';
import topology from '../../assets/maps/us.json';
import { useEffect, useState } from 'react';
import { scaleThreshold } from '@visx/scale';
import { LegendThreshold } from '@visx/legend';
import { Group } from '@visx/group';
import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import { ThresholdColorDatum } from 'core/types/charts.type';
import { ZoomOut } from '@mui/icons-material';
import numeral from 'numeral';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';

const TooltipTitle = styled('p')({
  margin: '0 10px 16px',
  fontWeight: 'bold',
});

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

const TooltipBody = styled('p')(({ theme }) => ({
  margin: 0,
  color: theme.ui.tooltip.body.color,
  fontSize: theme.ui.tooltip.body.fontSize,
  fontWeight: 'bold',
}));

type FeatureShape = {
  type: 'Feature';
  id: string;
  geometry: { coordinates: [number, number][][]; type: 'Polygon' };
  properties: { name: string; id: string; level: number };
};

const { features: geoFeatures } = topojson.feature(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topology as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topology.objects.collection as any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any;

export type TooltipData<T> = {
  feature?: FeatureShape;
  data?: T;
};

export type GeoProjectionProps<T> = {
  data?: T[];
  width: number;
  height: number;
  thresholdColorData?: ThresholdColorDatum[];
  loading?: boolean;
  unit?: NumeralFormat;
  hideLegend?: boolean;
  selector?: (_data: T) => number;
  zoomSelector?: (_feature: FeatureShape) => boolean;
  dataFilter?: (_data: T, _feature: FeatureShape) => boolean;
  colorScale?: (value: number) => string;
  onClick?: (value: string) => void;
};

export default function GeoProjection<T>({
  width,
  height,
  data = [],
  thresholdColorData = [],
  loading,
  unit = NumeralFormat.default,
  hideLegend = false,
  onClick = () => null,
  selector = (val: unknown) => val as number,
  zoomSelector = () => true,
  dataFilter = () => true,
  colorScale = () => '',
}: GeoProjectionProps<T>) {
  const theme = useTheme();
  const centerX = width / 2;
  const centerY = height / 2;
  const initialScale = (width + height) * 0.75;
  const [scale, setScale] = useState(1.5);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);

  const thresholdScale = scaleThreshold<number, string>({
    domain: thresholdColorData.map((el) => el.value),
    range: thresholdColorData.map((el) => el.color),
  });

  useEffect(() => {
    setScale(initialScale);
  }, [width]);

  const handleZoomOut = () => {
    setTranslateY(0);
    setTranslateX(0);
    setScale(initialScale);
    setIsZoomed(false);
    onClick('');
  };

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipData<T>>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: {},
  });

  const handleLineTooltip = (
    _feature: FeatureShape,
    _data?: T,
    _centroid?: number[]
  ) => {
    if (_centroid && !isZoomed) {
      showTooltip({
        tooltipLeft: _centroid[0],
        tooltipTop: _centroid[1],
        tooltipData: { feature: _feature, data: _data },
      });
    }
  };

  const handleCloseTooltip = () => {
    setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  return width < 10 ? null : (
    <div
      style={{
        marginTop: 20,
        marginRight: 30,
        position: 'relative',
      }}
    >
      <div style={{ height: '2px', margin: '10px 0' }}>
        {loading && <LinearProgress />}
      </div>

      {!hideLegend && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <LegendThreshold scale={thresholdScale}>
              {(labels) => (
                <Box
                  style={{
                    display: 'flex',
                  }}
                >
                  {labels.map((label, i, a) => {
                    const midIndex = Math.floor((a.length - 1) / 2);
                    return (
                      <Box
                        key={`legend-threshold-${i}`}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          style={{
                            width: 27,
                            height: 15,
                            backgroundColor: label.value,
                          }}
                        />
                        <Box
                          sx={{
                            fontSize: '12px',
                            textAlign: 'center',
                          }}
                        >
                          {i === 0
                            ? numeral(thresholdColorData[0].value).format(unit)
                            : i === a.length - 1
                            ? numeral(
                                thresholdColorData[a.length - 1].value
                              ).format(unit)
                            : i === midIndex
                            ? '|'
                            : ''}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </LegendThreshold>
          </div>
        </div>
      )}

      <div
        style={{
          position: 'relative',
        }}
      >
        {isZoomed && (
          <button
            style={{
              position: 'absolute',
              top: 40,
              right: 0,
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={() => handleZoomOut()}
          >
            <ZoomOut style={{ fontSize: '45px' }} />
          </button>
        )}

        <svg width={width} height={height}>
          <AlbersUsa<FeatureShape>
            data={geoFeatures}
            translate={[centerX + translateX, centerY + translateY]}
            scale={scale}
          >
            {({ features }) =>
              features.map(({ feature, path, projection }) => {
                const _data = data.find((d) => dataFilter(d, feature));
                const value = _data ? selector(_data) : null;

                return (
                  <Group key={`map-feature-${feature.properties.id}`}>
                    <path
                      d={path || ''}
                      fill={
                        value
                          ? loading
                            ? theme.viz.geoProjection.default
                            : colorScale(value)
                          : theme.viz.geoProjection.default
                      }
                      stroke={theme.viz.geoProjection.border}
                      opacity={isZoomed ? (zoomSelector(feature) ? 1 : 0.5) : 1}
                      strokeWidth={1}
                      onMouseLeave={handleCloseTooltip}
                      onMouseMove={() => {
                        const centroid = projection(geoCentroid(feature));
                        if (centroid) {
                          handleLineTooltip(feature, _data, centroid);
                        }
                      }}
                      onClick={() => {
                        if (isZoomed) {
                          handleZoomOut();
                        } else {
                          const centroid = projection(geoCentroid(feature));
                          if (centroid) {
                            const _scale = Math.min(
                              feature.properties.name === 'Texas' ? 3 : 5,
                              0.9 /
                                Math.abs(
                                  Math.max(
                                    (centerX - centroid[0]) / width,
                                    (centerY - centroid[1]) / height
                                  )
                                )
                            );

                            setScale(_scale * initialScale);
                            setTranslateY((centerY - centroid[1]) * _scale);
                            setTranslateX((centerX - centroid[0]) * _scale);
                          }
                          onClick(feature.properties.id);
                        }
                        setIsZoomed(!isZoomed);
                      }}
                    />
                  </Group>
                );
              })
            }
          </AlbersUsa>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 60,
              padding: '10px 10px 20px',
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
            }}
          >
            <TooltipWrapper>
              <TooltipTitle>
                {tooltipData.feature ? tooltipData.feature.properties.name : ''}
              </TooltipTitle>
              <TooltipBody
                sx={{
                  color: tooltipData.data
                    ? colorScale(selector(tooltipData.data))
                    : 'transparent',
                }}
              >
                {tooltipData.data
                  ? numeral(selector(tooltipData.data)).format(unit)
                  : ''}
              </TooltipBody>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
