import { Box, useTheme } from '@mui/material';
import Dropdown from 'components/dropdown';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import DateService from 'core/services/date.service';
import OutputServiceF from 'core/services/output.service';
import { JobOption } from 'core/types/job.type';
import { LevelOption } from 'core/types/level.type';
import { Model } from 'core/types/model.type';
import { VarianceOutput } from 'core/types/output.type';
import JobDropdown from 'modules/field/shared/job-dropdown';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import { ParentSize } from '@visx/responsive';
import VarianceChart from 'modules/field/shared/variance-chart';
import { ChartNavigationDirection } from 'core/enums/chart-navigation-direction';
import Snackbar from 'components/snackbar';

export type ForecastVarianceProps = {
  period: Period;
};

export default function ForecastVariance({ period }: ForecastVarianceProps) {
  const { metadataF } = useStore();
  const [job, setJob] = useState<JobOption>(metadataF.jobs[0]);
  const [level, setLevel] = useState<LevelOption>(metadataF.levels[0]);
  const [model, setModel] = useState<Model>(metadataF.models[0]);
  const [baseDate, setBaseDate] = useState(model.asOfDate);
  const [metricA, _setMetricA] = useState('capacity');
  const [metricB, _setMetricB] = useState('demand');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<VarianceOutput[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState('');

  const momentUnit = DateService.getMomentUnit(period);

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    -1,
    14,
    baseDate
  );

  const handleChartNavigation = (_direction: ChartNavigationDirection) => {
    if (_direction === ChartNavigationDirection.left) {
      const _date = DateService.decreaseDate(momentUnit, 1, baseDate);
      setBaseDate(_date);
    }
    if (_direction === ChartNavigationDirection.right) {
      const _date = DateService.increaseDate(momentUnit, 1, baseDate);
      setBaseDate(_date);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getVariance(
          period,
          level,
          job,
          startDate,
          endDate,
          +model.id,
          [metricA, metricB]
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, baseDate, job]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <JobDropdown
            value={job}
            onChange={(value) => setJob(value)}
            style={{
              margin: {
                xs: '0 0 20px 0',
                md: '0 20px 20px 0',
              },
            }}
          />
          <Dropdown
            label={t`dropdown.modelRunDate`}
            options={metadataF.models}
            selectedItem={model}
            onChange={(el) => {
              setModel(el);
              setBaseDate(el.asOfDate);
            }}
            selector={(val) => val.id}
            keyName="model"
            style={{
              margin: {
                xs: '20px 0',
                md: '0',
              },
            }}
            formatLabel={(val) =>
              moment.utc(val.asOfDate).format(DateFormat.default)
            }
          />
        </Box>
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      <Box>
        <ParentSize>
          {({ width }) => (
            <VarianceChart
              width={width}
              height={500}
              period={period}
              data={data}
              metricA={metricA}
              metricB={metricB}
              loading={loading}
              positiveColors={theme.viz.variance.colors.positive}
              negativeColors={theme.viz.variance.colors.negative}
              onNavigation={handleChartNavigation}
            />
          )}
        </ParentSize>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
