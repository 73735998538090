import Banner from 'components/banner';
import NavBar from 'components/nav-bar';
import ProtectedPage from 'components/protected-page';
import ApiService from 'core/services/api.service';
import AuthService from 'core/services/auth.service';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { navRoutes, pageRoutes, sharedLinkRoute } from './routes';

export default function FieldModule() {
  useEffect(() => {
    const getTokens = async () => {
      const token = await AuthService.getTokens();
      if (token.idToken !== '') {
        await ApiService.post('users', {});
      }
    };
    getTokens();
  }, []);

  return (
    <ProtectedPage navBar={<NavBar routes={navRoutes} />}>
      <Banner />
      <Routes>
        {pageRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route
          key={sharedLinkRoute.path}
          path={sharedLinkRoute.path}
          element={<sharedLinkRoute.Component />}
        />
      </Routes>
    </ProtectedPage>
  );
}
