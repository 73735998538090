import { Alert, Box, Snackbar as MuiSnackbar, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useStore } from 'core/hooks/useStore';
import { useEffect, useState } from 'react';
import LevelAutocomplete from '../../shared/level-autocomplete';
import { LevelOption } from 'core/types/level.type';
import OutputServiceF from 'core/services/output.service';
import { GraphOutputResponse } from 'core/types/output.type';
import OutputChart from 'modules/field/shared/output-chart';
import { Period } from 'core/enums/period.enum';
import { Model } from 'core/types/model.type';
import DatePicker from 'components/date-picker';
import DateService from 'core/services/date.service';
import JobDropdown from 'modules/field/shared/job-dropdown';
import { JobOption } from 'core/types/job.type';
import { useTranslation } from 'react-i18next';
import NoteForm from 'components/note-form';
import { Note } from 'core/types/note.type';
import NoteServiceF from 'core/services/notes.service';
import { ChartKey } from 'core/enums/chart-key.enum';
import Snackbar from 'components/snackbar';

export type ForecastGraphViewProps = {
  period: Period;
  initialDate: string;
  minDate?: string;
  maxDate?: string;
  noteFormOpen?: boolean;
  onNoteFormOpen: (isOpen: boolean) => void;
  setLevel: (level: LevelOption) => void;
  level: LevelOption;
  setNoteDate: (date: string) => void;
};

export default function ForecastGraphView({
  period,
  initialDate,
  minDate,
  maxDate,
  noteFormOpen = false,
  onNoteFormOpen,
  level,
  setLevel = () => null,
  setNoteDate,
}: ForecastGraphViewProps) {
  const { metadataF } = useStore();
  const [data, setData] = useState<GraphOutputResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [model, _] = useState<Model>(metadataF.models[0]);
  const [job, setJob] = useState<JobOption>(metadataF.jobs[0]);
  const [baseDate, setBaseDate] = useState(initialDate);
  const { t } = useTranslation();
  const theme = useTheme();
  const [snackSuccess, setSnackSuccess] = useState(false);
  const [snackFail, setSnackFail] = useState(false);
  const [notes, setNotes] = useState<Note[]>([]);
  const [error, setError] = useState('');
  const momentUnit = DateService.getMomentUnit(period);
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    unitsDisplayed,
    baseDate
  );

  const { minDate: safeMinDate, maxDate: safeMaxDate } =
    DateService.safelyExtractDateLimits(minDate, maxDate);

  const colorRangeA = theme.viz.output.colorScaleA;
  const forecastColorA = theme.viz.output.forecastColorA;
  const patternColorA = theme.viz.output.patternColorA;
  const colorRangeB = theme.viz.output.colorScaleB;
  const forecastColorB = theme.viz.output.forecastColorB;
  const patternColorB = theme.viz.output.patternColorB;

  const setPattern = (key: string) => (key.includes('Hybrid') ? 1 : 0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getGraphOutput(
          period,
          model,
          level,
          startDate,
          endDate,
          ['demand']
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    const fetchNotes = async () => {
      const _notes = await NoteServiceF.getChartNotes(
        +model.id,
        period,
        ChartKey.output,
        startDate,
        endDate,
        level.id,
        level.level
      );

      setNotes(_notes);
    };
    fetchData();
    fetchNotes();
  }, [period, level, baseDate, model, snackSuccess]);

  const handleClose = () => {
    onNoteFormOpen(false);
  };

  const handleSnackClose = () => {
    setSnackSuccess(false);
    setSnackFail(false);
  };

  data.sort((a, b) => a.key.localeCompare(b.key));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <JobDropdown
            value={job}
            onChange={(value) => setJob(value)}
            style={{
              margin: {
                xs: '0 0 20px 0',
                md: '0 20px 20px 0',
              },
            }}
          />
          <DatePicker
            label={t`dropdown.graphStart`}
            views={[momentUnit]}
            minDate={safeMinDate}
            maxDate={safeMaxDate}
            value={startDate}
            setValue={(date) => {
              setBaseDate(date);
              if (setNoteDate) {
                setNoteDate(date);
              }
            }}
          />
        </Box>
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      <ParentSize>
        {({ width }) => (
          <OutputChart
            width={width}
            height={500}
            data={data}
            job={job}
            period={period}
            loading={loading}
            colorRangeA={colorRangeA}
            forecastColorA={forecastColorA}
            forecastColorB={forecastColorB}
            patternColorA={patternColorA}
            colorRangeB={colorRangeB}
            patternColorB={patternColorB}
            setPattern={setPattern}
            notes={notes}
          />
        )}
      </ParentSize>
      {noteFormOpen && (
        <NoteForm
          isOpen={noteFormOpen}
          onClose={handleClose}
          initialDate={baseDate}
          period={period}
          level={level}
          snackSuccess={setSnackSuccess}
          snackFail={setSnackFail}
        />
      )}
      <MuiSnackbar
        open={snackSuccess}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`noteForm.successMessage`}
        </Alert>
      </MuiSnackbar>
      <MuiSnackbar
        open={snackFail}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t`noteForm.errorMessage`}
        </Alert>
      </MuiSnackbar>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
