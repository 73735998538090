export const mockSalesChart = [
  {
    id: 1,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 50,
    actual: 40,
  },
  {
    id: 2,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 55,
    actual: 60,
  },
  {
    id: 3,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 50,
    actual: 50,
  },
  {
    id: 4,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 40,
    actual: 20,
  },
  {
    id: 5,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 70,
    actual: 65,
  },
  {
    id: 6,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 50,
    actual: 30,
  },
  {
    id: 7,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 45,
    actual: 40,
  },
  {
    id: 8,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 75,
    actual: 60,
  },
  {
    id: 9,
    job0: {
      id: 'Fiber',
      name: 'Fiber',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Fiber',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 45,
    actual: 35,
  },
  {
    id: 10,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 30,
    actual: 40,
  },
  {
    id: 11,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 55,
    actual: 49,
  },
  {
    id: 12,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_1',
      name: 'TX_RC_1',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_1',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 57,
    actual: 50,
  },
  {
    id: 13,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 40,
    actual: 40,
  },
  {
    id: 14,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 76,
    actual: 63,
  },
  {
    id: 15,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_2',
      name: 'TX_RC_2',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_2',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 60,
    actual: 45,
  },
  {
    id: 16,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 1,
    projected: 50,
    actual: 45,
  },
  {
    id: 17,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 2,
    projected: 70,
    actual: 60,
  },
  {
    id: 18,
    job0: {
      id: 'Copper',
      name: 'Copper',
    },
    job1: {
      id: 'SOC',
      name: 'Install',
    },
    level1: {
      id: 'TX_RC_3',
      name: 'TX_RC_3',
    },
    bucket: {
      id: '1',
      name: 'Gross Adds',
    },
    cluster: {
      id: 'TX_CLUSTER_1',
      name: 'TX_CLUSTER_1',
    },
    job0Id: 'Copper',
    job1Id: 'SOC',
    level1Id: 'TX_RC_3',
    bucketId: 1,
    clusterId: 'TX_CLUSTER_1',
    week: 3,
    projected: 40,
    actual: 35,
  },
];
