// c = coma
// s = slash
// d = dash
// syntax = s_MMDD = MM/DD, c_MMDD = MM, DD
export enum DateFormat {
  default = 'YYYY-MM-DD',
  s_DDMMYYYY = 'DD/MM/YYYY',
  s_MMDDYYYY = 'MM/DD/YYYY',
  s_MMDD = 'MM/DD',
  ddd = 'ddd',
  defaultTime = 'YYYY-MM-DD, h:mm:ss a',
  download = 'YYYYMMDDhhmmss',

  // TODO: deprecate
  monthlyFormat = 'MMM, YYYY',
  dailyFormat = 'MMM D, YYYY',
  refresh = 'MMM D, YYYY h:mm:ss a',
  intradayFormat = 'H:m',
  monthAndDay = 'MM/DD',
  dayOnly = 'ddd',
  dayMonthDD = 'ddd MM/DD',
}
