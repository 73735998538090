import { SxProps, Theme, useTheme } from '@mui/material';
import Dropdown from 'components/dropdown';
import { useStore } from 'core/hooks/useStore';
import { MetricOption } from 'core/types/metric.type';

export type MetricDropdownProps = {
  value: MetricOption;
  style?: SxProps<Theme>;
  filterJobs?: boolean;
  onChange: (value: MetricOption) => void;
};

export default function MetricDropdown({
  value,
  style,
  onChange,
  filterJobs,
}: MetricDropdownProps) {
  const { metadataF } = useStore();
  const theme = useTheme();

  const { metricOptions } = metadataF;

  return (
    <Dropdown
      keyName="metric-jobs"
      selectedItem={value}
      onChange={onChange}
      options={metricOptions.filter((el) =>
        filterJobs
          ? el.categoryId === 'demand' || el.categoryId === 'capacity'
          : true
      )}
      selector={(el) => el.id}
      style={{
        ...style,
        backgroundColor: theme.ui.dropdown.strong.background,
        color: theme.ui.dropdown.strong.color,
        '& fieldset': {
          borderColor: 'transparent',
        },
      }}
      formatLabel={(el) => `${el.name}`}
      formatStyle={(el) => ({
        paddingLeft: el.indent ? 4 : 2,
      })}
    />
  );
}
