import {
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import DatePicker from 'components/date-picker';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import DateService from 'core/services/date.service';
import OutputServiceF from 'core/services/output.service';
import { LevelOption } from 'core/types/level.type';
import { Model } from 'core/types/model.type';
import { GraphOutputResponse } from 'core/types/output.type';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiTableCell from '@mui/material/TableCell';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import TableLoader from 'components/table-loader';
import numeral from 'numeral';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import Snackbar from 'components/snackbar';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  align: 'center',
  backgroundColor: theme.ui.table.background,
  borderBottom: `1px dashed ${theme.ui.table.border}`,
  borderRight: `1px solid ${theme.ui.table.border}`,
  padding: 25,
}));

const TableCellHeader = styled(MuiTableCell)(({ theme }) => ({
  align: 'center',
  fontSize: '0.875rem',
  backgroundColor: theme.ui.table.background,
  borderRight: `1px solid ${theme.ui.table.border}`,
  paddingBottom: 0,
  borderBottom: 'none',
}));

const TableCellSubHeader = styled(MuiTableCell)(({ theme }) => ({
  align: 'center',
  fontWeight: 'bold',
  fontSize: '0.875rem',
  backgroundColor: theme.ui.table.background,
  paddingTop: 4,
  top: 40,
}));

export type ForecastListViewProps = {
  period: Period;
  initialDate: string;
  minDate?: string;
  maxDate?: string;
  setLevel: (level: LevelOption) => void;
  level: LevelOption;
};

export default function ForecastListView({
  period,
  initialDate,
  minDate,
  maxDate,
  level,
  setLevel = () => null,
}: ForecastListViewProps) {
  const { metadataF } = useStore();
  const [model, _] = useState<Model>(metadataF.models[0]);
  const [data, setData] = useState<GraphOutputResponse[]>([]);
  const [baseDate, setBaseDate] = useState(initialDate);
  const [loading, setLoading] = useState(true);
  const momentUnit = DateService.getMomentUnit(period);
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState('');

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    unitsDisplayed,
    baseDate
  );

  const { minDate: safeMinDate, maxDate: safeMaxDate } =
    DateService.safelyExtractDateLimits(minDate, maxDate);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getListOutput(
          period,
          model,
          level,
          startDate,
          endDate,
          ['demand']
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, baseDate, model]);

  const borderRightSolidStyle = {
    borderRight: `1px solid ${theme.ui.table.border}`,
    borderRightColor: theme.ui.table.borderStrong,
  } as React.CSSProperties;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
        }}
        pb={5}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <DatePicker
            label={t`dropdown.graphStart`}
            views={[momentUnit]}
            minDate={safeMinDate}
            maxDate={safeMaxDate}
            value={startDate}
            setValue={(date) => {
              setBaseDate(date);
            }}
          />
        </Box>
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      {loading ? (
        <TableLoader />
      ) : (
        <TableContainer sx={{ maxHeight: 740 }}>
          <Table stickyHeader aria-label={t`field.views.listView.tableLabel`}>
            <TableHead style={{ minHeight: 200 }}>
              <TableRow>
                <TableCellHeader
                  style={{
                    ...borderRightSolidStyle,
                  }}
                ></TableCellHeader>
                <TableCellHeader
                  colSpan={2}
                >{t`field.views.listView.cellsHeader.allWorkTypes`}</TableCellHeader>
                {data.map((el) =>
                  el.value[0].keys.sort().map((key) => (
                    <TableCellHeader key={`cell-header-1-${key}`} colSpan={2}>
                      {key}
                    </TableCellHeader>
                  ))
                )}
              </TableRow>
              <TableRow>
                <TableCellSubHeader
                  rowSpan={2}
                  style={{
                    ...borderRightSolidStyle,
                    borderBottom: `1px solid ${theme.ui.table.border}`,
                  }}
                >
                  {t`field.views.listView.cellsHeader.date`}
                </TableCellSubHeader>
                <TableCellSubHeader>{t`field.views.listView.cellsHeader.actual`}</TableCellSubHeader>
                <TableCellSubHeader
                  style={{ borderRight: `1px solid ${theme.ui.table.border}` }}
                >
                  {t`field.views.listView.cellsHeader.forecast`}
                </TableCellSubHeader>

                {data.map((el) =>
                  el.value[0].keys.sort().map((key) => (
                    <Fragment key={`cell-subheader-1-${key}`}>
                      <TableCellSubHeader>{t`field.views.listView.cellsHeader.actual`}</TableCellSubHeader>
                      <TableCellSubHeader
                        style={{
                          borderRight: `1px solid ${theme.ui.table.border}`,
                        }}
                      >
                        {t`field.views.listView.cellsHeader.forecast`}
                      </TableCellSubHeader>
                    </Fragment>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((el) =>
                el.value.map((row) => {
                  return (
                    <TableRow key={row.date} tabIndex={-1}>
                      <TableCell style={borderRightSolidStyle}>
                        {moment(row.date).format(DateFormat.s_MMDDYYYY)}
                      </TableCell>
                      <TableCell style={{ borderRight: 'none' }}>
                        {row.actual['all'] > 0 &&
                          numeral(row.actual['all']).format(
                            NumeralFormat.default
                          )}
                      </TableCell>
                      <TableCell>
                        {numeral(row.forecast['all']).format(
                          NumeralFormat.default
                        )}
                      </TableCell>
                      {row.keys.sort().map((key) => (
                        <Fragment key={`table-cell-1-${key}`}>
                          <TableCell style={{ borderRight: 'none' }}>
                            {row.actual[key] > 0 &&
                              numeral(row.actual[key]).format(
                                NumeralFormat.default
                              )}
                          </TableCell>
                          <TableCell>
                            {numeral(row.forecast[key]).format(
                              NumeralFormat.default
                            )}
                          </TableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
          {!loading && !data.length && (
            <Box
              sx={{
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2>{t`charts.noData`}</h2>
            </Box>
          )}
        </TableContainer>
      )}
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
