import { useEffect, useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { stateIcons } from 'assets/icons/states';
import { useTranslation } from 'react-i18next';
import SVGIcon from 'components/svg-icon';
import GeoProjection from 'components/charts/geo-projection';
import OutputServiceF from 'core/services/output.service';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import { LevelOption } from 'core/types/level.type';
import {
  DemandOutput,
  DemandOutputResponse,
  MapOutput,
} from 'core/types/output.type';
import { Model } from 'core/types/model.type';
import { ALL_LEVEL_OPTION } from 'core/constants/options.constants';
import DateSlider from 'components/date-slider';
import DatePicker from 'components/date-picker';
import DateService from 'core/services/date.service';
import moment from 'moment';
import { flatten, uniq } from 'lodash';
import OutputHorizontalMicroChart from 'modules/field/shared/output-horizontal-micro-chart';
import { MetricOption } from 'core/types/metric.type';
import MetricDropdown from 'modules/field/shared/metric-dropdown';
import { NumberService } from 'core/services/number.service';
import { scaleThreshold } from '@visx/scale';
import { DateFormat } from 'core/enums/date-format.enum';
import Snackbar from 'components/snackbar';

const Title = styled('h3')(({ theme }) => ({
  fontWeight: theme.ui.text.title.fontWeight,
  fontSize: '1.5rem',
  margin: '0 20px 0 0',
}));

const ChartLabel = styled('p')({
  fontSize: '0.875rem',
  margin: '0',
});

export type ForecastMapViewProps = {
  period: Period;
  initialDate: string;
  minDate?: string;
  maxDate?: string;
};

export default function ForecastMapView({
  period,
  initialDate,
  minDate,
  maxDate,
}: ForecastMapViewProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { metadataF } = useStore();
  const [model, _] = useState<Model>(metadataF.models[0]);
  const [baseDate, setBaseDate] = useState(initialDate);
  const [selectedDate, setSelectedDate] = useState(baseDate);
  const [level, setLevel] = useState<LevelOption>(ALL_LEVEL_OPTION);
  const [range, setRange] = useState<number[]>([]);
  const [metric, setMetric] = useState<MetricOption>(
    metadataF.metricOptions[0]
  );
  const [data, setData] = useState<MapOutput[]>([]);
  const [loading, setLoading] = useState(true);
  const [demandData, setDemandData] = useState<DemandOutputResponse[]>([]);
  const [demandLoading, setDemandLoading] = useState(true);
  const stateIcon = stateIcons.find((el) => {
    return el.name === level.name;
  });
  const [error, setError] = useState('');

  const momentUnit = DateService.getMomentUnit(period);
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    unitsDisplayed,
    baseDate
  );

  useEffect(() => {
    const fetchDemandData = async () => {
      try {
        setDemandLoading(true);
        const result = await OutputServiceF.getDemandOutput(
          period,
          model,
          selectedDate,
          level.id !== 'all' ? level.id : undefined
        );
        setDemandData(result);
        setDemandLoading(false);
        setError('');
      } catch (e) {
        setDemandLoading(false);
        setError((e as Error).message);
      }
    };
    fetchDemandData();
  }, [period, selectedDate, level, model, metric]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getMapOutput(
          period,
          model.id,
          startDate,
          endDate,
          metric
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    const fetchRanges = async () => {
      try {
        const result = await OutputServiceF.getMetricRange(
          period,
          model.id,
          metric
        );

        setRange(NumberService.getThresholdRange(result.min, result.max, 11));
        setError('');
      } catch (e) {
        setError((e as Error).message);
      }
    };
    fetchRanges();
    fetchData();
  }, [period, baseDate, level, model, metric]);

  const thresholdColorData = range.map((el, i) => ({
    value: el,
    color: theme.viz.output.thresholdColors[i],
  }));

  const colorScale = scaleThreshold<number, string>({
    domain: thresholdColorData.map((el) => el.value),
    range: thresholdColorData.map((el) => el.color),
  });

  const colorRange = theme.viz.output.colorScaleA;
  const patternColor = theme.viz.output.patternColorA;

  const setPattern = (key: string) => (key.includes('Hybrid') ? 1 : 0);

  const { minDate: safeMinDate, maxDate: safeMaxDate } =
    DateService.safelyExtractDateLimits(minDate, maxDate);

  const overviewData = demandData
    .map((el) => ({
      ...el,
      value: el.value.filter((f) => f.key === level.id),
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

  const subLevelData = demandData
    .map((el) => ({
      ...el,
      value: el.value
        .filter((f) => f.key !== level.id)
        .sort((a, b) => a.key.localeCompare(b.key)),
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

  const levelNames = uniq(
    flatten(subLevelData.map((el) => el.value.map((d) => d.key)))
  );

  const selectedData = data.filter(
    (el) =>
      moment.utc(el.date).format(DateFormat.default) ===
      moment.utc(selectedDate).format(DateFormat.default)
  );
  console.log('map data', selectedData);

  const selectedLevelMapData = selectedData.find(
    (el) => el.levelId === level.id
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '65%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              margin: {
                xs: '0 0 20px',
                md: '0',
              },
            }}
          >
            <MetricDropdown
              value={metric}
              onChange={(value) => setMetric(value)}
              style={{
                margin: {
                  xs: '0 0 20px 0',
                  md: '0 20px 20px 0',
                },
                width: {
                  xs: '100%',
                  md: '250px',
                },
              }}
            />
            <DatePicker
              label={t`dropdown.graphStart`}
              views={[momentUnit]}
              minDate={safeMinDate}
              maxDate={safeMaxDate}
              value={baseDate}
              setValue={(value) => {
                setBaseDate(value);
                if (moment(value).isAfter(selectedDate)) {
                  setSelectedDate(value);
                }
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: {
              xs: 'auto',
              md: '700px',
            },
          }}
        >
          <ParentSize style={{ paddingTop: 10 }}>
            {({ width }) => (
              <GeoProjection
                width={width}
                height={width * 0.75}
                loading={loading}
                data={selectedData}
                dataFilter={(d, feature) => d.levelId === feature.properties.id}
                selector={(el) => el.forecast}
                zoomSelector={(_feature) => _feature.properties.id === level.id}
                thresholdColorData={thresholdColorData}
                colorScale={colorScale}
                onClick={(value) => {
                  const _level = metadataF.levels.find((el) => el.id === value);
                  if (_level) {
                    setLevel(_level);
                  } else {
                    setLevel(ALL_LEVEL_OPTION);
                  }
                }}
              />
            )}
          </ParentSize>
          <Box>
            <DateSlider
              period={period}
              quantity={unitsDisplayed}
              value={selectedDate}
              startDate={baseDate}
              onSelect={(value) => {
                setSelectedDate(value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '30%',
          },
          paddingLeft: {
            xs: 0,
            md: '40px',
          },
          marginTop: {
            xs: '20px',
            md: '0',
          },
          borderLeft: {
            xs: '0px',
            md: `1px solid ${theme.global.divider}`,
          },
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          <Title>
            {stateIcon && stateIcon?.name !== ''
              ? stateIcon.name
              : t`field.views.mapView.states.usa`}
          </Title>
          {
            <SVGIcon
              Icon={stateIcon?.Icon}
              color={
                selectedLevelMapData
                  ? colorScale(selectedLevelMapData.forecast)
                  : colorRange[0]
              }
              height={40}
            />
          }
        </Box>
        <Box>
          {/* <ParentSize>
            {({ width }) => (
              <OutputMicroChart
                width={width}
                height={200}
                data={overviewData}
                loading={demandLoading}
                colorRangeA={colorRangeA}
                patternColorA={patternColorA}
                colorRangeB={colorRangeB}
                patternColorB={patternColorB}
                setPattern={setPattern}
              />
            )}
          </ParentSize> */}
          <Box
            sx={{
              height: {
                xs: '100%',
                md: '500px',
              },
              overflowY: 'scroll',
            }}
          >
            {levelNames.map((el) => {
              const _chartData = subLevelData
                .map((d) => ({
                  ...d,
                  value: d.value
                    .filter((f) => f.key === el)
                    .sort((a, b) => a.key.localeCompare(b.key)),
                }))
                .sort((a, b) => a.key.localeCompare(b.key));

              const _subLevelData = _chartData
                .map((d) => ({
                  ...d,
                  value: flatten(
                    d.value.filter((f) => f.key === el).map((s) => s.value)
                  ) as DemandOutput[],
                }))
                .sort((a, b) => a.key.localeCompare(b.key));

              const _levelNames = uniq(
                flatten(_subLevelData.map((s) => s.value.map((d) => d?.key)))
              ).filter((f) => f);

              const _label = metadataF.levels.find((f) => f.id === el);
              return (
                <Box key={`level-chart-${el}`} sx={{ margin: '10px 0' }}>
                  <ChartLabel
                    style={{
                      fontSize: level.id !== 'all' ? '1.25rem' : '0.875rem',
                      fontWeight: level.id !== 'all' ? 'bold' : 'normal',
                    }}
                  >
                    {_label ? _label.name : el}
                  </ChartLabel>
                  <ParentSize>
                    {({ width }) => (
                      <OutputHorizontalMicroChart
                        width={width}
                        height={65}
                        data={_chartData}
                        loading={demandLoading}
                        colorRange={colorRange}
                        patternColor={patternColor}
                        setPattern={setPattern}
                        metric={metric.metricName}
                      />
                    )}
                  </ParentSize>
                  <Box
                    sx={{
                      margin: '20px 0',
                    }}
                  >
                    {_levelNames.map((s) => {
                      const __subLevelData = _subLevelData
                        .map((n) => ({
                          ...n,
                          value: n.value.filter((f) => f?.key === s),
                        }))
                        .sort((a, b) => a.key.localeCompare(b.key));

                      const dataA = _chartData.find(
                        (d) => d.key === metric.metricName
                      ) || {
                        key: '',
                        value: [],
                      };

                      const forecastA = (dataA.value || []).map((q) => ({
                        key: dataA.key,
                        value: q.forecast['all'],
                      }));

                      const xMax = Math.max(...forecastA.map((q) => q.value));

                      const __label = metadataF.levels.find((f) => f.id === s);
                      return (
                        <Box
                          key={`sub-level-chart-${s}`}
                          sx={{ margin: '20px 0' }}
                        >
                          <ChartLabel>{__label ? __label.name : el}</ChartLabel>
                          <ParentSize>
                            {({ width }) => (
                              <OutputHorizontalMicroChart
                                width={width}
                                height={65}
                                xMaxOverride={xMax}
                                data={__subLevelData}
                                loading={demandLoading}
                                colorRange={colorRange}
                                patternColor={patternColor}
                                setPattern={setPattern}
                                metric={metric.metricName}
                              />
                            )}
                          </ParentSize>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
