import { Snackbar as MuiSnackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

export type SnackbarProps = {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number;
};

export default function Snackbar({
  severity,
  message = '',
  open,
  onClose,
  autoHideDuration = 3000
}: SnackbarProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        severity={severity}
        onClose={handleClose}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
