import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import moment from 'moment';
import numeral from 'numeral';
import { VarianceOutput } from 'core/types/output.type';
import { DateFormat } from 'core/enums/date-format.enum';
import { Bar, Line } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';

import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import ChartNavigation from 'components/chart-navigation';
import { ChartNavigationDirection } from 'core/enums/chart-navigation-direction';

const LegendWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '20px 20px 0',
});

const LegendRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const TooltipTitle = styled('p')({
  margin: '0 10px 10px',
  fontWeight: 'bold',
});

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  date: string;
  dataA?: VarianceOutput;
  dataB?: VarianceOutput;
};

export type VarianceChartProps = {
  width: number;
  height: number;
  metricA: string;
  metricB: string;
  data: VarianceOutput[];
  period: Period;
  loading?: boolean;
  positiveColors?: string[];
  negativeColors?: string[];
  onNavigation?: (direction: ChartNavigationDirection) => void;
};

export default function VarianceChart({
  width,
  height,
  data,
  period,
  metricA,
  metricB,
  positiveColors = [],
  negativeColors = [],
  loading = false,
  onNavigation = () => null,
}: VarianceChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const margin = { top: 40, right: 0, bottom: 0, left: 75 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipDataOutput>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: { date: '' },
  });

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && data.length < 2) {
    return <ChartLoader width={width} height={height} />;
  }

  const dateFormat = DateService.getDateFormat(period, 'MM/DD', 'MM/YY');

  const secondaryAxisDateFormat = DateService.getDateFormat(
    period,
    'ddd',
    'mmm'
  );

  const tooltipDateFormat = DateService.getDateFormat(
    period,
    'MMM D, YYYY',
    'MMM YYYY'
  );

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/

  const positiveColorScale = scaleOrdinal<string, string>({
    domain: [metricA, metricB],
    range: positiveColors,
  });

  const negativeColorScale = scaleOrdinal<string, string>({
    domain: [metricA, metricB],
    range: negativeColors,
  });

  const xDomain = uniq(data.map((el) => el.date)).map((el) =>
    moment.utc(el).format(DateFormat.default)
  );

  const xScale = scaleBand<string>({
    domain: xDomain,
    range: [margin.left, innerWidth],
  });

  const yMax = Math.max(...data.map((el) => el.variance));
  const yMin = Math.min(...data.map((el) => el.variance));

  const symmetryMax = Math.max(Math.abs(yMin), Math.abs(yMax));

  const yScale = scaleLinear<number>({
    domain: [-symmetryMax, symmetryMax],
    range: [innerHeight, margin.top],
    nice: true,
  });

  const today = moment().format(DateFormat.default);
  const todayExists = xScale.domain().includes(today);

  const BAR_GROUP_SPACING = 20;

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|

  let tooltipTimeout: number;

  const handleTooltip = (_data: VarianceOutput) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);

    const _date = moment.utc(_data.date).format(DateFormat.default);
    const barX = (xScale(_date) || 0) + BAR_GROUP_SPACING;

    const _dataSet = data.filter((el) => el.date === _data.date);
    const _dataA = _dataSet.find((el) => el.metricId === metricA);
    const _dataB = _dataSet.find((el) => el.metricId === metricB);

    showTooltip({
      tooltipLeft: barX + 50,
      tooltipTop: yScale(_data.variance),
      tooltipData: {
        date: moment.utc(_data.date).format(tooltipDateFormat),
        dataA: _dataA,
        dataB: _dataB,
      },
    });
  };

  const handleCloseTooltip = () => {
    setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  //   _                           _
  //  | | ___  __ _  ___ _ __   __| |___
  //  | |/ _ \/ _` |/ _ \ '_ \ / _` / __|
  //  | |  __/ (_| |  __/ | | | (_| \__ \
  //  |_|\___|\__, |\___|_| |_|\__,_|___/
  //          |___/

  const _metricNameA = data.find((el) => el.metricId === metricA);
  const metricNameA = _metricNameA ? _metricNameA.metricName : metricA;

  const _metricNameB = data.find((el) => el.metricId === metricB);
  const metricNameB = _metricNameB ? _metricNameB.metricName : metricB;

  const legendsA: LegendItem[] = [
    {
      label: `${metricNameA} (${t`field.views.forecastVariance.labels.over`})`,
      color: positiveColorScale(metricA),
      type: LegendType.box,
    },
    {
      label: `${metricNameA} (${t`field.views.forecastVariance.labels.under`})`,
      color: negativeColorScale(metricA),
      type: LegendType.box,
    },
    {
      label: `${metricNameB} (${t`field.views.forecastVariance.labels.over`})`,
      color: positiveColorScale(metricB),
      type: LegendType.box,
    },
    {
      label: `${metricNameB} (${t`field.views.forecastVariance.labels.under`})`,
      color: negativeColorScale(metricB),
      type: LegendType.box,
    },
  ];

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ height: 2 }}>{loading && <LinearProgress />}</div>
        <LegendWrapper>
          <LegendRow>
            {legendsA.map((el, i) => (
              <Legend key={`legend-${i}`} item={el} width={220} />
            ))}
          </LegendRow>
        </LegendWrapper>
        <ChartNavigation onClick={onNavigation}>
          <svg width={innerWidth} height={height}>
            <Group>
              <GridRows
                left={margin.left}
                scale={yScale}
                width={innerWidth}
                height={innerHeight}
                stroke={theme.viz.grid.stroke}
                strokeDasharray={theme.viz.grid.strokeDasharray}
              />
            </Group>
            <Group>
              {data
                .filter((el) => el.metricId === metricA)
                .map((el) => {
                  const _date = moment.utc(el.date).format(DateFormat.default);
                  const barWidth = xScale.bandwidth() / 2 - BAR_GROUP_SPACING;
                  const barHeight = Math.abs(yScale(el.variance) - yScale(0));
                  const barX = (xScale(_date) || 0) + BAR_GROUP_SPACING;
                  const barY = yScale(Math.max(0, el.variance));
                  return (
                    <Group key={`bar-${metricA}-${_date}`}>
                      <Bar
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        onMouseLeave={handleCloseTooltip}
                        onMouseMove={() => handleTooltip(el)}
                        fill={
                          el.variance > 0
                            ? positiveColorScale(metricA)
                            : negativeColorScale(metricA)
                        }
                      />
                    </Group>
                  );
                })}
            </Group>
            <Group>
              {data
                .filter((el) => el.metricId === metricB)
                .map((el) => {
                  const _date = moment.utc(el.date).format(DateFormat.default);
                  const barWidth = xScale.bandwidth() / 2 - BAR_GROUP_SPACING;
                  const barHeight = Math.abs(yScale(el.variance) - yScale(0));
                  const barX = (xScale(_date) || 0) + xScale.bandwidth() / 2;
                  const barY = yScale(Math.max(0, el.variance));
                  return (
                    <Group key={`bar-${metricA}-${_date}`}>
                      <Bar
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        onMouseLeave={handleCloseTooltip}
                        onMouseMove={() => handleTooltip(el)}
                        fill={
                          el.variance > 0
                            ? positiveColorScale(metricB)
                            : negativeColorScale(metricB)
                        }
                      />
                    </Group>
                  );
                })}
            </Group>
            <Group>
              {todayExists && (
                <rect
                  x={xScale(today) || 0}
                  y={innerHeight}
                  height={40}
                  width={xScale.bandwidth()}
                  fill={theme.viz.xAxis.background.highlight}
                />
              )}
            </Group>
            <Group>
              <AxisLeft
                left={margin.left}
                scale={yScale}
                hideTicks
                numTicks={5}
                stroke={theme.viz.yAxis.stroke}
                tickLabelProps={() => ({
                  fontSize: theme.viz.yAxis.fontSize,
                  fill: theme.viz.yAxis.color,
                  textAnchor: 'end',
                })}
              />
            </Group>
            <Group>
              <AxisBottom
                top={innerHeight}
                scale={xScale}
                numTicks={unitsDisplayed}
                hideTicks
                stroke={theme.viz.xAxis.stroke}
                tickFormat={(d) => moment(d).format(dateFormat)}
                tickLabelProps={(d) => ({
                  fontSize: theme.viz.xAxis.fontSize,
                  fill:
                    moment(d).format('d') === '0' && period === Period.daily
                      ? theme.viz.xAxis.color.accent
                      : theme.viz.xAxis.color.primary,
                  fontWeight: 'bold',
                  textAnchor: 'middle',
                })}
              />
            </Group>
            <Group>
              <AxisBottom
                top={innerHeight + 16}
                scale={xScale}
                stroke={theme.viz.xAxis.stroke}
                tickLabelProps={(d) => ({
                  fontSize: theme.viz.xAxis.fontSize,
                  fill:
                    moment(d).format('d') === '0' && period === Period.daily
                      ? theme.viz.xAxis.color.accent
                      : theme.viz.xAxis.color.secondary,
                  textAnchor: 'middle',
                  fontWeight:
                    today === moment(d).format(DateFormat.default)
                      ? 'bold'
                      : 'normal',
                })}
                numTicks={unitsDisplayed}
                hideAxisLine
                hideTicks
                tickFormat={(d) => {
                  const tKey = `time.now.${period}`;
                  return today === moment(d).format(DateFormat.default)
                    ? t(tKey)
                    : moment(d).format(secondaryAxisDateFormat);
                }}
              />
            </Group>
            {todayExists && (
              <Group>
                <Line
                  stroke={theme.viz.xAxis.stroke}
                  from={{
                    x: (xScale(today) || 0) + xScale.bandwidth() / 2,
                    y: margin.top,
                  }}
                  to={{
                    x: (xScale(today) || 0) + xScale.bandwidth() / 2,
                    y: innerHeight,
                  }}
                />
              </Group>
            )}
            {
              <Group>
                <Line
                  stroke={theme.viz.xAxis.stroke}
                  from={{ x: xScale.range()[0], y: yScale(0) }}
                  to={{ x: xScale.range()[1], y: yScale(0) }}
                />
              </Group>
            }
          </svg>
        </ChartNavigation>
        {tooltipOpen &&
          tooltipData &&
          (tooltipData.dataA || tooltipData.dataB) && (
            <TooltipWithBounds
              top={tooltipTop}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 60,
                padding: 20,
                backgroundColor: theme.ui.tooltip.background,
                color: theme.ui.tooltip.color,
              }}
            >
              <TooltipWrapper>
                <TooltipTitle>{tooltipData.date}</TooltipTitle>
                <TooltipTable>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 10px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column',
                      }}
                    >
                      {tooltipData.dataA && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Legend
                            item={{
                              label: metricNameA,
                              type: LegendType.box,
                              color:
                                tooltipData.dataA.variance > 0
                                  ? positiveColorScale(metricA)
                                  : negativeColorScale(metricA),
                            }}
                          />
                          <p style={{ margin: 0, textAlign: 'right' }}>
                            {numeral(tooltipData.dataA.variance).format(
                              NumeralFormat.default
                            )}
                          </p>
                        </Box>
                      )}
                      {tooltipData.dataB && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Legend
                            item={{
                              label: metricNameB,
                              type: LegendType.box,
                              color:
                                tooltipData.dataB.variance > 0
                                  ? positiveColorScale(metricB)
                                  : negativeColorScale(metricB),
                            }}
                          />
                          <p style={{ margin: 0, textAlign: 'right' }}>
                            {numeral(tooltipData.dataB.variance).format(
                              NumeralFormat.default
                            )}
                          </p>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </TooltipTable>
              </TooltipWrapper>
            </TooltipWithBounds>
          )}
      </div>
    </div>
  );
}
