import { SxProps, Theme, useTheme } from '@mui/material';
import Dropdown from 'components/dropdown';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { NumberService } from 'core/services/number.service';
import { HorizonRange } from 'core/types/horizon-range.type';
import { useTranslation } from 'react-i18next';

export type HorizonRangeDropdownProps = {
  value: HorizonRange;
  style?: SxProps<Theme>;
  period: Period;
  onChange: (value: HorizonRange) => void;
};

export default function HorizonRangeDropdown({
  value,
  style,
  period,
  onChange,
}: HorizonRangeDropdownProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = NumberService.getHorizonRangeOptions(period, 1);
  const momentUnit = DateService.getMomentUnit(period);
  const tKey = `time.${momentUnit}s`;

  return (
    <Dropdown
      keyName="horizon-range"
      selectedItem={value}
      onChange={onChange}
      options={options}
      selector={(el) => el.id}
      style={{
        ...style,
        backgroundColor: theme.ui.dropdown.strong.background,
        color: theme.ui.dropdown.strong.color,
        '& fieldset': {
          borderColor: 'transparent',
        },
      }}
      formatLabel={(el) => {
        const _tKey =
          el.id === options[options.length - 1].id
            ? 'dropdown.horizonRangeAggregate'
            : 'dropdown.horizonRange';

        return `${t(_tKey, {
          range: el.id,
          frequency: t(tKey),
        })}`;
      }}
    />
  );
}
