import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function SharedLink() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const page = searchParams.get('page');
  const tab = searchParams.get('tab');
  const subTab = searchParams.get('subTab');
  const date = searchParams.get('date');
  const level = searchParams.get('levelId');

  useEffect(() => {
    navigate(`${page}`, {
      state: {
        sharedLinkData: {
          tab,
          subTab,
          date,
          level,
        },
      },
    });
  }, []);

  return <></>;
}
