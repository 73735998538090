import { SxProps, TextField, Theme } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  CalendarPickerView,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateFormat } from 'core/enums/date-format.enum';
import moment from 'moment';

export type DatePickerProps = {
  views: CalendarPickerView[];
  label?: string;
  minDate?: string;
  maxDate?: string;
  value: string;
  setValue: (value: string) => void;
  size?: 'small' | 'medium';
  disabled?: boolean;
  inputSx?: SxProps<Theme>;
};

export default function DatePicker({
  views,
  setValue,
  size,
  label = '',
  value = '1900-01-01',
  minDate = '1900-01-01',
  maxDate = '3000-01-01',
  disabled = false,
  inputSx = {},
}: DatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        views={views}
        label={label}
        minDate={moment(minDate, DateFormat.default)}
        maxDate={moment(maxDate, DateFormat.default)}
        value={moment(value, DateFormat.default)}
        onChange={(date) => {
          if (date) {
            setValue(date.format(DateFormat.default));
          }
        }}
        renderInput={(params) => (
          <TextField
            sx={{ m: 0, ...inputSx }}
            {...params}
            helperText={null}
            size={size ? size : 'small'}
          />
        )}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
