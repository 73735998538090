import { useTranslation } from 'react-i18next';
import { Period } from 'core/enums/period.enum';
import PageSection from 'components/page-section';
import {
  Box,
  Alert,
  Snackbar as MuiSnackbar,
  styled,
  useTheme,
} from '@mui/material';
import { useStore } from 'core/hooks/useStore';
import { useEffect, useState } from 'react';
import { JobOption } from 'core/types/job.type';
import { LevelOption } from 'core/types/level.type';
import DateService from 'core/services/date.service';
import JobDropdown from 'modules/field/shared/job-dropdown';
import DatePicker from 'components/date-picker';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import { Model } from 'core/types/model.type';
import Dropdown from 'components/dropdown';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import { EvolutionOutputResponse } from 'core/types/output.type';
import OutputServiceF from 'core/services/output.service';
import { ParentSize } from '@visx/responsive';
import EvolutionChart from 'modules/field/shared/evolution-chart';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { uniq } from 'lodash';
import Snackbar from 'components/snackbar';
import SaveToPageDialog from '../components/save-to-page-dialog';
import { fetchCustomPages } from 'store/field';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useLocation } from 'react-router-dom';

const Label = styled('p')({});

export type ForecastEvolutionProps = {
  period: Period;
  initialDate: string;
  minDate?: string;
  maxDate?: string;
  initialLevel?: LevelOption;
};

export default function ForecastEvolution({
  period,
  initialDate,
  minDate,
  maxDate,
  initialLevel,
}: ForecastEvolutionProps) {
  const { metadataF, dispatch } = useStore();
  const [job, setJob] = useState<JobOption>(metadataF.jobs[0]);
  const [baseDate, setBaseDate] = useState(initialDate);
  const [loading, setLoading] = useState(true);
  const [saveToPageOpen, setSaveToPageOpen] = useState(false);
  const [saveToPageSuccess, setSaveToPageSuccess] = useState(false);
  const [saveToPageFail, setSaveToPageFail] = useState(false);
  const [level, setLevel] = useState<LevelOption>(
    initialLevel ? initialLevel : metadataF.levels[0]
  );
  const [modelA, setModelA] = useState<Model>(
    metadataF.models[0] || { id: 0, asOfDate: '', runDatetime: '' }
  );
  const [modelB, setModelB] = useState<Model>(
    metadataF.models[1] || { id: 0, asOfDate: '', runDatetime: '' }
  );
  const [modelC, setModelC] = useState<Model>(
    metadataF.models[2] || { id: 0, asOfDate: '', runDatetime: '' }
  );
  const [data, setData] = useState<EvolutionOutputResponse[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState('');
  const [sharedLink, setSharedLink] = useState(false);
  const location = useLocation();

  const models = [modelA, modelB, modelC].filter((el) => el);
  const modelSetters = [setModelA, setModelB, setModelC];
  const seriesColors = [
    { id: modelA.id, ...theme.viz.evolution.series.a },
    { id: modelB.id, ...theme.viz.evolution.series.b },
    { id: modelC.id, ...theme.viz.evolution.series.c },
  ];

  const momentUnit = DateService.getMomentUnit(period);

  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    14,
    baseDate
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getEvolutionOutput(
          period,
          models.map((el) => `${el.id}`),
          level,
          startDate,
          endDate,
          job
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, baseDate, modelA, modelB, modelC, job]);

  data.sort((a, b) => +b.key - +a.key);

  const availableModels = uniq([modelA, modelB, modelC].map((el) => el.id));
  const colors = availableModels.map((el) => {
    const _color = seriesColors.find((d) => +d.id === +el);
    return (
      _color || {
        id: '0',
        color: theme.viz.default,
        backgroundColor: theme.viz.default,
      }
    );
  });

  const saveToPage = () => {
    setSaveToPageOpen(true);
  };

  const handleSaveToPageSuccess = () => {
    setSaveToPageSuccess(true);
    dispatch(fetchCustomPages());
  };

  const handleSaveToPageFail = () => {
    setSaveToPageSuccess(false);
    setSaveToPageFail(true);
  };

  const handleSaveToPageReset = () => {
    setSaveToPageSuccess(false);
    setSaveToPageFail(false);
  };

  const initSharedLink = () => {
    setSharedLink(true);
    OutputServiceF.copyToClipboard(
      `${window.location.host}/field/link?page=${location.pathname}&date=${baseDate}&levelId=${level.id}&tab=2`
    );
  };

  const { minDate: safeMinDate, maxDate: safeMaxDate } =
    DateService.safelyExtractDateLimits(minDate, maxDate);

  return (
    <PageSection
      title={t`field.views.forecastEvolution.title`}
      tooltipData={t`field.views.forecastEvolution.tooltip`}
      menuOptions={[
        {
          title: t`pageSection.menu.saveToPage`,
          icon: BookmarkBorderOutlinedIcon,
          onClick: saveToPage,
        },
        {
          title: t`pageSection.menu.share`,
          onClick: initSharedLink,
          icon: ShareOutlinedIcon,
        },
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          padding: '24px 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <JobDropdown
            value={job}
            onChange={(value) => setJob(value)}
            style={{
              margin: {
                xs: '0 0 20px 0',
                md: '0 20px 20px 0',
              },
            }}
          />
          <DatePicker
            label={t`dropdown.forecastDate`}
            views={[momentUnit]}
            minDate={safeMinDate}
            maxDate={safeMaxDate}
            value={startDate}
            setValue={(d) => {
              setBaseDate(d);
            }}
          />
        </Box>
        <LevelAutocomplete
          style={{
            width: {
              md: 200,
            },
          }}
          value={level}
          onChange={(value) => {
            if (value) {
              setLevel(value);
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'end',
          padding: '0 0 20px',
        }}
      >
        <Label
          sx={{
            color: theme.viz.label.color,
            margin: {
              xs: '10px 0 10px',
              md: '10px 16px 0 0',
            },
            fontSize: theme.viz.label.fontSize,
            fontWeight: theme.viz.label.fontWeight,
          }}
        >
          {t`field.views.forecastEvolution.labels.outputDates`}
        </Label>
        {models.map((el, i) => {
          const _color = seriesColors.find((c) => c.id === el.id);
          return +el.id > 0 ? (
            <Dropdown
              selectedItem={el}
              key={`model-${el.id}-${i}`}
              keyName={`model-${el.id}`}
              options={metadataF.models}
              onChange={(val) => modelSetters[i](val)}
              selector={(val) => `${val.id}`}
              formatLabel={(val) =>
                moment.utc(val.asOfDate).format(DateFormat.default)
              }
              style={{
                backgroundColor: _color
                  ? _color.backgroundColor
                  : 'transparent',
                color: _color ? _color.color : 'transparent',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                margin: {
                  xs: '0 0 20px 0',
                  md: '0 20px 20px 0',
                },
              }}
            />
          ) : null;
        })}
      </Box>
      <Box>
        <ParentSize>
          {({ width }) => (
            <EvolutionChart
              width={width}
              height={500}
              data={data}
              loading={loading}
              period={period}
              colors={colors}
            />
          )}
        </ParentSize>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
      {saveToPageOpen && (
        <SaveToPageDialog
          open={saveToPageOpen}
          level={level}
          onClose={() => setSaveToPageOpen(false)}
          onSuccess={handleSaveToPageSuccess}
          onFail={handleSaveToPageFail}
        />
      )}
      <MuiSnackbar
        open={saveToPageSuccess}
        autoHideDuration={3000}
        onClose={handleSaveToPageReset}
      >
        <Alert
          onClose={handleSaveToPageReset}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`saveToPageForm.successMessage`}
        </Alert>
      </MuiSnackbar>
      <MuiSnackbar
        open={saveToPageFail}
        autoHideDuration={3000}
        onClose={handleSaveToPageReset}
      >
        <Alert
          onClose={handleSaveToPageReset}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`saveToPageForm.errorMessage`}
        </Alert>
      </MuiSnackbar>
      <MuiSnackbar
        open={sharedLink}
        autoHideDuration={3000}
        onClose={() => setSharedLink(false)}
      >
        <Alert
          onClose={() => setSharedLink(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`alerts.clipboard`}
        </Alert>
      </MuiSnackbar>
    </PageSection>
  );
}
