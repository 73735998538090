import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import moment from 'moment';
import numeral from 'numeral';
import { ComparisonLineDatum } from 'core/types/output.type';
import { DateFormat } from 'core/enums/date-format.enum';
import { Bar, LinePath } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useState } from 'react';
import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import { curveMonotoneX } from '@visx/curve';
import { rangeDisplayed } from 'core/constants/forecast.constants';

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  data: { legend: LegendItem; value?: number }[];
};

export type ComparisonLineChartProps = {
  width: number;
  height: number;
  data: ComparisonLineDatum[];
  actualDate: string;
  period: Period;
  horizonA: string;
  horizonB: string;
  loading?: boolean;
};

export default function ComparisonLineChart({
  width,
  height,
  data,
  actualDate,
  period,
  horizonA = '',
  horizonB = '',
  loading = false,
}: ComparisonLineChartProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [hoverValue, setHoverValue] = useState('');
  const margin = { top: 40, right: 0, bottom: 40, left: 75 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipDataOutput>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: { data: [] },
  });

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && data.length < 2) {
    return <ChartLoader width={width} height={height} />;
  }

  const dateFormat = DateService.getDateFormat(period, 'MM/DD', 'MM/YY');

  const tooltipDateFormat = DateService.getDateFormat(
    period,
    'MMM D, YYYY',
    'MMM YYYY'
  );

  //         _       _
  //    __| | __ _| |_ __ _
  //   / _` |/ _` | __/ _` |
  //  | (_| | (_| | || (_| |
  //   \__,_|\__,_|\__\__,_|

  data.sort((a, b) => a.date.localeCompare(b.date));

  const horizonAData = data.find((el) => el.horizon === horizonA);
  const horizonBData = data.find((el) => el.horizon === horizonB);
  const actualData = { ...data[0] };

  actualData.date = actualDate;

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/

  const xDomain = data.map((d) => moment(d.date).format(DateFormat.default));

  if (actualData && actualData.value.actual) {
    xDomain.push(actualData.date);
  }

  const xScale = scaleBand<string>({
    domain: xDomain,
    range: [margin.left, innerWidth],
  });

  const yValues = data.map((el) => el.value.forecast);

  if (actualData) {
    yValues.push(actualData.value.actual);
  }

  const yMax = Math.max(...yValues);
  const yMin = Math.min(...yValues);

  const yScale = scaleLinear<number>({
    domain: [yMin, yMax],
    range: [innerHeight, margin.top],
    nice: true,
  });

  const MARKER_RADIUS = xScale.bandwidth() / 15;

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|

  const handleTooltip = (_date: string, top: number) => {
    const _value = data.find(
      (el) =>
        moment.utc(el.date).format(DateFormat.default) ===
        moment.utc(_date).format(DateFormat.default)
    );

    const _tooltipData: TooltipDataOutput = {
      data: [
        {
          legend: {
            label: t('field.views.forecastComparison.labels.horizon', {
              horizon: 'A',
            }),
            color: theme.viz.comparison.horizonA,
            type: LegendType.box,
          },
          value: horizonAData?.value.forecast,
        },
        {
          legend: {
            label: t('field.views.forecastComparison.labels.horizon', {
              horizon: 'B',
            }),
            color: theme.viz.comparison.horizonB,
            type: LegendType.box,
          },
          value: horizonBData?.value.forecast,
        },
        {
          legend: {
            label: `${t`field.views.forecastComparison.labels.actual`} (${moment(
              actualData.date
            ).format(dateFormat)})`,
            color: theme.viz.comparison.actual,
            type: LegendType.box,
          },
          value: actualData?.value.forecast,
        },
        {
          legend: {
            label: moment(_date).format(tooltipDateFormat),
            color: theme.viz.comparison.line,
            type: LegendType.line,
          },
          value: _value ? _value.value.forecast : 0,
        },
      ],
    };

    showTooltip({
      tooltipLeft: (xScale(_date) || 0) + 50,
      tooltipTop: top,
      tooltipData: _tooltipData,
    });
  };

  const handleCloseTooltip = () => {
    setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ height: 2 }}>{loading && <LinearProgress />}</div>
        <svg width={innerWidth} height={height}>
          <Group>
            <GridRows
              left={margin.left}
              scale={yScale}
              width={innerWidth}
              height={innerHeight}
              stroke={theme.viz.grid.stroke}
              strokeDasharray={theme.viz.grid.strokeDasharray}
            />
          </Group>
          <Group>
            <LinePath
              stroke={theme.viz.comparison.line || theme.viz.default}
              strokeWidth={2}
              data={data}
              curve={curveMonotoneX}
              x={(d) =>
                xScale.bandwidth() / 2 +
                (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
              }
              y={(d) => yScale(d.value.forecast) ?? 0}
            />
            {actualData && actualData.value.actual && (
              <>
                <LinePath
                  stroke={theme.viz.comparison.actual || theme.viz.default}
                  strokeWidth={2}
                  data={[
                    {
                      date: actualData.date,
                      value: { forecast: yScale.domain()[0] || 0 },
                    },
                    {
                      date: actualData.date,
                      value: { forecast: yScale.domain()[1] || 1 },
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value.forecast) ?? 0}
                />
                {
                  <circle
                    fill={theme.viz.comparison.actual || theme.viz.default}
                    cx={
                      (xScale(
                        moment(actualData.date).format(DateFormat.default)
                      ) ?? 0) +
                      xScale.bandwidth() / 2
                    }
                    cy={yScale(actualData.value.actual)}
                    r={MARKER_RADIUS}
                  />
                }
              </>
            )}
            {horizonAData && (
              <>
                <LinePath
                  stroke={theme.viz.comparison.horizonA || theme.viz.default}
                  strokeWidth={2}
                  data={[
                    {
                      date: horizonAData.date,
                      value: { forecast: yScale.domain()[0] || 0 },
                    },
                    {
                      date: horizonAData.date,
                      value: { forecast: yScale.domain()[1] || 1 },
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value.forecast) ?? 0}
                />
                <circle
                  fill={theme.viz.comparison.horizonA || theme.viz.default}
                  cx={
                    (xScale(
                      moment(horizonAData.date).format(DateFormat.default)
                    ) ?? 0) +
                    xScale.bandwidth() / 2
                  }
                  cy={yScale(horizonAData.value.forecast)}
                  r={MARKER_RADIUS}
                />
              </>
            )}
            {horizonBData && (
              <>
                <LinePath
                  stroke={theme.viz.comparison.horizonB || theme.viz.default}
                  strokeWidth={2}
                  data={[
                    {
                      date: horizonBData.date,
                      value: { forecast: yScale.domain()[0] || 0 },
                    },
                    {
                      date: horizonBData.date,
                      value: { forecast: yScale.domain()[1] || 1 },
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value.forecast) ?? 0}
                />
                <circle
                  fill={theme.viz.comparison.horizonB || theme.viz.default}
                  cx={
                    (xScale(
                      moment(horizonBData.date).format(DateFormat.default)
                    ) ?? 0) +
                    xScale.bandwidth() / 2
                  }
                  cy={yScale(horizonBData.value.forecast)}
                  r={MARKER_RADIUS}
                />
              </>
            )}
          </Group>
          <Group>
            {xScale.domain().map((el, i) => (
              <Group
                key={`tooltip-g-comparison-${i}`}
                onMouseLeave={() => {
                  setHoverValue('');
                  handleCloseTooltip();
                }}
                onMouseMove={(e) => {
                  if (el !== hoverValue) {
                    setHoverValue(el);
                  }
                  handleTooltip(el, e.screenY * 0.3);
                }}
                opacity={el === hoverValue ? 1 : 0}
              >
                <LinePath
                  stroke={
                    moment
                      .utc(horizonBData?.date)
                      .format(DateFormat.default) ===
                      moment.utc(el).format(DateFormat.default) ||
                    moment
                      .utc(horizonAData?.date)
                      .format(DateFormat.default) ===
                      moment.utc(el).format(DateFormat.default) ||
                    moment.utc(actualData?.date).format(DateFormat.default) ===
                      moment.utc(el).format(DateFormat.default)
                      ? 'transparent'
                      : theme.viz.default
                  }
                  strokeWidth={1}
                  strokeDasharray={5}
                  data={[
                    {
                      date: el,
                      value: yScale.domain()[0] || 0,
                    },
                    {
                      date: el,
                      value: yScale.domain()[1] || 1,
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment.utc(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value) ?? 0}
                />
                {data.map((d) => {
                  const radius = 5;
                  const _value = data.find(
                    (f) =>
                      moment.utc(f.date).format(DateFormat.default) ===
                      moment.utc(el).format(DateFormat.default)
                  );
                  return _value ? (
                    <circle
                      key={`tooltip-comparison-marker-${d.date}-${i}`}
                      fill={
                        moment
                          .utc(horizonBData?.date)
                          .format(DateFormat.default) ===
                          moment.utc(el).format(DateFormat.default) ||
                        moment
                          .utc(horizonAData?.date)
                          .format(DateFormat.default) ===
                          moment.utc(el).format(DateFormat.default)
                          ? 'transparent'
                          : theme.viz.comparison.line || theme.viz.default
                      }
                      cx={(xScale(el) || 0) + xScale.bandwidth() / 2}
                      cy={yScale(_value.value.forecast)}
                      r={radius}
                    />
                  ) : null;
                })}
                <Bar
                  x={xScale(el)}
                  y={innerHeight - yScale(yMin)}
                  fill="transparent"
                  width={xScale.bandwidth()}
                  height={innerHeight - (innerHeight - yScale(yMin))}
                />
              </Group>
            ))}
          </Group>
          <Group>
            <AxisLeft
              left={margin.left}
              scale={yScale}
              hideTicks
              numTicks={5}
              stroke={theme.viz.yAxis.stroke}
              tickLabelProps={() => ({
                fontSize: theme.viz.yAxis.fontSize,
                fill: theme.viz.yAxis.color,
                textAnchor: 'end',
              })}
            />
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              numTicks={rangeDisplayed}
              hideTicks
              stroke={theme.viz.xAxis.stroke}
              tickFormat={(d) => moment(d).format(dateFormat)}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  actualData.date === d
                    ? theme.viz.xAxis.color.secondary
                    : theme.viz.xAxis.color.primary,
                fontWeight: actualData.date === d ? 'bold' : 'normal',
                textAnchor: 'middle',
              })}
            />
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight + 16}
              scale={xScale}
              stroke={theme.viz.xAxis.stroke}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  actualData.date === d
                    ? theme.viz.xAxis.color.secondary
                    : theme.viz.xAxis.color.primary,
                textAnchor: 'middle',
                fontWeight: actualData.date === d ? 'bold' : 'normal',
              })}
              numTicks={rangeDisplayed}
              hideAxisLine
              hideTicks
              tickFormat={(d, i, a) => {
                if (actualData.date === d) {
                  return t`field.views.forecastComparison.labels.actual`;
                }

                if (i === 0) {
                  const tKey = `time.${DateService.getFrequencyTranslation(
                    period
                  )}${a.length - 1 > 1 ? 's' : ''}`;
                  return t('field.views.forecastComparison.labels.prior', {
                    number: a.length - 1,
                    frequency: t(tKey),
                  });
                }

                if (
                  i ===
                  Math.ceil(
                    (actualData.value.actual ? a.length - 2 : a.length - 1) / 2
                  )
                ) {
                  const tKey = `time.${DateService.getFrequencyTranslation(
                    period
                  )}${
                    Math.ceil(
                      (actualData.value.actual ? a.length - 2 : a.length - 1) /
                        2
                    ) > 1
                      ? 's'
                      : ''
                  }`;
                  return t('field.views.forecastComparison.labels.prior', {
                    number: Math.ceil((a.length - 1) / 2),
                    frequency: t(tKey),
                  });
                }
                if (
                  i === (actualData.value.actual ? a.length - 2 : a.length - 1)
                ) {
                  const tKey = `time.${DateService.getFrequencyTranslation(
                    period
                  )}`;
                  return t('field.views.forecastComparison.labels.prior', {
                    number: 1,
                    frequency: t(tKey),
                  });
                }
                return '';
              }}
            />
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 60,
              padding: 20,
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
            }}
          >
            <TooltipWrapper>
              <TooltipTable>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 10px',
                  }}
                >
                  {tooltipData.data.map((el, i) => (
                    <Box
                      key={`tooltip-comparison-${i}`}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Legend item={el.legend} />
                      {numeral(el.value).format(NumeralFormat.default)}
                    </Box>
                  ))}
                </Box>
              </TooltipTable>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
