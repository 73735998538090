import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { SvgIconComponent } from '@mui/icons-material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')({
  marginBottom: 30,
});

const Title = styled('h2')(({ theme }) => ({
  fontSize: theme.ui.text.title.fontSize,
  fontWeight: theme.ui.text.title.fontWeight,
  color: theme.ui.text.title.color,
  margin: '0 0 20px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const SectionHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ControlWrapper = styled('div')({
  display: 'flex',
});

const Control = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: 12,
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingBottom: 10,
  },
  color: theme.ui.text.secondary.color,
  cursor: 'pointer',
}));

const Text = styled(Box)(({ theme }) => ({
  fontSize: theme.ui.text.secondary.fontSize,
  fontWeight: theme.ui.text.secondary.fontWeight,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  padding: '10px',
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.ui.text.tooltip.background,
    color: theme.ui.text.tooltip.color,
    fontSize: theme.ui.text.tooltip.fontSize,
    maxWidth: 250,
    padding: '20px',
    boxShadow: '1px 10px 18px rgba(0, 0, 0, 0.2)',
  },
}));

export type MenuOptionType = {
  title: string;
  icon: SvgIconComponent;
  onClick: () => void;
};

export type PageSectionProps = {
  title?: string;
  children?: ReactNode;
  onDownload?: () => void;
  tooltipData?: string;
  menuOptions?: MenuOptionType[];
  style?: SxProps<Theme>;
};

export default function PageSection({
  children,
  title,
  onDownload,
  tooltipData,
  menuOptions,
  style = {},
}: PageSectionProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <Wrapper sx={{ ...style }}>
      <SectionHeader>
        <Title>{title}</Title>
        <ControlWrapper>
          {onDownload && (
            <Control onClick={onDownload}>
              <DownloadIcon />
              <Text>{t`pageSection.download`}</Text>
            </Control>
          )}
          {tooltipData && (
            <Control>
              <HtmlTooltip title={tooltipData ?? ''}>
                <Box display={'flex'} alignItems={'center'}>
                  <InfoOutlinedIcon />
                  <Text>{t`pageSection.info`}</Text>
                </Box>
              </HtmlTooltip>
            </Control>
          )}
          {menuOptions && (
            <Control>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {menuOptions?.map((menu) => {
                  const MenuIcon = menu.icon;
                  return (
                    <MenuItem
                      onClick={menu.onClick}
                      key={`menu-item-${menu.title}`}
                    >
                      <ListItemIcon>
                        <MenuIcon fontSize="small" />
                      </ListItemIcon>
                      {menu.title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Control>
          )}
        </ControlWrapper>
      </SectionHeader>
      {children}
    </Wrapper>
  );
}
