import { Period } from 'core/enums/period.enum';
import { HorizonRange } from 'core/types/horizon-range.type';

export class NumberService {
  public static generateNumberSet(start: number, end: number): number[] {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  }

  private static getHorizonRangeLimits(period: Period): {
    modulo: number;
    max: number;
    moduloMax: number;
  } {
    switch (period) {
      case Period.monthly:
        return { modulo: 3, max: 14, moduloMax: 15 };
      case Period.daily:
      default:
        return { modulo: 7, max: 60, moduloMax: 63 };
    }
  }

  public static getHorizonRangeOptions(
    period: Period,
    initial: number
  ): HorizonRange[] {
    const { modulo, max, moduloMax } = this.getHorizonRangeLimits(period);
    const result = [];
    for (let i = initial; i <= moduloMax; i++) {
      if (i % modulo === 0) {
        const from = i - modulo + 1;
        const to = i === moduloMax ? max : i;
        result.push({ id: `${from}-${to}`, from, to });
      }
    }
    result.push({ id: `${initial}-${max}`, from: initial, to: max });
    return result;
  }

  public static getThresholdRange(min: number, max: number, qty: number) {
    const digitsMin = `${min.toFixed(0)}`.length - 1;
    const digitsMax = `${max.toFixed(0)}`.length - 1;
    const powerMin = 10 ** digitsMin;
    const powerMax = 10 ** digitsMax;

    const roundedMin = Math.floor(min / powerMin) * powerMin;
    const roundedMax = Math.floor(max / powerMax) * powerMax;
    const interval = (roundedMax - roundedMin) / (qty - 1);

    const range = [];

    for (let i = 0; i <= qty - 1; i++) {
      range.push(Math.floor(roundedMin + interval * i));
    }

    return range;
  }
}
