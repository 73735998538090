import TextField from '@mui/material/TextField';
import * as MuiAutocomplete from '@mui/material/Autocomplete';
import { HTMLAttributes, ReactNode, SyntheticEvent } from 'react';
import { SxProps, Theme } from '@mui/material';

export type AutocompleteProps<T> = {
  selectedValue?: T | T[] | null | undefined;
  options: T[];
  label?: string;
  onChange: (value: T | T[] | null) => void;
  onClose?:
    | ((
        event: SyntheticEvent<Element, Event>,
        reason: MuiAutocomplete.AutocompleteCloseReason
      ) => void)
    | undefined;
  getOptionLabel: (option: T) => string;
  getOptionDisabled?: (option: T) => boolean;
  renderOption: (
    props: HTMLAttributes<HTMLLIElement>,
    option: T,
    selected: boolean
  ) => ReactNode;
  style?: SxProps<Theme>;
  disabledOptions?: T[];
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  disableClearable?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
};

export default function Autocomplete<T>({
  options,
  selectedValue,
  onChange,
  getOptionLabel,
  getOptionDisabled,
  renderOption,
  style,
  label = '',
  limitTags = 10,
  disabledOptions = [],
  disableClearable = false,
  onClose = () => {},
  multiple = false,
  disableCloseOnSelect = false,
  ...rest
}: AutocompleteProps<T>) {
  const handleDisable = (option: T) =>
    !!disabledOptions.find((element) => element === option);

  return (
    <MuiAutocomplete.default
      multiple={multiple}
      value={selectedValue}
      limitTags={limitTags}
      size="small"
      onChange={(_, val) => {
        onChange(val);
      }}
      onClose={onClose}
      options={options}
      sx={{ ...style }}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled || handleDisable}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={(props, option, { selected }) =>
        renderOption(props, option, selected)
      }
      renderInput={(params) => <TextField {...params} label={label} />}
      {...rest}
    />
  );
}
