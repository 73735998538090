import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactNode, useState } from 'react';
import { styled, SxProps, Typography, useTheme } from '@mui/material';
import AppContainer from './app-container';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';

const Marker = styled('div')(({ theme }) => ({
  width: 7,
  height: 7,
  borderRadius: '50%',
  backgroundColor: theme.ui.tabs.notification,
  marginLeft: '4px',
}));

export type TabItem = {
  title: string;
  component: JSX.Element;
  isMarked?: boolean;
  id?: string;
  disabled?: boolean;
};

export type TabViewProps = {
  style?: SxProps<Theme>;
  tabsSx?: SxProps<Theme>;
  labelsSx?: SxProps<Theme>;
  tabItems: TabItem[];
  header?: boolean;
  initialIndex?: number;
  onSelect?: (value: number) => void;
  sectionLabel?: string;
  sectionTabItems?: TabItem[];
  renderSubmenu?: (selectedTabNumber: number) => ReactNode | null;
  scrollButtons?: boolean;
  tabsBorder?: boolean;
  tabItemsPadding?: string;
  inactiveColor?: string | null;
};

export default function TabView({
  style = {},
  tabItems,
  header,
  onSelect,
  initialIndex,
  sectionTabItems = [],
  renderSubmenu,
  tabsSx,
  scrollButtons = false,
  labelsSx,
  tabsBorder = true,
  tabItemsPadding = '20px 0',
  inactiveColor = null,
}: TabViewProps) {
  const [value, setValue] = useState(initialIndex || 0);
  const _theme = useTheme();
  const theme = header ? _theme.ui.tabs.header : _theme.ui.tabs;
  const { t } = useTranslation();

  const handleChange = (tab: number) => {
    setValue(tab);
    if (onSelect) {
      onSelect(tab);
    }
  };

  return (
    <>
      <AppContainer
        style={{
          backgroundColor: theme.background,
          ...style,
        }}
        hideMargin={!header}
      >
        <Box>
          <Box
            display={'flex'}
            justifyContent={
              [...tabItems, ...sectionTabItems].length <= 0
                ? 'flex-end'
                : 'space-between'
            }
            style={{
              borderBottom: tabsBorder ? `1px solid ${theme.divider}` : '',
              alignItems: 'center',
            }}
          >
            {[...tabItems, ...sectionTabItems].length > 0 && (
              <Tabs
                sx={{
                  backgroundColor: theme.background,
                  ...tabsSx,
                }}
                value={value}
                onChange={(_: React.SyntheticEvent, tab: number) =>
                  handleChange(tab)
                }
                variant="scrollable"
                TabIndicatorProps={{
                  sx: {
                    background: theme.border.color,
                    height: theme.border.width,
                  },
                }}
                scrollButtons={scrollButtons}
              >
                {tabItems?.map((item) => (
                  <Tab
                    key={item.title}
                    disabled={item.disabled}
                    label={
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'start'}
                        pt={0}
                      >
                        {sectionTabItems && sectionTabItems.length > 0 && (
                          <Typography
                            fontSize={10}
                            style={{ color: 'transparent' }}
                            height={14}
                            sx={{ ...labelsSx }}
                          />
                        )}
                        <Typography sx={{ ...labelsSx }}>
                          {item.title}
                        </Typography>
                      </Box>
                    }
                    sx={{
                      padding: '0 0 20px',
                      marginRight: 3,
                      fontWeight: theme.text.fontWeight,
                      fontSize: theme.text.fontSize,
                      textTransform: 'none',
                      backgroundColor: theme.background,
                      color: inactiveColor || theme.color.inactive,
                      '&.Mui-selected': {
                        backgroundColor: theme.background,
                        color: theme.color.active,
                        '&.Mui-focusVisible': {
                          background: theme.background,
                        },
                      },
                    }}
                  />
                ))}
                {sectionTabItems &&
                  sectionTabItems?.map((item) => (
                    <Tab
                      key={item.title}
                      disabled={item.disabled}
                      label={
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          pt={0}
                          alignItems={'start'}
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography fontSize={10}>
                              {t`labels.shared`}
                            </Typography>
                            {item.isMarked && <Marker />}
                          </Box>
                          <Typography>{item.title}</Typography>
                        </Box>
                      }
                      sx={{
                        padding: '0 0 20px',
                        marginRight: 3,
                        fontWeight: theme.text.fontWeight,
                        fontSize: theme.text.fontSize,
                        textTransform: 'none',
                        backgroundColor: theme.background,
                        color: theme.color.inactive,
                        '&.Mui-selected': {
                          backgroundColor: theme.background,
                          color: theme.color.active,
                          '&.Mui-focusVisible': {
                            background: theme.background,
                          },
                        },
                      }}
                    />
                  ))}
              </Tabs>
            )}
          </Box>
        </Box>
      </AppContainer>
      {renderSubmenu && renderSubmenu(value)}
      <div style={{ padding: tabItemsPadding }}>
        {tabItems[value] && tabItems[value].component}
        {sectionTabItems &&
          sectionTabItems.length > 0 &&
          sectionTabItems[value - tabItems.length] &&
          sectionTabItems[value - tabItems.length].component}
      </div>
    </>
  );
}
