import { useReducer } from 'react';

export function useSetState<T>(initial: T) {
  const [state, dispatch] = useReducer((prev: T, next: T) => {
    return { ...prev, ...next };
  }, initial);

  const setState = (nextState: Partial<T>) =>
    dispatch({ ...state, ...nextState });

  return { state, setState };
}
