import { ReactComponent as Alabama } from './Alabama.svg';
import { ReactComponent as Alaska } from './Alaska.svg';
import { ReactComponent as Arizona } from './Arizona.svg';
import { ReactComponent as Arkansas } from './Arkansas.svg';
import { ReactComponent as California } from './California.svg';
import { ReactComponent as Colorado } from './Colorado.svg';
import { ReactComponent as Connecticut } from './Connecticut.svg';
import { ReactComponent as Delaware } from './Delaware.svg';
import { ReactComponent as DC } from './District of Columbia.svg';
import { ReactComponent as Florida } from './Florida.svg';
import { ReactComponent as Georgia } from './Georgia.svg';
import { ReactComponent as Hawaii } from './Hawaii.svg';
import { ReactComponent as Idaho } from './Idaho.svg';
import { ReactComponent as Illinois } from './Illinois.svg';
import { ReactComponent as Indiana } from './Indiana.svg';
import { ReactComponent as Iowa } from './Iowa.svg';
import { ReactComponent as Kansas } from './Kansas.svg';
import { ReactComponent as Kentucky } from './Kentucky.svg';
import { ReactComponent as Louisiana } from './Louisiana.svg';
import { ReactComponent as Maine } from './Maine.svg';
import { ReactComponent as Maryland } from './Maryland.svg';
import { ReactComponent as Massachusetts } from './Massachusetts.svg';
import { ReactComponent as Michigan } from './Michigan.svg';
import { ReactComponent as Minnesota } from './Minnesota.svg';
import { ReactComponent as Mississippi } from './Mississippi.svg';
import { ReactComponent as Missouri } from './Missouri.svg';
import { ReactComponent as Montana } from './Montana.svg';
import { ReactComponent as USA } from './USA.svg';
import { ReactComponent as Nebraska } from './Nebraska.svg';
import { ReactComponent as Nevada } from './Nevada.svg';
import { ReactComponent as NewHampshire } from './New Hampshire.svg';
import { ReactComponent as NewJersey } from './New Jersey.svg';
import { ReactComponent as NewMexico } from './New Mexico.svg';
import { ReactComponent as NewYork } from './New York.svg';
import { ReactComponent as NorthCarolina } from './North Carolina.svg';
import { ReactComponent as NorthDakota } from './North Dakota.svg';
import { ReactComponent as Ohio } from './Ohio.svg';
import { ReactComponent as Oklahoma } from './Oklahoma.svg';
import { ReactComponent as Oregon } from './Oregon.svg';
import { ReactComponent as Pennsylvania } from './Pennsylvania.svg';
import { ReactComponent as RhodeIsland } from './Rhode Island.svg';
import { ReactComponent as SouthCarolina } from './South Carolina.svg';
import { ReactComponent as SouthDakota } from './South Dakota.svg';
import { ReactComponent as Tennessee } from './Tennessee.svg';
import { ReactComponent as Texas } from './Texas.svg';
import { ReactComponent as Utah } from './Utah.svg';
import { ReactComponent as Vermont } from './Vermont.svg';
import { ReactComponent as Virginia } from './Virginia.svg';
import { ReactComponent as Washington } from './Washington.svg';
import { ReactComponent as WestVirginia } from './West Virginia.svg';
import { ReactComponent as Wisconsin } from './Wisconsin.svg';
import { ReactComponent as Wyoming } from './Wyoming.svg';

export const stateIcons = [
  { Icon: Alabama, name: 'Alabama' },
  { Icon: Alaska, name: 'Alaska' },
  { Icon: Arizona, name: 'Arizona' },
  { Icon: Arkansas, name: 'Arkansas' },
  { Icon: California, name: 'California' },
  { Icon: Colorado, name: 'Colorado' },
  { Icon: Connecticut, name: 'Connecticut' },
  { Icon: Delaware, name: 'Delaware' },
  { Icon: DC, name: 'District of Columbia' },
  { Icon: Florida, name: 'Florida' },
  { Icon: Georgia, name: 'Georgia' },
  { Icon: Hawaii, name: 'Hawaii' },
  { Icon: Idaho, name: 'Idaho' },
  { Icon: Illinois, name: 'Illinois' },
  { Icon: Indiana, name: 'Indiana' },
  { Icon: Iowa, name: 'Iowa' },
  { Icon: Kansas, name: 'Kansas' },
  { Icon: Kentucky, name: 'Kentucky' },
  { Icon: Louisiana, name: 'Louisiana' },
  { Icon: Maine, name: 'Maine' },
  { Icon: Maryland, name: 'Maryland' },
  { Icon: Massachusetts, name: 'Massachusetts' },
  { Icon: Michigan, name: 'Michigan' },
  { Icon: Minnesota, name: 'Minnesota' },
  { Icon: Mississippi, name: 'Mississippi' },
  { Icon: Missouri, name: 'Missouri' },
  { Icon: Montana, name: 'Montana' },
  { Icon: USA, name: 'National' },
  { Icon: Nebraska, name: 'Nebraska' },
  { Icon: Nevada, name: 'Nevada' },
  { Icon: NewHampshire, name: 'New Hampshire' },
  { Icon: NewJersey, name: 'New Jersey' },
  { Icon: NewMexico, name: 'New Mexico' },
  { Icon: NewYork, name: 'New York' },
  { Icon: NorthCarolina, name: 'North Carolina' },
  { Icon: NorthDakota, name: 'North Dakota' },
  { Icon: Ohio, name: 'Ohio' },
  { Icon: Oklahoma, name: 'Oklahoma' },
  { Icon: Oregon, name: 'Oregon' },
  { Icon: Pennsylvania, name: 'Pennsylvania' },
  { Icon: RhodeIsland, name: 'Rhode Island' },
  { Icon: SouthCarolina, name: 'South Carolina' },
  { Icon: SouthDakota, name: 'South Dakota' },
  { Icon: Tennessee, name: 'Tennessee' },
  { Icon: Texas, name: 'Texas' },
  { Icon: Utah, name: 'Utah' },
  { Icon: Vermont, name: 'Vermont' },
  { Icon: Virginia, name: 'Virginia' },
  { Icon: Washington, name: 'Washington' },
  { Icon: WestVirginia, name: 'West Virginia' },
  { Icon: Wisconsin, name: 'Wisconsin' },
  { Icon: Wyoming, name: 'Wyoming' },
];
