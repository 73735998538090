import { Box } from '@mui/material';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import ChartLoader from 'components/chart-loader';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import numeral from 'numeral';

export type AccuracyHorizontalBarChartProps = {
  width: number;
  height: number;
  metricA: string;
  valueA: number;
  loading?: boolean;
  colorScale?: (value: number) => string;
};

export default function AccuracyHorizontalBarChart({
  width,
  height,
  metricA,
  valueA,
  loading,
  colorScale = () => '#F6F6F6',
}: AccuracyHorizontalBarChartProps) {
  const LABEL_HEIGHT = 20;
  const PADDING = 20;
  const LABEL_WIDTH = 65;
  const TICKER_WIDTH = 60;

  const BAR_HEIGHT = LABEL_HEIGHT * 0.7;

  const BAR_A_Y = BAR_HEIGHT / 4;

  const LABEL_A_Y = 0;

  if (loading) {
    return <ChartLoader width={width} height={height} scale={0.3} gap={-15} />;
  }

  const xScale = scaleLinear({
    domain: [0, 1],
    range: [0, width - LABEL_WIDTH - TICKER_WIDTH - PADDING],
  });

  return (
    <Box
      sx={{
        margin: '10px 0',
      }}
    >
      <svg width={width} height={height} style={{ zIndex: 1 }}>
        <Group>
          <foreignObject
            x={0}
            y={LABEL_A_Y}
            width={LABEL_WIDTH}
            height={LABEL_HEIGHT}
          >
            <div
              style={{
                color: '#000000',
                fontSize: '0.875rem',
              }}
            >
              {metricA}
            </div>
          </foreignObject>
          <Bar
            width={xScale(valueA) > 0 ? xScale(valueA) : 0}
            fill={colorScale(valueA)}
            height={BAR_HEIGHT}
            x={LABEL_WIDTH}
            y={BAR_A_Y}
          />
          <foreignObject
            x={(xScale(valueA) > 0 ? xScale(valueA) : 0) + LABEL_WIDTH}
            y={LABEL_A_Y}
            width={TICKER_WIDTH}
            height={LABEL_HEIGHT}
          >
            <div
              style={{
                color: '#000000',
                fontSize: '0.875rem',
                fontWeight: 'bold',
                padding: ' 0 0 0 5px',
              }}
            >
              {numeral(valueA).format(NumeralFormat.percent)}
            </div>
          </foreignObject>
        </Group>
      </svg>
    </Box>
  );
}
