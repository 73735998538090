import React, { useEffect } from 'react';
import { map } from 'lodash';
import WebsocketContext from './websocket-context.hook';
import WebsocketService from '../../services/websocket.service';
import { useSetState } from '../useSetState';
import { WSMessage } from '../../types/ws-message.type';
import { useStore } from '../useStore';

export const WebsocketProvider = ({ children }: { children: any; }) => {
  const { state, setState } = useSetState<{
    websocket: WebsocketService | null,
  }>({
    websocket: WebsocketService.getInstance(),
  });
  const {
    websocketState: {
      status
    },
    dispatch
  } = useStore();

  useEffect(() => {
    const initService = async () => {
      setState({
        websocket: WebsocketService.getInstance()
      });
    };

    initService();
  }, []);

  const connect = async (resubscribe?: boolean) => {
    let ws = state.websocket;

    if (!ws) {
      setState({
        websocket: ws
      });
      ws = WebsocketService.getInstance();
    }

    if (!ws?.websocket) {
      await ws.initSocket(dispatch);
    }

    if (resubscribe) {
      ws.subscriptions.forEach(({ channel }) => {
        ws?.sendMessage('subscribe', {
          channel
        });
      })
    }

    return ws;
  }

  const disconnect = async () => {
    let ws = state.websocket;

    if (!ws?.websocket) {
      ws = await connect();
    }

    ws.websocket?.close();
  }

  const subscribe = async (channel: string, callback = (event: WSMessage) => {}): Promise<string[]> => {
    let ws = state.websocket;

    if (!ws?.websocket) {
      try {
        ws = await connect();
      } catch (e) {
        return new Promise(() => []);
      }
    }

    const subscriptions = ws.subscribe(channel, callback);

    ws.sendMessage('subscribe', {
      channel
    });

    return map(subscriptions, 'channel');
  }

  const unsubscribe = async (channel: string) => {
    let ws = state.websocket;

    if (!ws?.websocket) {
      ws = await connect();
    }

    const currentSubscriptions = ws.unsubscribe(channel);

    ws.sendMessage('unsubscribe', {
      channel
    });

    if (currentSubscriptions.length === 0) {
      disconnect();
    }
  }

  const broadcast = async (channel: string, message: { [key: string]: number | string }) => {
    let ws = state.websocket;

    if (!ws?.websocket) {
      ws = await connect();
    }

    ws.sendMessage('broadcast', {
      channel,
      message
    });
  }

  return (
    <WebsocketContext.Provider value={{
      ...state,
      status,
      connect,
      disconnect,
      subscribe,
      unsubscribe,
      broadcast
    }}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;

