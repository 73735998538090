import { Box } from '@mui/material';
import Dropdown from 'components/dropdown';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import DateService from 'core/services/date.service';
import { JobOption } from 'core/types/job.type';
import { LevelOption } from 'core/types/level.type';
import { Model } from 'core/types/model.type';
import JobDropdown from 'modules/field/shared/job-dropdown';
import LevelMultiAutocomplete from 'modules/field/shared/level-multi-autocomplete';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormat } from 'core/enums/date-format.enum';
import { ChartNavigationDirection } from 'core/enums/chart-navigation-direction';
import OutputServiceF from 'core/services/output.service';
import {
  AccuracyTrendColors,
  AccuracyTrendSeries,
} from 'core/types/output.type';
import { ParentSize } from '@visx/responsive';
import AccuracyTrendChart from 'modules/field/shared/accuracy-trend-chart';
import { schemeCategory10 } from 'd3-scale-chromatic';
import Snackbar from 'components/snackbar';

export type AccuracyTrendProps = {
  period: Period;
};

export default function AccuracyTrend({ period }: AccuracyTrendProps) {
  const { metadataF } = useStore();
  const [job, setJob] = useState<JobOption>(metadataF.jobs[0]);
  const [levels, setLevel] = useState<LevelOption[]>([metadataF.levels[0]]);
  const [cachedKeys, setCachedKeys] = useState<string[]>([]);
  const [model, setModel] = useState<Model>(metadataF.models[0]);
  const [baseDate, setBaseDate] = useState(model.asOfDate);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AccuracyTrendSeries>({});
  const [colors, setColors] = useState<AccuracyTrendColors>({});
  const [colorCount, setColorCount] = useState(0);
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const momentUnit = DateService.getMomentUnit(period);
  const { startDate, endDate } = DateService.generateDateRange(
    momentUnit,
    0,
    14,
    baseDate
  );

  useEffect(() => {
    const fetchData = async (_levels: LevelOption[]) => {
      setLoading(true);
      try {
        const result = await Promise.all(
          _levels.map((el) =>
            OutputServiceF.getAccuracyTrend(
              period,
              +model.id,
              el,
              job,
              startDate,
              endDate
            )
          )
        );

        const _data: AccuracyTrendSeries = {};

        _levels.forEach((el, i) => {
          _data[el.name] = result[i];
        });

        setData({ ...data, ..._data });
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };

    const _levelFetch: LevelOption[] = [];
    const _colors: AccuracyTrendColors = {};
    levels.forEach((el) => {
      if (!cachedKeys.includes(el.name)) {
        setCachedKeys([el.name, ...cachedKeys]);
        _levelFetch.push(el);
        _colors[el.name] = colors[el.name]
          ? colors[el.name]
          : schemeCategory10[colorCount];
        const _colorCount = colorCount + 1 === 10 ? 0 : colorCount + 1;
        setColorCount(_colorCount);
      }
    });
    fetchData(_levelFetch);
    setColors({ ...colors, ..._colors });
  }, [job, levels, model, baseDate]);

  const handleChartNavigation = (_direction: ChartNavigationDirection) => {
    if (_direction === ChartNavigationDirection.left) {
      const _date = DateService.decreaseDate(momentUnit, 1, baseDate);
      setBaseDate(_date);
      setCachedKeys([]);
    }
    if (_direction === ChartNavigationDirection.right) {
      const _date = DateService.increaseDate(momentUnit, 1, baseDate);
      setBaseDate(_date);
      setCachedKeys([]);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            margin: {
              xs: '0 0 20px',
              md: '0',
            },
          }}
        >
          <JobDropdown
            value={job}
            onChange={(value) => {
              setJob(value);
              setCachedKeys([]);
            }}
            style={{
              margin: {
                xs: '0 0 20px 0',
                md: '0 20px 20px 0',
              },
            }}
          />
          <Dropdown
            label={t`dropdown.modelRunDate`}
            options={metadataF.models}
            selectedItem={model}
            onChange={(el) => {
              setModel(el);
              setBaseDate(el.asOfDate);
              setCachedKeys([]);
            }}
            selector={(val) => val.id}
            keyName="model"
            style={{
              margin: {
                xs: '20px 0',
                md: '0',
              },
            }}
            formatLabel={(val) =>
              moment.utc(val.asOfDate).format(DateFormat.default)
            }
          />
        </Box>
        <LevelMultiAutocomplete
          style={{
            width: {
              md: 320,
            },
          }}
          value={levels}
          onChange={(value) => {
            if (value) {
              setLevel(value);
              let _data: AccuracyTrendSeries = {};

              value.forEach((el) => {
                if (data && data[el.name]) {
                  _data = {
                    [el.name]: data[el.name],
                    ..._data,
                  };
                }
              });
              setCachedKeys(
                cachedKeys.filter((f) => value.map((d) => d.name).includes(f))
              );
              setData({
                ..._data,
              });
            }
          }}
        />
      </Box>
      <Box>
        <ParentSize>
          {({ width }) => (
            <AccuracyTrendChart
              width={width}
              colors={colors}
              height={500}
              data={data}
              period={period}
              loading={loading}
              onNavigation={handleChartNavigation}
            />
          )}
        </ParentSize>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </Box>
  );
}
