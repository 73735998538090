import { Box } from '@mui/material';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import OutputServiceF from 'core/services/output.service';
import { LevelOption } from 'core/types/level.type';
import { AggregateAccuracy } from 'core/types/output.type';
import LevelAutocomplete from 'modules/field/shared/level-autocomplete';
import { useEffect, useState } from 'react';
import HorizonRangeDropdown from 'modules/field/shared/horizon-range-dropdown';
import { NumberService } from 'core/services/number.service';
import { HorizonRange } from 'core/types/horizon-range.type';
import AggregateAccuracyGrid from 'modules/field/shared/aggregate-accuracy-grid';
import PageSection from 'components/page-section';
import { useTranslation } from 'react-i18next';
import Snackbar from 'components/snackbar';

export type ForecastAccuracyProps = {
  period: Period;
};

export default function ForecastAccuracy({ period }: ForecastAccuracyProps) {
  const horizonRanges = NumberService.getHorizonRangeOptions(period, 1);
  const { metadataF } = useStore();
  const [horizonRange, setHorizonRange] = useState<HorizonRange>(
    horizonRanges[0]
  );
  const [level, setLevel] = useState<LevelOption>(metadataF.levels[0]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AggregateAccuracy[]>([]);
  const [error, setError] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await OutputServiceF.getAggregateAccuracies(
          period,
          horizonRange,
          level
        );
        setData(result);
        setLoading(false);
        setError('');
      } catch (e) {
        setLoading(false);
        setError((e as Error).message);
      }
    };
    fetchData();
  }, [period, level, horizonRange]);

  return (
    <PageSection title={t`field.views.forecastAccuracy.title`}>
      <Box sx={{ marginBottom: 20 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              margin: {
                xs: '0 0 20px',
                md: '0',
              },
            }}
          >
            <HorizonRangeDropdown
              value={horizonRange}
              period={period}
              onChange={(_range) => setHorizonRange(_range)}
            />
          </Box>
          <LevelAutocomplete
            style={{
              width: {
                md: 200,
              },
            }}
            value={level}
            onChange={(value) => {
              if (value) {
                setLevel(value);
              }
            }}
          />
        </Box>
        <Box>
          <AggregateAccuracyGrid loading={loading} data={data} />
        </Box>
      </Box>
      {error !== '' && (
        <Snackbar
          severity="error"
          message={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      )}
    </PageSection>
  );
}
