import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import moment from 'moment';
import numeral from 'numeral';
import { EvolutionOutputResponse } from 'core/types/output.type';
import { DateFormat } from 'core/enums/date-format.enum';
import { Bar, LinePath } from '@visx/shape';
import { GridRows } from '@visx/grid';
import { Box, LinearProgress, styled, useTheme } from '@mui/material';
import ChartLoader from 'components/chart-loader';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useState } from 'react';
import Legend from 'components/legend';
import { LegendItem, LegendType } from 'core/types/legend.type';
import { NumeralFormat } from 'core/enums/numeral-format.enum';
import { Period } from 'core/enums/period.enum';
import DateService from 'core/services/date.service';
import { useTranslation } from 'react-i18next';
import { flatten, uniq } from 'lodash';

const TooltipTitle = styled('p')({
  margin: '0 10px 10px',
  fontWeight: 'bold',
});

const TooltipWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const TooltipTable = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export type TooltipDataOutput = {
  date: string;
  data: { legend: LegendItem; value?: number }[];
};

export type EvolutionChartProps = {
  width: number;
  height: number;
  data: EvolutionOutputResponse[];
  period: Period;
  loading?: boolean;
  colors: { color: string; backgroundColor: string; id: string }[];
};

export default function EvolutionChart({
  width,
  height,
  data,
  period,
  colors = [],
  loading = false,
}: EvolutionChartProps) {
  const theme = useTheme();
  const [hoverValue, setHoverValue] = useState('');
  const { t } = useTranslation();
  const margin = { top: 40, right: 0, bottom: 40, left: 75 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const unitsDisplayed = DateService.getUnitsDisplayedCount(period);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipDataOutput>({
    tooltipOpen: false,
    tooltipLeft: width,
    tooltipTop: height,
    tooltipData: { date: '', data: [] },
  });

  if (!loading && !data.length) {
    return (
      <Box
        sx={{
          height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2>{t`charts.noData`}</h2>
      </Box>
    );
  }

  if (loading && data.length < 2) {
    return <ChartLoader width={width} height={height} />;
  }

  const dateFormat = DateService.getDateFormat(period, 'MM/DD', 'MM/YY');

  const secondaryAxisDateFormat = DateService.getDateFormat(
    period,
    'ddd',
    'mmm'
  );

  const tooltipDateFormat = DateService.getDateFormat(
    period,
    'MMM D, YYYY',
    'MMM YYYY'
  );

  //                   _
  //   ___  ___ __ _| | ___  ___
  //  / __|/ __/ _` | |/ _ \/ __|
  //  \__ \ (_| (_| | |  __/\__ \
  //  |___/\___\__,_|_|\___||___/

  const xDomain = uniq(
    flatten(
      data.map((el) =>
        el.value.map((d) => moment(d.date).format(DateFormat.default))
      )
    )
  );

  xDomain.sort((a, b) => (moment(a).isBefore(moment(b)) ? -1 : 0));

  const xScale = scaleBand<string>({
    domain: xDomain,
    range: [margin.left, innerWidth],
  });

  const yValues = flatten(data.map((el) => el.value.map((d) => d.forecast)));
  const yMax = Math.max(...yValues);
  const yMin = Math.min(...yValues);

  const yScale = scaleLinear<number>({
    domain: [yMin, yMax],
    range: [innerHeight, margin.top],
    nice: true,
  });

  const today = moment().format(DateFormat.default);
  const todayExists = xScale.domain().includes(today);

  //     _              _ _   _
  //  | |_ ___   ___ | | |_(_)_ __
  //  | __/ _ \ / _ \| | __| | '_ \
  //  | || (_) | (_) | | |_| | |_) |
  //   \__\___/ \___/|_|\__|_| .__/
  //                         |_|
  const handleTooltip = (_date: string, top: number) => {
    const values = data.map((el) =>
      el.value.find(
        (f) =>
          moment(f.date).format(DateFormat.default) ===
          moment(_date).format(DateFormat.default)
      )
    );

    const colorA = values[0]
      ? colors.find((f) => +f.id === +(values[0] || { modelId: 0 }).modelId)
      : { backgroundColor: 'transparent' };
    const colorB = values[1]
      ? colors.find((f) => +f.id === +(values[1] || { modelId: 0 }).modelId)
      : { backgroundColor: 'transparent' };
    const colorC = values[2]
      ? colors.find((f) => +f.id === +(values[2] || { modelId: 0 }).modelId)
      : { backgroundColor: 'transparent' };

    const _tooltipData: TooltipDataOutput = {
      date: moment(_date).format(tooltipDateFormat),
      data: [
        {
          legend: {
            label:
              (values[0] &&
                values[0].asOfDate &&
                moment.utc(values[0].asOfDate).format(DateFormat.default)) ||
              '',
            color: colorA?.backgroundColor || theme.viz.default,
            type: LegendType.line,
          },
          value: (values[0] && values[0].forecast) || 0,
        },
        {
          legend: {
            label:
              (values[1] &&
                values[1].asOfDate &&
                moment.utc(values[1].asOfDate).format(DateFormat.default)) ||
              '',
            color: colorB?.backgroundColor || theme.viz.default,
            type: LegendType.line,
          },
          value: (values[1] && values[1].forecast) || 0,
        },
        {
          legend: {
            label:
              (values[2] &&
                values[2].asOfDate &&
                moment.utc(values[2].asOfDate).format(DateFormat.default)) ||
              '',
            color: colorC?.backgroundColor || theme.viz.default,
            type: LegendType.line,
          },
          value: (values[2] && values[2].forecast) || 0,
        },
      ],
    };

    showTooltip({
      tooltipLeft: (xScale(_date) || 0) + 50,
      tooltipTop: top,
      tooltipData: _tooltipData,
    });
  };

  const handleCloseTooltip = () => {
    setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div style={{ height: 2 }}>{loading && <LinearProgress />}</div>
        <svg width={innerWidth} height={height}>
          <Group>
            <GridRows
              left={margin.left}
              scale={yScale}
              width={innerWidth}
              height={innerHeight}
              stroke={theme.viz.grid.stroke}
              strokeDasharray={theme.viz.grid.strokeDasharray}
            />
          </Group>
          <Group>
            {data.map((el, i) => {
              const _color = colors.find((f) => +f.id === +el.key);
              return (
                <Group key={`${el.key}-${i}`}>
                  <LinePath
                    stroke={_color?.backgroundColor || theme.viz.default}
                    strokeWidth={2}
                    data={data[i].value}
                    x={(d) =>
                      xScale.bandwidth() / 2 +
                      (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
                    }
                    y={(d) => yScale(d.forecast) ?? 0}
                  />
                </Group>
              );
            })}
          </Group>
          <Group>
            {xScale.domain().map((el, i) => (
              <Group
                key={`tooltip-g-evolution-${i}`}
                onMouseLeave={() => {
                  setHoverValue('');
                  handleCloseTooltip();
                }}
                onMouseMove={(e) => {
                  if (el !== hoverValue) {
                    setHoverValue(el);
                  }
                  handleTooltip(el, e.screenY - innerHeight / 2 - 100);
                }}
                opacity={el === hoverValue ? 1 : 0}
              >
                <LinePath
                  stroke={theme.viz.default}
                  strokeWidth={1}
                  strokeDasharray={5}
                  data={[
                    {
                      date: el,
                      value: yScale.domain()[0] || 0,
                    },
                    {
                      date: el,
                      value: yScale.domain()[1] || 1,
                    },
                  ]}
                  x={(d) =>
                    xScale.bandwidth() / 2 +
                    (xScale(moment(d.date).format(DateFormat.default)) ?? 0)
                  }
                  y={(d) => yScale(d.value) ?? 0}
                />
                {data.map((d) => {
                  const _color = colors.find((f) => +f.id === +d.key);
                  const radius = 5;
                  const _value = d.value.find(
                    (f) =>
                      moment(f.date).format(DateFormat.default) ===
                      moment(el).format(DateFormat.default)
                  );
                  return _value ? (
                    <circle
                      key={`tooltip-evolution-marker-${d.key}-${i}`}
                      fill={_color?.backgroundColor || theme.viz.default}
                      cx={(xScale(el) || 0) + xScale.bandwidth() / 2}
                      cy={yScale(_value.forecast)}
                      r={radius}
                    />
                  ) : null;
                })}
                <Bar
                  x={xScale(el)}
                  y={innerHeight - yScale(yMin)}
                  fill="transparent"
                  width={xScale.bandwidth()}
                  height={innerHeight - (innerHeight - yScale(yMin))}
                />
              </Group>
            ))}
          </Group>
          <Group>
            {todayExists && (
              <rect
                x={xScale(today) || 0}
                y={innerHeight}
                height={40}
                width={xScale.bandwidth()}
                fill={theme.viz.xAxis.background.highlight}
              />
            )}
          </Group>
          <Group>
            <AxisLeft
              left={margin.left}
              scale={yScale}
              hideTicks
              numTicks={5}
              stroke={theme.viz.yAxis.stroke}
              tickLabelProps={() => ({
                fontSize: theme.viz.yAxis.fontSize,
                fill: theme.viz.yAxis.color,
                textAnchor: 'end',
              })}
            />
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight}
              scale={xScale}
              numTicks={unitsDisplayed}
              hideTicks
              stroke={theme.viz.xAxis.stroke}
              tickFormat={(d) => moment(d).format(dateFormat)}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  moment(d).format('d') === '0' && period === Period.daily
                    ? theme.viz.xAxis.color.accent
                    : theme.viz.xAxis.color.primary,
                fontWeight: 'bold',
                textAnchor: 'middle',
              })}
            />
          </Group>
          <Group>
            <AxisBottom
              top={innerHeight + 16}
              scale={xScale}
              stroke={theme.viz.xAxis.stroke}
              tickLabelProps={(d) => ({
                fontSize: theme.viz.xAxis.fontSize,
                fill:
                  moment(d).format('d') === '0' && period === Period.daily
                    ? theme.viz.xAxis.color.accent
                    : theme.viz.xAxis.color.secondary,
                textAnchor: 'middle',
                fontWeight:
                  today === moment(d).format(DateFormat.default)
                    ? 'bold'
                    : 'normal',
              })}
              numTicks={unitsDisplayed}
              hideAxisLine
              hideTicks
              tickFormat={(d) => {
                const tKey = `time.now.${period}`;
                return today === moment(d).format(DateFormat.default)
                  ? t(tKey)
                  : moment(d).format(secondaryAxisDateFormat);
              }}
            />
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 40,
              padding: 10,
              backgroundColor: theme.ui.tooltip.background,
              color: theme.ui.tooltip.color,
            }}
          >
            <TooltipWrapper>
              <TooltipTitle>{tooltipData.date}</TooltipTitle>
              <TooltipTable>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 10px',
                  }}
                >
                  {tooltipData.data.map((el, i) => (
                    <Box
                      key={`tooltip-evolution-${i}`}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Legend item={el.legend} />
                      {el.value
                        ? numeral(el.value).format(NumeralFormat.default)
                        : ''}
                    </Box>
                  ))}
                </Box>
              </TooltipTable>
            </TooltipWrapper>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
