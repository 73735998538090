import { useTranslation } from 'react-i18next';
import ForecastGraphView from './forecast-graph-view';
import ForecastMapView from './forecast-map-view';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PageSection, { PageSectionProps } from 'components/page-section';
import TabView from 'components/tab-view';
import { Period } from 'core/enums/period.enum';
import { useStore } from 'core/hooks/useStore';
import { useEffect, useState } from 'react';
import { Model } from 'core/types/model.type';
import OutputServiceF from 'core/services/output.service';
import { MetadataOutput } from 'core/types/output.type';
import { LinearProgress, Alert, Snackbar as MuiSnackbar } from '@mui/material';
import DateService from 'core/services/date.service';
import NoteIndex from './note-index';
import { LevelOption } from 'core/types/level.type';
import { DateFormat } from 'core/enums/date-format.enum';
import moment from 'moment';
import ForecastListView from './forecast-list-view';
import { useLocation } from 'react-router-dom';
import { Note } from 'core/types/note.type';
import { ALL_LEVEL_OPTION } from 'core/constants/options.constants';
import SaveToPageDialog from '../components/save-to-page-dialog';
import { fetchCustomPages } from 'store/field';
import { LocationState } from 'core/types/location-state-type';
import { SharedLinkData } from 'core/types/shared-link-data.type';

export type ModelOutputProps = {
  period: Period;
};

export default function ModelOutput({ period }: ModelOutputProps) {
  const { metadataF, dispatch } = useStore();
  const [model, _] = useState<Model>(metadataF.models[0]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MetadataOutput>();
  const [noteFormOpen, setNoteFormOpen] = useState(false);
  const [saveToPageOpen, setSaveToPageOpen] = useState(false);
  const [noteDate, setNoteDate] = useState('');
  const [tab, setTab] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());
  const [level, setLevel] = useState<LevelOption>(metadataF.levels[0]);
  const [saveToPageSuccess, setSaveToPageSuccess] = useState(false);
  const [saveToPageFail, setSaveToPageFail] = useState(false);
  const [pageSectionProps, setPageSectionProps] = useState<PageSectionProps>(
    {}
  );
  const [sharedLink, setSharedLink] = useState(false);
  const location = useLocation();

  const onTabSelect = (
    _tab: number,
    _date: string,
    _level: LevelOption,
    _trigger: number
  ) => {
    setTab(_tab);
    setTrigger(_trigger);
    setNoteDate(moment.utc(_date).format(DateFormat.default));
    setLevel(_level);
    getPageSectionProps(
      _tab,
      _level,
      moment.utc(_date).format(DateFormat.default)
    );
  };

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await OutputServiceF.getMetadataOutput(period, model);
      setData(result);
      setLoading(false);
    };
    fetchData();
  }, [model, period]);

  useEffect(() => {
    if (location.state) {
      const locationState = location.state as LocationState;
      if (locationState.note) {
        initNoteValues(locationState.note);
      }
      if (locationState.sharedLinkData) {
        initSharedLinkData(locationState.sharedLinkData);
      }
    }
  }, [location.state]);

  const initNoteValues = (note: Note) => {
    const selectedLevel = note.level2
      ? note.level2.id
      : note.level1
      ? note.level1.id
      : note.level0?.id || ALL_LEVEL_OPTION.id;

    const noteLevel = [ALL_LEVEL_OPTION, ...metadataF.levels].find(
      (_noteLevel) =>
        _noteLevel.id != undefined && _noteLevel.id == selectedLevel
    ) as LevelOption;

    const _date = moment.utc(note.targetDate).format(DateFormat.default);

    setTab(1);
    setNoteDate(_date);
    setLevel(noteLevel ?? ALL_LEVEL_OPTION);
    getPageSectionProps(1, noteLevel, _date);
  };

  const { startDate } = DateService.generateDateRange(
    DateService.getMomentUnit(period),
    6,
    8,
    data?.maxActualDate
  );

  const initSharedLinkData = (sharedLinkData: SharedLinkData) => {
    setTab(+sharedLinkData.subTab);
    setNoteDate(sharedLinkData.date);
    const _level =
      [ALL_LEVEL_OPTION, ...metadataF.levels].find(
        (_noteLevel) =>
          _noteLevel.id != undefined && _noteLevel.id == sharedLinkData.level
      ) ?? ALL_LEVEL_OPTION;
    setLevel(_level);
    getPageSectionProps(1, _level, noteDate !== '' ? noteDate : startDate);
    setTrigger(Date.now());
  };

  const addNote = () => {
    setNoteFormOpen(true);
  };

  const saveToPage = () => {
    setSaveToPageOpen(true);
  };

  const handleSaveToPageSuccess = () => {
    setSaveToPageSuccess(true);
    dispatch(fetchCustomPages());
  };

  const handleSaveToPageFail = () => {
    setSaveToPageSuccess(false);
    setSaveToPageFail(true);
  };

  const handleSaveToPageReset = () => {
    setSaveToPageSuccess(false);
    setSaveToPageFail(false);
  };

  const initSharedLink = (_level: LevelOption, _date: string) => {
    OutputServiceF.copyToClipboard(
      `${window.location.host}/field/link?page=${location.pathname}&date=${_date}&levelId=${_level.id}&subTab=1`
    );

    setSharedLink(true);
  };

  const getPageSectionProps = (
    _tab: number,
    _level: LevelOption,
    _date: string
  ) => {
    const props: PageSectionProps = {};

    props.title = t`field.views.forecastOutput.title`;

    switch (_tab) {
      case 0:
        break;

      case 1:
        props.tooltipData = t`field.views.graphView.tooltip`;
        props.menuOptions = [
          {
            title: t`pageSection.menu.addNote`,
            onClick: addNote,
            icon: NoteAddOutlinedIcon,
          },
          {
            title: t`pageSection.menu.saveToPage`,
            onClick: saveToPage,
            icon: BookmarkBorderOutlinedIcon,
          },
          {
            title: t`pageSection.menu.share`,
            onClick: () => initSharedLink(_level, _date),
            icon: ShareOutlinedIcon,
          },
        ];
        break;
      case 2:
        props.menuOptions = [
          {
            title: t`pageSection.menu.saveToPage`,
            onClick: saveToPage,
            icon: BookmarkBorderOutlinedIcon,
          },
        ];
        break;

      default:
        break;
    }

    setPageSectionProps(props);
  };

  return (
    <PageSection
      title={t`field.views.forecastOutput.title`}
      tooltipData={pageSectionProps.tooltipData}
      menuOptions={pageSectionProps.menuOptions}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <TabView
          key={trigger}
          initialIndex={tab}
          onSelect={(_tab) => {
            setNoteDate('');
            getPageSectionProps(
              _tab,
              level,
              noteDate !== '' ? noteDate : startDate
            );
          }}
          tabItems={[
            {
              title: t`field.views.mapView.tab`,
              component: (
                <ForecastMapView
                  period={period}
                  initialDate={startDate}
                  minDate={data?.minDate}
                  maxDate={data?.maxDate}
                />
              ),
            },
            {
              title: t`field.views.listView.tab`,
              component: (
                <ForecastListView
                  period={period}
                  initialDate={startDate}
                  minDate={data?.minDate}
                  maxDate={data?.maxDate}
                  setLevel={(_level) => setLevel(_level)}
                  level={level}
                />
              ),
            },
            {
              title: t`field.views.graphView.tab`,
              component: (
                <ForecastGraphView
                  period={period}
                  initialDate={noteDate !== '' ? noteDate : startDate}
                  minDate={data?.minDate}
                  maxDate={data?.maxDate}
                  noteFormOpen={noteFormOpen}
                  onNoteFormOpen={setNoteFormOpen}
                  setLevel={(_level) => {
                    setLevel(_level);
                    getPageSectionProps(
                      1,
                      _level,
                      noteDate !== '' ? noteDate : startDate
                    );
                  }}
                  level={level}
                  setNoteDate={(_noteDate) => {
                    setNoteDate(_noteDate);
                    getPageSectionProps(1, level, _noteDate);
                  }}
                />
              ),
            },
            {
              title: t`field.views.noteIndex.tab`,
              component: <NoteIndex period={period} onClick={onTabSelect} />,
            },
          ]}
        />
      )}
      {saveToPageOpen && (
        <SaveToPageDialog
          open={saveToPageOpen}
          level={level}
          onClose={() => setSaveToPageOpen(false)}
          onSuccess={handleSaveToPageSuccess}
          onFail={handleSaveToPageFail}
        />
      )}
      <MuiSnackbar
        open={saveToPageSuccess}
        autoHideDuration={3000}
        onClose={handleSaveToPageReset}
      >
        <Alert
          onClose={handleSaveToPageReset}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`saveToPageForm.successMessage`}
        </Alert>
      </MuiSnackbar>
      <MuiSnackbar
        open={saveToPageFail}
        autoHideDuration={3000}
        onClose={handleSaveToPageReset}
      >
        <Alert
          onClose={handleSaveToPageReset}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`saveToPageForm.errorMessage`}
        </Alert>
      </MuiSnackbar>
      <MuiSnackbar
        open={sharedLink}
        autoHideDuration={3000}
        onClose={() => setSharedLink(false)}
      >
        <Alert
          onClose={() => setSharedLink(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t`alerts.clipboard`}
        </Alert>
      </MuiSnackbar>
    </PageSection>
  );
}
