import { useAuth } from 'core/hooks/useAuth';

import { ReactNode, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

export type ProtectedPageProps = {
  navBar?: ReactNode;
  children: ReactNode;
};

export default function ProtectedPage({
  navBar,
  children,
}: ProtectedPageProps) {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
  });
  const [tokens, authAttempt] = useAuth();

  if (!authAttempt) {
    return null;
  }

  if (!tokens?.idToken && authAttempt) {
    return <Navigate to="/403" />;
  }

  return (
    <div>
      {navBar && navBar}
      {children}
    </div>
  );
}
